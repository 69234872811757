// useLazy.ts
import { onMounted, onBeforeUnmount, ref } from "vue";

/**
 * A composable function that sets up a lazy loading mechanism using the IntersectionObserver API.
 * It triggers the provided callback function when the target element comes into view.
 *
 * @param callBack - The function to be called when the target element intersects with the viewport.
 * @param target - The target element to observe. It can be a CSS selector string or an Element. Defaults to ".product-list".
 */
export function useLazy(
    callBack: () => void,
    target: string | Element = ".product-list",
    onlyOnce = false,
) {
    const lazyObserver = ref<IntersectionObserver | null>(null);
    let hasIntersected = false;

    onMounted(() => {
        if (!("IntersectionObserver" in window)) {
            callBack();
            return;
        }

        const element = typeof target === "string"
            ? document.querySelector(target)
            : target;

        lazyObserver.value = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && shouldCallCallback()) {
                    hasIntersected = true;
                    callBack();
                }
            },
            { threshold: [0] },
        );

        if (element) {
            lazyObserver.value.observe(element);
        }
    });

    const shouldCallCallback = () => {
        return !onlyOnce || !hasIntersected;
    }

    onBeforeUnmount(() => {
        if (lazyObserver.value) {
            lazyObserver.value.disconnect();
        }
    });
}