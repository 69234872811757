<template>
  <!-- Preview bar -->
  <div class="info-bar info-bar--bg-black u-text-white">
    <p>
      <strong class="u-text-white"></strong>
      <button class="btn" @click="$emit('clickExit', $event)">
        <span class="u-text-white">Exit preview mode</span>
        <Icon :type="IconTypes.Close" :size="IconSizes.Small" />
      </button>
    </p>
  </div>
</template>

<script>
import { defineComponent } from "vue";

import Icon from "../icon/icon.vue";
import { IconSizes, IconTypes } from "../icon/icon.model";

export default defineComponent({
  components: {
    Icon,
  },
  data() {
    return {
      IconSizes,
      IconTypes,
    };
  },
});
</script>
