/**
 * Format product size
 */

function formatZeroSize(size: string) {
    // If 0 return onesize
    if (size === "0") {
        return "ONESIZE";
    }
    return null;
}

function formatSockSize(size: string) {
    const sockSizes = [
        "3639",
        "3641",
        "3842",
        "3941",
        "4045",
        "4046",
        "4145",
        "4247",
    ];

    if (sockSizes.includes(size)) {
        return size.slice(0, 2) + "-" + size.slice(2); // Format as range
    }

    return null; // Return null if it's not a sock size
}

// Function to format regular sizes
function formatRegularSize(size: string): string | null {
    const regularSizes = [
        "XXS",
        "XS",
        "S",
        "M",
        "L",
        "XL",
        "XXL",
        "XXXL",
        "XXXXL",
    ];

    if (regularSizes.includes(size)) {
        const regex = /^(X{2,})(L|S)$/;
        const match = size.match(regex);

        if (match) {
            // Count the number of 'X's and append the last character (L or S)
            const count = match[1].length;
            return `${count}X${match[2]}`;
        }
        return size; // Return regular sizes as they are
    }
    return null; // Return null if it's not a regular size
}

// Function to format pants sizes
function formatPantsSize(size: string): string | null {
    if (/^\d{4}$/.test(size)) {
        return size.slice(0, 2) + "/" + size.slice(2); // Format as "29/30"
    }
    return null; // Return null if it's not a pants size
}

// Function to format suit sizes
function formatSuitSize(size: string): string | null {
    const suitPattern = /^([BC])([123])(\d{2})$/;
    const match = suitPattern.exec(size);

    if (match) {
        const width = match[1] === "B" ? "Normal vidde" : "Ekstra vidde";
        const length =
            match[2] === "1"
                ? "kort lengde"
                : match[2] === "2"
                  ? "normal lengde"
                  : "lang lengde";
        const sizeNumber = match[3];
        return `${width}, ${length}, ${sizeNumber}, (${size})`;
    }
    return null; // Return null if it's not a suit size
}

// Main size formatting function
export function formatSize(size: string): string {
    // If 0 return ONESIZE
    const zeroSize = formatZeroSize(size);
    if (zeroSize) {
        return zeroSize;
    }

    // Check for sock size
    const sockSize = formatSockSize(size);
    if (sockSize) {
        return sockSize;
    }

    // Check for regular size
    const regularSize = formatRegularSize(size);
    if (regularSize) {
        return regularSize;
    }

    // Check for pants size
    const pantsSize = formatPantsSize(size);
    if (pantsSize) {
        return pantsSize;
    }

    // Check for suit size
    const suitSize = formatSuitSize(size);
    if (suitSize) {
        return suitSize;
    }

    // Return the original string if it doesn't match any of the patterns
    return size;
}
