<template>
  <article class="store-list-item">
    <div class="store-list-item__col">
      <h3 class="store-list-item__heading">{{ store.title }}</h3>
      <address>
        {{ address }}<br />
        <span class="u-font-weight-bold">
          {{ $t("phoneNumber") }}:
          <a :href="getPhoneHref(phone)">{{ phone }}</a>
        </span>
      </address>
    </div>
    <div class="store-list-item__col">
      <h4 class="store-list-item__heading">{{ $t("openingHours") }}</h4>
      <p>{{ hours }}</p>
      <a :href="mapUrl" target="_blank" class="btn btn--inline">
        {{ $t("findTheWay") }}
      </a>
    </div>
    <div class="store-list-item__col u-flex-center">
      <a
        :href="store.link"
        class="btn btn--profile btn--small u-text-nowrap"
        @click="notifyClick($event, store)"
        @click.middle="notifyClick($event, store)"
        @click.right="notifyClick($event, store)"
      >
        {{ $t("goToStore") }}
      </a>
    </div>
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NotifyClick from "../../mixins/notifyClick";
import { ContentItem } from "../../services/search.service";

export default defineComponent({
  mixins: [NotifyClick],
  props: ["contentItem"],
  computed: {
    store(): ContentItem {
      return this.contentItem;
    },
    address(): string {
      return (
        (this.store.custom &&
          this.store.custom.Address &&
          this.store.custom.Address[0] &&
          this.store.custom.Address[0].label) ||
        ""
      );
    },
    phone(): string {
      return (
        (this.store.custom &&
          this.store.custom.Phone &&
          this.store.custom.Phone[0] &&
          this.store.custom.Phone[0].label) ||
        ""
      );
    },
    hours(): string {
      return (
        (this.store.custom &&
          this.store.custom.OpeningHours &&
          this.store.custom.OpeningHours[0] &&
          this.store.custom.OpeningHours[0].label) ||
        ""
      );
    },
    lat(): string {
      return (
        (this.store.custom &&
          this.store.custom.Lat &&
          this.store.custom.Lat[0] &&
          this.store.custom.Lat[0].label) ||
        ""
      ).replace(",", ".");
    },
    lon(): string {
      return (
        (this.store.custom &&
          this.store.custom.Lon &&
          this.store.custom.Lon[0] &&
          this.store.custom.Lon[0].label) ||
        ""
      ).replace(",", ".");
    },
    mapUrl(): string {
      let q: any = `${this.store.title} ${this.address}`;

      return `https://maps.google.com/?q=${encodeURIComponent(q)}`;
    },
  },
  methods: {
    getPhoneHref: function (pNr) {
      return "tel: " + pNr;
    },
  },
});
</script>
