<template>
  <div>
    <!-- Button: SR-toggle -->
    <div id="app-quicksearch-sr">
      <button
        role="button"
        class="sr-only sr-only--selectable"
        aria-controls="app-quicksearch-container"
        :aria-expanded="isExpanded"
        @click="clickOpen($event)"
      >
        {{ $t("goToSearchSr") }}
      </button>
    </div>
    <!-- Button: Toggle -->
    <div class="page-actions__search">
      <button
        role="button"
        class="page-actions__search btn--search"
        :aria-label="$tc('searchButton')"
        :title="$tc('searchButton')"
        @click="clickOpen($event)"
      >
        <Icon :type="IconTypes.Search" :size="IconSizes.Small" />
        <span class="page-actions__action-label u-hide-for-small">Søk</span>
      </button>
    </div>
    <!-- Modal: Quick search -->
    <div
      id="app-quicksearch-container"
      ref="searchContainer"
      class="quicksearch-container"
      @keydown.esc="clickClose($event)"
    >
      <Transition name="quicksearch-slide">
        <div v-show="isExpanded" class="u-bg-neutral quicksearch__content">
          <!-- Button: Close -->
          <button
            type="button"
            class="btn btn--small quicksearch__close"
            :aria-label="$tc('close')"
            @click="clickClose"
          >
            <Icon :type="IconTypes.Close" :size="IconSizes.Small" />
          </button>
          <!-- Quick search -->
          <QuickSearch
            ref="quickSearch"
            class-name="search-form u-text-center"
            :search-page-url="searchPageUrl"
            @clickSearch="setIsExpanded(false)"
            @clickSuggestion="setIsExpanded(false)"
          />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import Vue, { nextTick, onMounted, ref } from "vue";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import QuickSearch from "../../components/quickSearch/quickSearch.vue";

defineProps<{
  searchPageUrl: string;
}>();

const isExpanded = ref<boolean>();

const quickSearch = ref(null);
const searchContainer = ref(null);

onMounted(() => {
  document.addEventListener("click", (e: Event) => {
    if (
      searchContainer.value &&
      (searchContainer.value as HTMLElement).contains(e.target as Node) ===
        false
    ) {
      setIsExpanded(false);
    }
  });
});

/**
 * Click open
 */
const clickOpen = (e: Event) => {
  e.preventDefault();
  e.stopPropagation();

  isExpanded.value = true;

  nextTick(() => {
    if (quickSearch.value && (quickSearch.value as Vue).$refs.input) {
      ((quickSearch.value as Vue).$refs.input as HTMLInputElement).focus();
    }
  });
};
/**
 * Click close
 */
function clickClose(e: Event) {
  e.preventDefault();

  isExpanded.value = false;

  if (quickSearch.value && (quickSearch.value as Vue).$refs.input) {
    ((quickSearch.value as Vue).$refs.input as HTMLInputElement).blur();
  }
}

/**
 * Set is expanded
 */
function setIsExpanded(expandedState: boolean) {
  isExpanded.value = expandedState;
}
</script>
