﻿export default class DeleteProfile {
    private DELETE_ENDPOINT = '/min-side/DeleteProfile';

    private form: HTMLElement;
    private submitTrigger: HTMLElement | null;
    private feedbackSuccess: HTMLElement | null;
    private feedbackError: HTMLElement | null;
    private profileData: HTMLFormElement | null;

    constructor(element) {
        this.form = element;
        this.submitTrigger = this.form.querySelector('input[type="submit"]');
        this.feedbackSuccess = this.form.querySelector('.feedback--success');
        this.feedbackError = this.form.querySelector('.feedback--error');
        this.profileData = document.querySelector('._js-mypage-profile');
        this.init();
    }

    init = () => {
        this.initSubmit();
    };

    private initSubmit = () => {
        if (!this.submitTrigger) return;

        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.handleSubmit();
        });
    };

    private handleSubmit = () => {
        this.updateDetailsBegin();
        const success = this.requestDeletion();
        this.updateDetailsComplete(success);
    };

    private requestDeletion = async() => {
        if (!this.profileData) return;
        
        const formData = new FormData(this.profileData);
        if (!formData) return;

        const request = new Request(this.DELETE_ENDPOINT, {
            method: 'POST',
            body: formData
        });

        try {
            const res = await fetch(request);
            return res.ok;
        } catch (error) {
            return false;
        }
    };

    private updateDetailsBegin = () => {
        this.submitTrigger?.setAttribute('disabled', 'true');
        this.feedbackError?.classList.remove('delete-profile__feedback--visible');
        this.feedbackSuccess?.classList.remove('delete-profile__feedback--visible');
    };

    private updateDetailsComplete = (success) => {
        this.submitTrigger?.removeAttribute('disabled');    

        if (success && !this.feedbackSuccess?.classList.contains('delete-profile__feedback--visible')) {
            this.feedbackSuccess?.classList.add('delete-profile__feedback--visible');
            return;
        }

        if (!this.feedbackError?.classList.contains('delete-profile__feedback--visible')) this.feedbackError?.classList.add('delete-profile__feedback--visible');
    };
}
