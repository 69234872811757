const tabs = () => {
    const hash = window.location.hash.replace('#', '');

    // Tab click
    $('._js-tab-trigger').each(function() {
        $(this).on('click',
            function(event) {
                event.preventDefault();

                const parent = $(this).closest('._js-tab-wrapper');
                const contentTabs = parent.find('._js-tab-trigger');
                const contentPanels = parent.find('._js-tab-content');
                const target = $(this).attr('aria-controls');

                contentTabs.each(function() {
                    $(this).removeClass('is-active');
                    $(this).attr('aria-expanded', 'false');
                });

                contentPanels.each(function() {
                    $(this).removeClass('is-active');
                });

                $(this).attr('aria-expanded', 'true');
                $(this).addClass('is-active');
                $('#' + target).addClass('is-active');

                if (history.pushState) {
                    history.pushState(null, null, '#' + target);
                } else {
                    location.hash = target;
                }

                // Special cases
                if (target === 'login-tab-2') {
                    $('#orderForm').hide();
                } else if (target === 'login-tab-1') {
                    $('#orderForm').show();
                } else if (target === 'search-results-products') {
                    $('#product-categories:not(.is-hidden)').show();
                    $('#search-results-articles').hide();
                    $('#back-to-products').hide();
                } else if (target === 'search-results-articles') {
                    $('#product-categories').hide();
                    $('#search-results-articles').show();
                    $('#back-to-products').show();
                    if (window.lazySizes) {
                        window.lazySizes.init();
                    }
                }
            });
    });

    // Check hash
    if (hash) {
        $('._js-tab-trigger').each(function() {
            if ($(this).attr('aria-controls') === hash) {
                $(this).click();
            }
        });
    }
};

export const init = () => {
    tabs();
};
