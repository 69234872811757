export default class EpiserverUploadReload {
    private RELOAD_IDENTIFIER = "excelfile-uploaded-A71CC701-ED69-4BDA-A450-353B7F1655E2";
    private element: HTMLElement;
    private observer: MutationObserver;

    constructor(element: HTMLElement) {
        this.element = element;
        this.init();
    }

    private init() {
        this.setupMutationObserver();
    }

    private setupMutationObserver() {
        const callback: MutationCallback = (mutationsList, _) => {
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    for (const addedNode of Array.from(mutation.addedNodes)) {
                        if ((addedNode as HTMLElement)?.innerHTML === this.RELOAD_IDENTIFIER) {
                            location.reload();
                        }
                    }
                }
            }
        };

        this.observer = new MutationObserver(callback);
        this.observer.observe(this.element, {
            childList: true, // Observe direct children
            subtree: true,  // Observe all descendants
        });
    }
}