<script lang="ts" setup>
import { computed, ref, defineEmits } from "vue";
import { FEEDBACK_TEXT } from "../../utils/validationHelpers";
import { TranslateResult } from "vue-i18n";

const props = defineProps<{
  modelValue?: string;
  label: string | TranslateResult;
  type: string;
  labelClass?: string;
  inputClass?: string;
  required?: boolean;
  error?: string;
  displayRequiredError?: boolean;
  autofocus?: boolean;
  autocomplete?: string;
  emitInputElement?: boolean;
  emitEventName?: string;
  pattern?: string;
  disabled?: boolean;
  maxLength?: number;
}>();

const emits = defineEmits(['update:modelValue', 'blur']);
const inputElement = ref<HTMLInputElement | null>();
const id = Math.random();

/**
 * Computed property that filters out errors regarding empty values, as they are handled by input and label classes.
 *
 * @returns {Array} The filtered errors.
 */
const errorFiltered = computed(() => {
  if (!props.error) return;
  if (
    (!props.displayRequiredError && props.error === FEEDBACK_TEXT().REQUIRED) ||
    props.error === "Required"
  )
    return;

  return props.error;
});
</script>

<template>
  <div>
    <label class="checkout-form__label" :class="props.labelClass">
      <input
        ref="inputElement"
        :value="modelValue"
        :type="type"
        class="checkout-form__input"
        :class="[inputClass, { 'checkout-form__input--error': !!error }]"
        :autocomplete="autocomplete"
        :aria-required="required"
        :aria-invalid="!!error"
        placeholder=" "
        :autofocus="autofocus"
        :aria-describedby="errorFiltered ? `error-${id}` : ''"
        :pattern="pattern"
        :disabled="disabled"
        :maxLength="maxLength"
        @input="$emit('update:modelValue', $event.target.value)"
        @blur="$emit('blur', $event)"
      />
      <span
        class="checkout-form__label-text"
        :class="{ 'checkout-form__label-text--error': !!error }"
        >{{ label }}</span
      >
    </label>
    <div
      v-show="errorFiltered"
      :id="`error-${id}`"
      class="checkout-form__error checkout-form__error--inline"
    >
      {{ errorFiltered }}
    </div>
  </div>
</template>
