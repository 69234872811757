<script setup lang="ts">
import { ref, onMounted, computed, watch, nextTick } from "vue";
import { HeadingLevel } from "../accordion/accordionSchema";
import { CheckoutStages, CheckoutStep } from "../../services/models/checkout.models";
import Icon from "../icon/icon.vue";
import { useCheckoutStateStore } from "./stores/checkoutStateStore";
import { sentenceCase } from "../../../../helpers/checkoutHelpers";

const OFFSET_TO_SHOW_PREVIOUSLY_COMPLETED_STEP = 300 as const;

const props = defineProps<{
  headingLevel?: HeadingLevel;
  headingClass?: string;
  containerClass?: string;
  stepClass?: string;
  steps: Array<CheckoutStep>;
  completedSteps?: Array<CheckoutStep>;
  activeStep: CheckoutStep;
}>();

const checkoutStateStore = useCheckoutStateStore();
const stepElements = ref();

/**
 * Watches the `activeStep` prop for changes and scrolls to the new active step.
 *
 * @param {Function} callback - The callback function to execute when the `activeStep` prop changes.
 * @param {any} newActiveStep - The new value of the `activeStep` prop.
 * @param {any} oldActiveStep - The previous value of the `activeStep` prop.
 */
watch(
  () => props.activeStep,
  (newActiveStep, oldActiveStep) => {
    if (newActiveStep !== oldActiveStep) scrollToStep(newActiveStep);
  },
);

const headingLevelWithDefault = computed(() => props.headingLevel ?? "h2");

/**
 * Scrolls to the specified checkout step.
 *
 * @param {CheckoutStep} step - The checkout step to scroll to.
 */
const scrollToStep = (
  step: CheckoutStep,
  scrollBehavior: ScrollBehavior = "smooth",
) => {
  const stepElement = stepElements.value[
    props.steps.indexOf(step)
  ] as HTMLElement;

  if (stepElement) {
    nextTick(() =>
      window.scrollTo({
        top: stepElement.offsetTop - OFFSET_TO_SHOW_PREVIOUSLY_COMPLETED_STEP,
        behavior: scrollBehavior,
      }),
    );
  }
};

/**
 * Determines whether to display the edit button for a given step.
 * The edit button is displayed if the step is not the active step and
 * if the step has been completed.
 *
 * @param {number} step - The step to check.
 * @returns {boolean} - True if the edit button should be displayed, false otherwise.
 */
const displayEditButton = (step) => {
  return (
    props.activeStep != step &&
    props.completedSteps &&
    props.completedSteps.includes(step)
  );
};

/**
 * Returns a string that combines the number indicator and the checkout step.
 *
 * @param {number} numberIndicator - The number indicator for the step.
 * @param {CheckoutStep} step - The checkout step.
 * @returns {string} - The combined string of the number indicator and the step.
 */
const buttonHeading = (numberIndicator: number, step: CheckoutStep) => {
  return `${numberIndicator}. ${step}`;
};

const handleClick = (step: CheckoutStep) => {
  checkoutStateStore.setToCheckoutStep(step);
};

const goToShoppingCart = () => {
  checkoutStateStore.setCheckoutStage(CheckoutStages.ShoppingCart);
}

onMounted(() => {
  if (props.activeStep) scrollToStep(props.activeStep, "auto");
});
</script>

<template>
  <div :class="props.containerClass">
    <div class="layout-new-checkout__primary-content">
      <div class="checkout-feedback checkout-feedback--line-item" v-if="checkoutStateStore.newItemsAfterVerification?.length">
        <div class="u-text-left">
          <Icon type="info" size="tiny"/> Handlekurven din har produkter fra en tidligere handel. Sjekk sammendrag eller <button class="btn--inline" @click.prevent="goToShoppingCart">endre i handlekurven</button></div>
        </div>
      <div
        v-for="(step, index) in props.steps"
        ref="stepElements"
        :key="index"
        class="checkout-step-selector"
        :class="props.stepClass"
      >
        <component
          :is="headingLevelWithDefault"
          :class="[
            props.headingClass,
            {
              'checkout-step-selector__heading--padded':
                !displayEditButton(step),
            },
          ]"
        >
          <span
            :class="{
              'checkout-step-selector__heading-content--padded':
                displayEditButton(step),
            }"
            >{{ buttonHeading(index + 1, step) }}</span
          >
          <button
            v-if="displayEditButton(step)"
            class="checkout-step-selector__edit-step checkout-step-selector__activator"
            @click="handleClick(step)"
          >
            <Icon type="pen" size="checkout-edit"></Icon>
            <span class="checkout-step-selector__edit-text">{{
              $t("change")
            }}</span>
          </button>
        </component>
        <div v-if="props.activeStep" class="checkout-step-selector__content">
          <slot :name="step"></slot>
        </div>
      </div>
    </div>
  </div>
</template>
