<template>
  <div class="minicart-container">
    <a
      class="page-actions__action nav-profile-menu__trigger"
      :href="props.checkoutUrl"
    >
      <template v-if="cartEmpty">
        <Icon :type="IconTypes.BagEmpty" :size="IconSizes.MiniCartEmpty" />
      </template>
      <template v-else>
        <Icon :type="IconTypes.Bag" :size="IconSizes.MiniCartEmpty" />
        <span class="page-actions__action-amount">{{
          addToCartStore.totalProductCount
        }}</span>
      </template>

      <span class="page-actions__action-label u-hide-for-small"
        >Handlekurv</span
      ></a
    >
    <div ref="toast" class="toast">
      <span
        ref="toastMessage"
        class="is-hidden"
        role="alert"
        aria-atomic="true"
        aria-hidden="true"
        aria-live="assertive"
        >{{ errorMessage }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import { get } from "./../../../../helpers/http";
import { useProductPageStore } from "../../stores/productPageStore";

const props = defineProps<{
  checkoutUrl: string;
  useMinicartOnMobileString?: string;
}>();

const addToCartStore = useProductPageStore();
const errorMessage = ref("Det skjedde en feil. Prøv igjen senere" as string);
const toast = ref<HTMLInputElement | null>(null);
const toastMessage = ref<HTMLInputElement | null>(null);
const cartEmpty = computed(() => addToCartStore.cartEmpty);

const fetchContent = async () => {
  return get("/Cart/MiniCartContent");
};

onMounted(async () => {
  await fetchContent()
    .then((res) => res.json())
    .then((data) => {
      addToCartStore.setCartItemCount(data.itemCount);
    });
});
</script>
