<template>
  <div v-if="format.values">
    <label
      v-for="value in format.values"
      :key="value.id"
      class="checkbox product-filter__checkbox checkbox product-filter__checkbox--sizes"
    >
      <input
        type="checkbox"
        :name="`facet${facet.id}`"
        :value="value.id"
        :checked="isSelected(value, selectedValues)"
        @change="change(value, $event)"
        @keyup.enter="$emit('enter', $event)"
      />
      <span>{{ formatLabel(value.label) }} ({{ value.count }})</span>
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FacetValue } from "../../../services/search.service";

export default defineComponent({
  props: {
    selectedValues: Array,
    facet: Object,
    format: Function,
  },
  methods: {
    /**
     * Is selected?
     */
    isSelected(value) {
      return this?.selectedValues?.indexOf(value.id) !== -1;
    },
    /**
     * Change
     */
    change(facetValue: FacetValue, event: Event) {
      this.$emit("selectValue", {
        facetValue,
        state: (event.target as HTMLInputElement).checked,
      });
    },
    /**
     * Format label
     */
    formatLabel(label: string) {
      return formatMatrixSizeLabel(
        label,
        this.$t("matrixWidth"),
        this.$t("matrixLength"),
      );
    },
  },
});

/**
 * Format matrix size label
 */
export function formatMatrixSizeLabel(label: string, wText, lText): string {
  let w, l;

  if (label.length === 3) {
    w = label.substring(0, 1);
    l = label.substring(1);
  } else {
    w = label.substring(0, 2);
    l = label.substring(2);
  }

  return `${wText}: ${w} / ${lText}: ${l}`;
}
</script>
