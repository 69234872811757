const loginSession = () => {
    const login = document.querySelectorAll('._js-login');
    const loginFinished = document.querySelectorAll('._js-login-finished');
    const phoneNumberInput = document.querySelectorAll('._js-phone-input');

    if (login) {
        login.forEach(l => {
            l.addEventListener('click', event => {
                window.sessionStorage.setItem('currentPage', window.location.href);
            });
        });
    }

    if (loginFinished) {
        loginFinished.forEach(l => {
            l.addEventListener('click', event => {
                const storedPage = window.sessionStorage.getItem('currentPage');
                const storedPhoneNumber = window.sessionStorage.getItem('phoneNumber');
                if (storedPage) {
                    event.preventDefault();
                    window.location = storedPage;
                    window.sessionStorage.removeItem('currentPage');
                }

                if (storedPhoneNumber) {
                    window.sessionStorage.removeItem('phoneNumber');
                }
            });
        });
    }

    if (phoneNumberInput) {        
        const storedPhoneNumber = window.sessionStorage.getItem('phoneNumber');
        //check if validates
        phoneNumberInput.forEach(pnr => {
            pnr.addEventListener('blur', event => {
                window.sessionStorage.setItem('phoneNumber', pnr.value);
            });
            if (storedPhoneNumber) {
                pnr.value = storedPhoneNumber;
            }
        });
    }
};

export const loginSessionInit = () => {
    loginSession();
};
