const getData = videoid => {
    /**
     * Extract id if videoid is provided as an url: "https://vimeo.com/123"
     */
    if(typeof(videoid) === 'string') {
        videoid = videoid.replace('https://vimeo.com/', '');
    }

    /**
     * Get meta data
     */
    $.getJSON('https://vimeo.com/api/v2/video/' + videoid + '.json',
        {
            format: 'json'
        })
        .done(function(data) {
            if (!data[0].description) {
                return false;
            }
            data = {
                '@context': 'https://schema.org',
                '@type': 'VideoObject',
                'contentUrl': data[0].url,
                'name': data[0].title,
                'description': data[0].description,
                'thumbnailUrl': data[0].thumbnail_medium,
                'uploadDate': data[0].upload_date
            };

            let script;
            script = document.createElement('script');
            script.type = 'application/ld+json';
            script.innerHTML = JSON.stringify(data);
            document.getElementsByTagName('head')[0].appendChild(script);
        });
};

const structuredVideoData = () => {
    $('[data-videoobject-id]').each(function() {
        getData($(this).data('videoobject-id'));
    });
};

export const init = () => {
    structuredVideoData();
};
