<script setup lang="ts">
const props = defineProps<{
  showAsColumnOnMobile?: boolean;
  productCount?: number;
}>();
</script>

<template>
  <div class="product-list__heading skeleton__text skeleton__text--longest"></div>
  <div class="product-list">
    <div class="product-list__heading">
      <span class="skeleton__text--long"></span>
    </div>
    <div
      class="product-list__items product-list__items--auto-width"
      :class="{
        'product-list__items--column': props.showAsColumnOnMobile,
      }"
    >
      <div v-for="(_, index) in props.productCount" :key="index">
        <div class="teaser-product teaser-product--variation">
          <div class="teaser-product__link-image">
            <div class="teaser-product__photos">
              <div class="teaser-product__photo">
                <div class="skeleton__img skeleton__img--productsmodule"></div>
              </div>
            </div>
          </div>
          <div class="teaser-product__content">
            <div class="teaser-product__name skeleton__text skeleton__text--long"></div>
            <div class="teaser-product__price skeleton__text skeleton__text--short"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
