export default class Quicksearch {
    constructor(element: HTMLElement) {
        // Quicksearch
        // TODO: Toggle aria on AND off
        // Close when clicking outside search area
        element.addEventListener('click', function () {
            const quicksearchInput = document.querySelector('._js-quicksearch-input') as HTMLElement | null;
            quicksearchInput?.focus();
        });

    }
}

