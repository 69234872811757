export default class HenryHeroBlockVideo {
    private USE_MOBILE_VIDEO_SOURCE_TRESHOLD = 768 as const;
    private FIRST_FRAME_IDENTIFIER = "#t=0.1" as const;

    private videoElement?: HTMLVideoElement;
    private videoSourceDesktop?: string;
    private videoSourceMobile?: string;

    constructor(element) {
        this.videoElement = element;
        if (!this.videoElement) return;

        this.videoSourceDesktop = this.videoElement.dataset.source;
        this.videoSourceMobile = this.videoElement.dataset.sourceMobile;
        this.init();
    }

    /**
     * Initializes the hero block.
     *
     * If both `videoSourceDesktop` and `videoSourceMobile` are not provided, or if `videoElement` is not available,
     * the method will return early without performing any initialization.
     *
     * The `videoElement` source will be set to `videoSourceDesktop` if available, otherwise to `videoSourceMobile`.
     * If neither `videoSourceDesktop` nor `videoSourceMobile` are available, the `videoElement` source will be set to an empty string.
     *
     * The method also initializes the resize observer.
     */
    init = () => {
        if (
            (!this.videoSourceDesktop && !this.videoSourceMobile) ||
            !this.videoElement
        )
            return;

        this.videoElement.src =
            this.videoSourceDesktop ?? this.videoSourceMobile ?? "";
        this.initSourceElements();
        this.initPoster();
        this.initResizeObserver();
        this.videoElement.play();
    };

    /**
     * Initializes the source elements for the video.
     */
    private initSourceElements = () => {
        if (this.videoSourceDesktop) {
            this.createSourceElement(this.videoSourceDesktop);
        }

        if (this.videoSourceMobile && !this.videoSourceDesktop) {
            this.createSourceElement(this.videoSourceMobile);
        }
    };

    private initPoster = () => {
        if (!this.videoElement) return;

        this.videoElement.poster = (this.videoSourceDesktop ?? this.videoSourceMobile) + this.FIRST_FRAME_IDENTIFIER;
    }

    /**
     * Creates a source element for the video with the specified video source.
     * @param videoSource - The URL of the video source.
     */
    private createSourceElement = (videoSource: string) => {
        if (!videoSource) return;

        const sourceElement = document.createElement("source");
        sourceElement.src = videoSource;

        const mimeType = this.getValidMimeTypeFromFileExtension(videoSource);
        if (mimeType) {
            sourceElement.type = mimeType;
        }

        this.videoElement?.appendChild(sourceElement);
        this.videoElement?.load();
    };

    /**
     * Initializes the resize observer and sets up the event listener for the 'resize' event.
     * It also calls the `handleResize` method once initially to check the current viewport size.
     */
    private initResizeObserver = () => {
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    };

    /**
     * Handles the resize event and updates the video source based on the window width.
     */
    private handleResize = () => {
        if (!this.videoElement) return;
        const shouldUseMobileSource =
            window.innerWidth < this.USE_MOBILE_VIDEO_SOURCE_TRESHOLD;

        if (
            shouldUseMobileSource &&
            this.videoElement.src !== this.videoSourceMobile &&
            this.videoSourceMobile
        ) {
            this.videoElement.src = this.videoSourceMobile;
        }

        if (
            !shouldUseMobileSource &&
            this.videoElement.src !== this.videoSourceDesktop &&
            this.videoSourceDesktop
        ) {
            this.videoElement.src = this.videoSourceDesktop;
        }
    };

    /**
     * Retrieves the valid MIME type based on the file extension.
     * Valid in this context means that the MIME type is supported by the HTML5 video element.
     * 
     * @param {string} filename - The name of the file.
     * @returns {string} The valid MIME type for the given file extension or an empty string if the MIME type is not supported or not found for the filename.
     */
    private getValidMimeTypeFromFileExtension = (filename) => {
        const extension = filename.split(".").pop();
        switch (extension) {
            case "mp4":
                return "video/mp4";
            case "webm":
                return "video/webm";
            case "ogg":
                return "video/ogg";
            default:
                return "";
        }
    };

    /**
     * Removes the event listener for the 'resize' event if the hero block is destroyed.
     */
    destroy = () => {
        window.removeEventListener("resize", this.handleResize);
    };
}
