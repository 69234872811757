import axios from 'axios';

const captcha = () => {
    const form = document.querySelector('#submitCaptcha').closest('form');

    if (form && form.checkValidity()) {
        const refreshUrl = form.action;
        const data = new FormData(form);
        let obj = {};
        for (let [key, value] of data) {
            obj[key] = value;
        }

        const ajax = axios.create({
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        });

        ajax.post(refreshUrl, data)
            .then(response => {
                document.querySelector('._js-captcha-response').replaceWith(response.data);
            });
    } else {
        grecaptcha.reset();
    }
};

export const init = () => {
    captcha();
};
