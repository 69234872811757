import { decodeJwt } from 'jose';
import { oauthFunctions } from './oauth_wp';

export let googleLoginUrls = {};

export const googleLoginFunctions = {
    init: (urls) => {
        googleLoginUrls = urls;
    },

    onSuccess: (googleUser) => {
        const profile = decodeJwt(googleUser.credential);
        if (profile) {
            if (googleLoginUrls) {
                const idToken = googleUser.credential;
                oauthFunctions.doLogin(googleLoginUrls.loginUrl, idToken).then(function (data) {
                    if (data.DoRegistration) {
                        // Success, but not connected
                        oauthFunctions.doRegistration(googleLoginUrls.connectUrl, idToken, profile.email, '')
                            .then(function (data) {
                                if (data.DoCreate) {
                                    oauthFunctions.doCreation(googleLoginUrls.createUrl,
                                        idToken,
                                        profile.given_name,
                                        profile.family_name,
                                        profile.email)
                                        .then(function (data) {
                                            if (!data.Success) {
                                                console.log(data.Message);
                                            }
                                            window.voice.googleLogin.signOut();
                                        });
                                } else if (!data.Success) {
                                    console.log(data.Message);
                                }
                                window.voice.googleLogin.signOut();
                            });
                    } else {
                        window.voice.googleLogin.signOut();
                    }
                });
            }
        }
    },

    onFailure: (data) => {
        console.log('Login failure: ' + data.error);
    }
};
