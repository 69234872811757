import { defineComponent } from "vue";
import { ProductGroup, Product } from "../services/models/search.models";
import { pushDataToGtm as pushData } from "../../modules/gtmdata_wp";

export default defineComponent({
    methods: {
        /**
         * Push data to GTM
         */
        pushDataToGtm(event: any) {
            pushData(event);
        },
        /**
         * Get product click event
         */
        getProductClickEvent({
            userId,
            department,
            category,
            brand,
            rebate,
            price,
            metaColor,
            key,
            title,
            stock,
        }: {
            userId: string;
            department: string;
            category: string;
            brand: string;
            rebate: string;
            price: number;
            metaColor: string;
            key: string;
            title: string;
            stock: string;
        }) {
            const event: any = {
                ecommerce: {
                    click: {
                        actionField: {
                            list: `Cat: ${department}`,
                        },
                        products: [
                            {
                                brand,
                                category,
                                dimension2: rebate,
                                dimension4: stock,
                                price: price,
                                variant: metaColor,
                                list: `Cat: ${department}`,
                                id: key,
                                name: title,
                            },
                        ],
                    },
                },
                event: "productClick",
            };

            if (userId) {
                event.userId = userId;
            }

            return event;
        },
    },
});

/**
 * Push data to GTM
 */
export function pushDataToGtm(event: any) {
    pushData(event);
}
/**
 * Get product click event
 */
export function getProductClickEvent({
    userId,
    department,
    category,
    brand,
    rebate,
    price,
    metaColor,
    key,
    title,
    stock,
}: {
    userId: string;
    department: string;
    category: string;
    brand: string;
    rebate: string;
    price: number;
    metaColor: string;
    key: string;
    title: string;
    stock: string;
}) {
    const event: any = {
        ecommerce: {
            click: {
                actionField: {
                    list: `Cat: ${department}`,
                },
                products: [
                    {
                        brand,
                        category,
                        dimension2: rebate,
                        dimension4: stock,
                        price: price,
                        variant: metaColor,
                        list: `Cat: ${department}`,
                        id: key,
                        name: title,
                    },
                ],
            },
        },
        event: "productClick",
    };

    if (userId) {
        event.userId = userId;
    }

    return event;
}
/**
 * Get category impressions event
 */
export function getCategoryImpressionsEvent({
    userId,
    store,
    productGroups,
}: {
    userId: string;
    store: string;
    productGroups: Array<ProductGroup>;
}): any {
    const event: any = {
        ecommerce: {
            impressions: productGroups.map(
                (productGroup: ProductGroup, i: number) => {
                    const product = productGroup.products[0] as Product;

                    const department =
                        product.custom &&
                        product.custom.Dept &&
                        product.custom.Dept[0] &&
                        product.custom.Dept[0].label;

                    const category =
                        product.custom &&
                        product.custom.Category &&
                        product.custom.Category[0] &&
                        product.custom.Category[0].label;

                    const price = product.sellingPrice.max;

                    return {
                        brand: product.brand,
                        category: `${store}/${department}/${category}`,
                        list: `Cat: ${department}`,
                        position: i + 1 + "",
                        price: price + "",
                        id: product.key,
                        name: product.title,
                    };
                },
            ),
        },
        ecommPageType: "category",
        event: "impression",
    };

    if (userId) {
        event.userId = userId;
    }

    return event;
}

/**
 * Get filter impressions event
 */
export function getFilterImpressionsEvent({
    store,
    listName,
    productGroups,
}: {
    store: string;
    listName: string;
    productGroups: Array<ProductGroup>;
}): any {
    const event: any = {
        ecommerce: {
            impressions: productGroups.map(
                (productGroup: ProductGroup, i: number) => {
                    const product = productGroup.products[0] as Product;

                    const department =
                        product.custom &&
                        product.custom.Dept &&
                        product.custom.Dept[0] &&
                        product.custom.Dept[0].label;

                    const category =
                        product.custom &&
                        product.custom.Category &&
                        product.custom.Category[0] &&
                        product.custom.Category[0].label;

                    const price = product.sellingPrice.max;

                    return {
                        brand: product.brand,
                        category: `${store}/${department}/${category}`,
                        listName,
                        position: i + 1 + "",
                        price: price + "",
                        id: product.key,
                        name: product.title,
                    };
                },
            ),
        },
        event: "impression",
    };
    return event;
}
