import {
    expand,
    scrollToTarget
} from '../../utils';

const customerClub = () => {
    const button = document.querySelector('._js-terms-button');

    if (button) {
        button.addEventListener('click', e => {
            e.preventDefault();

            const id = e.target.id;
            const lipScoreToggleButton = document.querySelector('#terms-accordion-btn');

            if (lipScoreToggleButton) {
                if (!lipScoreToggleButton.classList.contains('is-active')) {
                    expand(lipScoreToggleButton);
                }
            }

            scrollToTarget(id);
        });
    }
};

export const init = () => {
    customerClub();
};
