import {scrollToTarget} from '../../utils';
import bubbleEvent from '../../helpers/event-bubble';

export default class Product  {
    constructor(element: HTMLElement){

        bubbleEvent(element, 'click', 'lipscore-rating-review-link', this.handleLipscoreClick.bind(this));
    
        bubbleEvent(element, 'click', '_js-extended-details-link', this.handleExtendedDetailsClick.bind(this));
    
        // Display "No reviews"  if no lipscore-review-content
        window.addEventListener('load', () => {
            setTimeout(() => {
                const reviewContent = Array.from(element.getElementsByClassName('lipscore-review-content'));
                if(reviewContent && reviewContent.length < 1){
                    const noReviewsElement = element.querySelector('._js-lipscore-no-reviews') as HTMLElement;
                    noReviewsElement?.classList.remove('is-hidden');
                } else {
                    const reviewCountElement = element.querySelector('.lipscore-rating-review-count') as HTMLElement;
                    const reviewCount = reviewCountElement?.textContent;
                    const reviewListCount = element.querySelector('._js-lipscore-reviews-number');
                    if(reviewCount && reviewListCount){
                        reviewListCount.textContent = reviewListCount.textContent + ' (' + reviewCount + ')';
                    }
                }
            }, 3000);
        });

        // If only-choice (one-size) check radiobutton
        let sizes = Array.from(element.getElementsByClassName('_js-select-size--onesize')) as HTMLElement[];
        sizes = sizes.filter(element => !element.firstElementChild?.hasAttribute('disabled'));
        if (sizes.length === 1){
            sizes[0].firstElementChild?.setAttribute('checked', 'checked');
        }

    }

    handleLipscoreClick(e: Event): void {
        e.preventDefault();

        const target = e.target as HTMLElement;
        const lipScoreAccordion = document.getElementById('lipscore-accordion');
        if (lipScoreAccordion && !lipScoreAccordion.hasAttribute('open')) {
            lipScoreAccordion.setAttribute('open', '');
        }
        const id = target.getAttribute('href');
        scrollToTarget(id);
    }

    handleExtendedDetailsClick(e: Event): void {
        e.preventDefault();

        const target = e.target as HTMLElement;
        const extendedDetailsAccordion = document.getElementById('extended-details-accordion');
        if (extendedDetailsAccordion && !extendedDetailsAccordion.hasAttribute('open')) {
            extendedDetailsAccordion.setAttribute('open', '');
        }
        const id = target.getAttribute('href');
        scrollToTarget(id);
    }
}
