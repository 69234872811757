import * as z from "zod";
import { FEEDBACK_TEXT } from "../../validationHelpers";

export const verificationSchema = z.object({
    email: z.string().min(1, { message: FEEDBACK_TEXT().REQUIRED }),
    password: z.string().min(1, { message: FEEDBACK_TEXT().REQUIRED }),
});

export const resetPasswordEmailVerificationSchema = z
    .string()
    .trim()
    .toLowerCase()
    .min(1, { message: FEEDBACK_TEXT().PASSWORD_RESET_EMAIL_REQUIRED })
    .email({ message: FEEDBACK_TEXT().PASSWORD_RESET_EMAIL_INVALID });
