import { USER_FEEDBACK } from "../APIErrors";
import {
    AddPaymentMethodRequestDto,
    GetPaymentMethodsResponseModel,
    GetPaymentMethodsResponseModelSchema,
    PaymentMethodsDto,
    PaymentMethodsDtoSchema,
    PaymentMethodViewModel,
    PaymentMethodViewModelSchema,
    SelectPaymentResultDto,
    SelectPaymentResultDtoSchema,
} from "./paymentAPISchema";
import { LogErrorResponse } from "../Logging/LoggingAPI";

const API_URL = "/api/payment";

export const getPaymentMethods = async (): Promise<GetPaymentMethodsResponseModel> => {
    const url = `${API_URL}`;
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return Promise.reject(USER_FEEDBACK.GENERIC);
    }

    const data = await response.json();
    const validatedObject = GetPaymentMethodsResponseModelSchema.parse(data);

    return validatedObject;
};

export const setPaymentMethod = async (
    paymentId: string,
    addPaymentMethodRequestDto: AddPaymentMethodRequestDto,
): Promise<SelectPaymentResultDto> => {
    const url = `${API_URL}/${paymentId}`;
    const body = JSON.stringify(addPaymentMethodRequestDto);
    const response = await fetch(url.toString(), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: body,
    });


    if (!response.ok) {
        LogErrorResponse(url, response, body);
        return {};
    }
    
    const data = await response.json();
    const validatedData = SelectPaymentResultDtoSchema.parse(data);
    return validatedData;
};
