<template>
  <div class="product-filter__group-wrapper">
    <fieldset
      v-for="group in groups"
      v-if="group.values.length > 0"
      :key="group.heading"
      class="product-filter__group"
    >
      <legend class="product-filter__group-heading">{{ group.heading }}</legend>
      <label
        v-for="value in group.values"
        :key="value.id"
        class="checkbox product-filter__checkbox checkbox product-filter__checkbox--sizes"
      >
        <input
          type="checkbox"
          :name="`facet${facet.id}`"
          :value="value.id"
          :checked="isSelected(value, selectedValues)"
          @change="change(value, $event)"
          @keyup.enter="$emit('enter', $event)"
        />
        <span>{{ formatLabel(value.label) }} ({{ value.count }})</span>
      </label>
    </fieldset>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Format, FacetValue } from "../../../services/search.service";

interface Group {
  heading: string;
  values: Array<FacetValue>;
}

interface Groups {
  b1?: Group;
  b2?: Group;
  b3?: Group;
  c1?: Group;
  other?: Group;
}

export default defineComponent({
  props: {
    selectedValues: Array,
    facet: Object,
    format: Function,
  },
  computed: {
    groups(): Groups {
      return {
        b1: {
          heading: this.$t("suitB1").toString(),
          values: getValuesForGroups(this.format, ["b1"]),
        },
        b2: {
          heading: this.$t("suitB2").toString(),
          values: getValuesForGroups(this.format, ["b2"]),
        },
        b3: {
          heading: this.$t("suitB3").toString(),
          values: getValuesForGroups(this.format, ["b3"]),
        },
        c1: {
          heading: this.$t("suitC1").toString(),
          values: getValuesForGroups(this.format, ["c1"]),
        },
        other: {
          // There shouldn't really be any other suit sizes, but just in case.
          heading: "",
          values: getValuesNotInGroup(this.format, ["b1", "b2", "b3", "c1"]),
        },
      };
    },
  },
  methods: {
    /**
     * Is selected?
     */
    isSelected(value) {
      return this.selectedValues.indexOf(value.id) !== -1;
    },
    /**
     * Change
     */
    change(facetValue: FacetValue, event: Event) {
      this.$emit("selectValue", {
        facetValue,
        state: (<HTMLInputElement>event.target).checked,
      });
    },
    /**
     * Format label
     */
    formatLabel(label: string) {
      return `${label.substring(0, 2)}/${label.substring(2)}`;
    },
  },
});

/**
 * Get values for groups
 *
 * Search facet values for labels beginning with <groupKey>. Such as:
 *
 * Get values for group B1:
 *
 * B156 -> true
 * B154 -> true
 * B152 -> true
 * B256 -> false
 * C156 -> false
 */
function getValuesForGroups(
  format: Format,
  groupKeys: Array<string>,
): Array<FacetValue> {
  const values: Array<FacetValue> = [];

  groupKeys.forEach((groupKey) => {
    format.values.forEach((value: FacetValue) => {
      if (value.label.toLowerCase().indexOf(groupKey.toLowerCase()) !== -1) {
        values.push(value);
      }
    });
  });

  return values;
}

/**
 * Get values not in group
 */
function getValuesNotInGroup(
  format: Format,
  groupKeys: Array<string>,
): Array<FacetValue> {
  return format.values.filter((value: FacetValue) => {
    let notInGroup = true;

    groupKeys.forEach((groupKey) => {
      if (value.label.toLowerCase().indexOf(groupKey.toLowerCase()) !== -1) {
        notInGroup = false;
      }
    });

    return notInGroup;
  });
}
</script>
function defineComponent(arg0: { props: { selectedValues: ArrayConstructor;
facet: ObjectConstructor; format: FunctionConstructor; }; computed: { groups():
Groups; }; methods: { ...; }; }) { throw new Error("Function not implemented.");
}
