import { Buffer } from "buffer";
import { LogErrorResponse } from "../Logging/LoggingAPI";

const API_URL = "/api/profile";

/**
 * Logs in a user with the provided email and password.
 * The credentials are sent as a base64 encoded string in the Authorization header
 *
 * @param email - The user's email.
 * @param password - The user's password.
 * @returns A promise that resolves to a boolean indicating whether the login was successful.
 */
export async function login(email: string, password: string): Promise<boolean> {
    const url = `${API_URL}/login`;
    const credentials = Buffer.from(`${email}:${password}`).toString("base64");

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Basic ${credentials}`,
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return false;
    }

    return true;
}

/**
 * Resets the password for a user.
 *
 * @param email - The email address of the user.
 * @returns A promise that resolves to a boolean indicating whether the password reset was successful.
 */
export async function resetPassword(email: string): Promise<boolean> {
    const url = `${API_URL}/resetpassword`;
    const body = JSON.stringify(email);

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: body,
    });

    if (!response.ok) {
        await LogErrorResponse(url, response, body);
        return false;
    }

    return true;
}
