const promotions = () => {
    // Disable enter key to force user to use submit-promotion button
    $('._js-promotions-input').keypress(function (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
        }
    });
};

export const init = () => {
    promotions();
};
