﻿export default class EditProfile {
    private SAVE_PROFILE_DATA_ENDPOINT = '/min-side/SaveProfileData';

    private form: HTMLFormElement | null;
    private fieldset: HTMLElement | null;
    private saveButton: HTMLElement | null;
    private status: HTMLElement | null;

    constructor(element) {
        this.form = element;
        this.fieldset = element.querySelector('._js-mypage-profile fieldset');
        this.saveButton = element.querySelector('._js-btn-mypage-save');
        this.status = element.querySelector('._js-mypage-save-status');
        this.initSubmit();
    }

    init = () => {
        this.initSubmit();
    };

    private initSubmit = () => {
        this.form?.addEventListener('submit', async (e) => {
            e.preventDefault();

            const success = await this.saveData();
            if (success) this.updatedetailsSuccess();
            this.updatedetailsComplete();
        });
        return;
    };

    private saveData = async () => {
        if (!this.form) return;

        const body = new FormData(this.form);
        this.updatedetailsBegin();

        try {
            const res = await fetch(this.SAVE_PROFILE_DATA_ENDPOINT, {
                method: 'POST',
                body: body,
            });
            return res.ok;
        } catch (error) {
            return false;
        }
    };

    private updatedetailsBegin = () => {
        this.fieldset?.setAttribute('disabled', 'disabled');
        this.saveButton?.classList.add('is-animating');
        this.saveButton?.setAttribute('disabled', 'disabled');
    };

    private updatedetailsComplete = () => {
        this.fieldset?.removeAttribute('disabled');
        this.saveButton?.classList.remove('is-animating');
        this.saveButton?.removeAttribute('disabled');
    };

    private updatedetailsSuccess = () => {
        if (!this.status) return;

        this.status.style.display = 'block';

        setTimeout(() => {
            if (this.status) this.status.style.display = 'none';
        }, 4000);
    };
}
