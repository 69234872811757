<script lang="ts" setup>
import { ref, computed } from "vue";
import Icon from "../icon/icon.vue";
import { TranslateResult } from "vue-i18n";
import { ComboboxOptionModel } from "../../services/models/combobox.models";

const props = defineProps<{
  heading: string | TranslateResult;
  hideHeading?: boolean;
  options: ComboboxOptionModel[];
  selectedId?: string;
}>();

const emits = defineEmits(["update:modelValue"]);

/**
 * Computed property that returns the index of the selected option in the combobox.
 * If no option is selected, it returns 0.
 *
 * @returns {number} The index of the selected option.
 */
const selectedIndex = computed(() => {
  if (!props.selectedId) return 0;

  return (
    props.options.findIndex((option) => option.id === props.selectedId) ?? 0
  );
});

const rootElement = ref();
const comboElement = ref();
const listboxElement = ref();
const comboboxOpen = ref(false);
const randomizedId = Math.random();
const comboContainerElement = ref<HTMLElement>();
const optionElements = ref<HTMLElement[]>();
const activeIndex = ref(selectedIndex.value);

// Save a list of named combobox actions, for future readability
const SelectActions = {
  Close: 0,
  CloseSelect: 1,
  First: 2,
  Last: 3,
  Next: 4,
  Open: 5,
  PageDown: 6,
  PageUp: 7,
  Previous: 8,
  Select: 9,
  Type: 10,
};

// map a key press to an action
function getActionFromKey(
  event: { key: any; altKey: any; ctrlKey: any; metaKey: any },
  menuOpen: any,
) {
  const { key, altKey, ctrlKey, metaKey } = event;
  const openKeys = ["ArrowDown", "ArrowUp", "Enter", " "]; // all keys that will do the default open action
  // handle opening when closed
  if (!menuOpen && openKeys.includes(key)) {
    return SelectActions.Open;
  }

  // home and end move the selected option when open or closed
  if (key === "Home") {
    return SelectActions.First;
  }
  if (key === "End") {
    return SelectActions.Last;
  }

  // handle typing characters when open or closed
  if (
    key === "Backspace" ||
    key === "Clear" ||
    (key.length === 1 && key !== " " && !altKey && !ctrlKey && !metaKey)
  ) {
    return SelectActions.Type;
  }

  // handle keys when open
  if (menuOpen) {
    if (key === "ArrowUp" && altKey) {
      return SelectActions.CloseSelect;
    } else if (key === "ArrowDown" && !altKey) {
      return SelectActions.Next;
    } else if (key === "ArrowUp") {
      return SelectActions.Previous;
    } else if (key === "PageUp") {
      return SelectActions.PageUp;
    } else if (key === "PageDown") {
      return SelectActions.PageDown;
    } else if (key === "Escape") {
      return SelectActions.Close;
    } else if (key === "Enter" || key === " ") {
      return SelectActions.CloseSelect;
    }
  }
}
// get an updated option index after performing an action
function getUpdatedIndex(
  currentIndex: number,
  maxIndex: number,
  action: number,
) {
  const pageSize = 10; // used for pageup/pagedown

  switch (action) {
    case SelectActions.First:
      return 0;
    case SelectActions.Last:
      return maxIndex;
    case SelectActions.Previous:
      return Math.max(0, currentIndex - 1);
    case SelectActions.Next:
      return Math.min(maxIndex, currentIndex + 1);
    case SelectActions.PageUp:
      return Math.max(0, currentIndex - pageSize);
    case SelectActions.PageDown:
      return Math.min(maxIndex, currentIndex + pageSize);
    default:
      return currentIndex;
  }
}

const updateMenuState = (open: boolean, callFocus = true) => {
  if (comboboxOpen.value === open) {
    return;
  }

  comboboxOpen.value = open;

  // update activedescendant
  const activeID = open ? `${randomizedId}-${selectedIndex.value}` : "";

  if (activeID === "" && !isElementInView(comboElement.value)) {
    comboElement.value.scrollIntoView({ behavior: "smooth", block: "nearest" });
  }

  // move focus back to the combobox, if needed
  callFocus && comboElement.value.focus();
};

// check if element is visible in browser view port
function isElementInView(element: { getBoundingClientRect: () => DOMRect }) {
  var bounding = element.getBoundingClientRect();

  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
}

// check if an element is currently scrollable
function isScrollable(element: { clientHeight: number; scrollHeight: number }) {
  return element && element.clientHeight < element.scrollHeight;
}

// ensure a given child element is within the parent's visible scroll area
// if the child is not visible, scroll the parent
function maintainScrollVisibility(
  activeElement: HTMLElement,
  scrollParent: HTMLElement,
) {
  const { offsetHeight, offsetTop } = activeElement;
  const { offsetHeight: parentOffsetHeight, scrollTop } = scrollParent;

  const isAbove = offsetTop < scrollTop;
  const isBelow = offsetTop + offsetHeight > scrollTop + parentOffsetHeight;

  if (isAbove) {
    scrollParent.scrollTo(0, offsetTop);
  } else if (isBelow) {
    scrollParent.scrollTo(0, offsetTop - parentOffsetHeight + offsetHeight);
  }
}

const onComboBlur = (event: { relatedTarget: any }) => {
  // do nothing if relatedTarget is contained within listboxElement
  if (listboxElement.value.contains(event.relatedTarget)) {
    return;
  }

  // select current option and close
  if (comboboxOpen.value) {
    selectOption(selectedIndex.value);
    updateMenuState(false, false);
  }
};

const onComboClick = (e) => {
  e.preventDefault();
  e.stopPropagation();
  updateMenuState(!comboboxOpen.value, false);
};

const onComboKeyDown = (event: KeyboardEvent) => {
  const { key } = event;
  const max = props.options.length - 1;

  const action = getActionFromKey(event, comboboxOpen.value);

  switch (action) {
    case SelectActions.Last:
    case SelectActions.First:
      updateMenuState(true);
    // intentional fallthrough
    case SelectActions.Next:
    case SelectActions.Previous:
    case SelectActions.PageUp:
    case SelectActions.PageDown:
      event.preventDefault();
      return onOptionChange(getUpdatedIndex(activeIndex.value, max, action));
    case SelectActions.CloseSelect:
      event.preventDefault();
      selectOption(activeIndex.value);
    // intentional fallthrough
    case SelectActions.Close:
      event.preventDefault();
      return updateMenuState(false);
    case SelectActions.Open:
      event.preventDefault();
      return updateMenuState(true);
  }
};

const onOptionChange = (index: number) => {
  activeIndex.value = index;
  if (!optionElements.value) return;

  // ensure the new option is in view
  if (isScrollable(listboxElement.value)) {
    maintainScrollVisibility(optionElements.value[index], listboxElement.value);
  }

  // ensure the new option is visible on screen
  // ensure the new option is in view
  if (!isElementInView(optionElements.value[index])) {
    optionElements.value[index].scrollIntoView({
      behavior: "smooth",
      block: "nearest",
    });
  }
};

const onOptionClick = (index: number) => {
  onOptionChange(index);
  selectOption(index);
  updateMenuState(false);
};

/**
 * Selects an option from the combobox.
 * Emits an event with the selected index.
 *
 * @param {number} index - The index of the option to select.
 */
const selectOption = (index: number) => {
  emits("update:modelValue", props.options[index].id);
};
</script>

<template>
  <div ref="rootElement">
    <label
      :id="`combobox-${randomizedId}-label`"
      class="combo__label"
      :class="props.hideHeading && 'sr-only'"
      >{{ props.heading }}</label
    >
    <div
      ref="comboContainerElement"
      class="combo js-select"
      :class="comboboxOpen && 'open'"
    >
      <div
        :id="`combox-${randomizedId}`"
        ref="comboElement"
        :aria-activedescendant="`${randomizedId}-${selectedIndex}`"
        aria-controls="listbox1"
        :aria-expanded="comboboxOpen"
        aria-haspopup="listbox"
        aria-labelledby="combo1-label"
        class="combo__input"
        role="combobox"
        tabindex="0"
        @blur="(e) => onComboBlur(e)"
        @click="(e) => onComboClick(e)"
        @keydown="(e) => onComboKeyDown(e)"
      >
        <div class="combo-input-content">
          <p
            v-if="props.options[selectedIndex]?.heading"
            class="combo__heading"
          >
            {{ props.options[selectedIndex].heading }}
          </p>
          <p
            v-if="props.options[selectedIndex]?.description"
            class="combo__description"
          >
            {{ props.options[selectedIndex].description }}
          </p>
        </div>
        <Icon
          ref="icon"
          class="combo__icon"
          size="accordion"
          type="chevron"
        ></Icon>
      </div>
      <div
        :id="`listbox-${randomizedId}`"
        ref="listboxElement"
        class="combo__menu"
        role="listbox"
        :aria-labelledby="`combobox-${randomizedId}-label`"
        tabindex="-1"
        @focusout="(e) => onComboBlur(e)"
      >
        <template
          v-for="(option, index) in props.options"
          :key="`${randomizedId}-${index}`"
        >
          <div
            :id="`${randomizedId}-${index}`"
            ref="optionElements"
            role="option"
            class="combo__option"
            :class="index == activeIndex && 'option-current'"
            :aria-selected="index === selectedIndex"
            :aria-current="index === activeIndex"
            @click.stop.prevent="() => onOptionClick(index)"
          >
            <div class="combo__option-content">
              <div class="">
                <p v-if="option.heading" class="combo__heading">
                  {{ option.heading }}
                </p>
                <p class="combo__description">
                  {{ option.description }}
                </p>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
