const toggleElement = (isToggled, element) => {
    element.setAttribute('aria-expanded', isToggled);
    element.classList.toggle('is-active');
    const elementToToggle = document.getElementById(element.dataset.togglewhat);

    if (elementToToggle) {
        elementToToggle.classList.toggle('is-active');
        elementToToggle.querySelectorAll('button, input, a').forEach(el => {
            el.setAttribute('tabindex', `${isToggled ? '0' : '-1'}`);
        });

        elementToToggle.focus();

        elementToToggle.dispatchEvent(
            new CustomEvent('elementToggled')
        );
    }

    //Ugly edgecases, but the modals rely on stackcontext being the same as the headers. Remove sticky from productdetails when modals open, add it when closing. 
    //Find a better solution for this later, this is a quick fix.
    if(element.dataset.togglewhat === 'storeInventory') {
        document.getElementById('product-details')?.classList.toggle('sticky');
    }
    if(element.dataset.togglewhat === 'sizeguide') {
        document.getElementById('product-details')?.classList.toggle('sticky');
    }
    if(element.dataset.togglewhat === 'inventoryNotification') {
        document.getElementById('product-details')?.classList.toggle('sticky');
    }
};

const toggler = () => {
    let isToggled = false;

    document.querySelectorAll('._js-generic-toggler').forEach(trigger => {
        const controlsWhat = trigger.dataset.togglewhat;

        trigger.setAttribute('aria-controls', controlsWhat);
        trigger.setAttribute('aria-expanded', 'true');

        // Remove tabindex on hidden content controlled by trigger
        const controlsElement = document.getElementById(controlsWhat);
        if (controlsElement) {
            controlsElement.querySelectorAll('button, input, a').forEach(el => {
                el.setAttribute('tabindex', '-1');
            });
        }

        trigger.addEventListener('click', e => {
            if (trigger.nodeName !== 'INPUT') {
                e.preventDefault();
            }
            isToggled = !isToggled;
            //Use currentTarget to find the the element that fired the event.
            //If there is a button with an svg inside e.target could be the svg,
            //we want the button element
            toggleElement(isToggled, e.currentTarget);
        });
    });

    //Needs to use triggerNested if elemtn is inside another toggler
    let toggledNested = false;
    document.querySelectorAll('._js-generic-toggler-nested').forEach(el => {
        el.addEventListener('click', e => {
            e.preventDefault();
            toggledNested = !toggledNested;
            //Use currentTarget to find the the element that fired the event.
            //If there is a button with an svg inside e.target could be the svg,
            //we want the button element
            toggleElement(toggledNested, e.currentTarget);
        });
    });
};

export const vueComponentToggler = () => {
    let isToggled = false;

    document.querySelectorAll('._js-vue-component-toggler').forEach(trigger => {
        const controlsWhat = trigger.dataset.togglewhat;

        trigger.setAttribute('aria-controls', controlsWhat);
        trigger.setAttribute('aria-expanded', 'true');

        // Remove tabindex on hidden content controlled by trigger
        const controlsElement = document.getElementById(controlsWhat);
        if (controlsElement) {
            controlsElement.querySelectorAll('button, input, a').forEach(el => {
                el.setAttribute('tabindex', '-1');
            });
        }

        trigger.addEventListener('click', e => {
            if (trigger.nodeName !== 'INPUT') {
                e.preventDefault();
            }
            isToggled = !isToggled;
            //Use currentTarget to find the the element that fired the event.
            //If there is a button with an svg inside e.target could be the svg,
            //we want the button element
            toggleElement(isToggled, e.currentTarget);
        });
    });
};

export const initToggle = () => {
    toggler();
};

export const closeOpenElement = (element) => {
    toggleElement(true, element);
};
