import { Product } from "../services/search.service";
import { getCustomApptusValue } from "../utils/search.utils";

export const computePromotion = (
    product: Product,
    smallLabel?: boolean,
) => {
    const labelText = getCustomApptusValue(product, "Promotion");

    if (labelText) {
        return {
            className: `label--amountdiscount ${smallLabel ? "label--small" : ""}`,
            text: labelText,
        };
    }

    return undefined;
};

export const computeMemberPromotion = (
    product: Product,
    smallLabel?: boolean,
) => {
    const labelText = getCustomApptusValue(product, "MemberPromotionTag");

    if (labelText) {
        return {
            className: `label--member ${smallLabel ? "label--small" : ""}`,
            text: labelText,
        };
    }

    return undefined;
};

export const computeBadge = (product: Product) => {
    const primaryBadges = product.badges?.primary;
    const secondaryBadges = product.badges?.secondary;

    const primaryBadge = primaryBadges;
    const secondaryBadge = secondaryBadges;

    const badge = primaryBadge || secondaryBadge;

    if (badge) {
        return {
            className: "label--amountdiscount", // TODO
            text: badge.label,
        };
    }
};
