declare global {
    interface Window {
        porterbuddy: {
            token: string;
            view: string;
        };
    }
}

export default function showPorterbuddyWidget() {
    const porterbuddyWidget = document.querySelector('#porterbuddy-widget') as HTMLElement;
    const addToCartFormComponent = document.querySelector('.add-to-cart-form-component');
    if (porterbuddyWidget && addToCartFormComponent) {
        window.porterbuddy = {
            token: porterbuddyWidget.dataset.pbToken ?? '',
            view: porterbuddyWidget.dataset.pbView || '',
        };
    }
}