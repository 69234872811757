import axios from 'axios';
import { initPostNordMap } from './map-postnord_wp';
import { formatNumber } from '../../utils';
import { pushShippingOption } from './gtmdata_wp';
import { formatPriceWithDash } from '../../global/apps/mixins/productPrice.mixin';

const moreDeliveryPoints = document.querySelector('._js-moredeliverypoints');
const moreChoices = document.querySelector('._js-delivery-more-choices');
const deliveryPoints = document.querySelectorAll('._js-delivery-point');
const deliveryPointMain = document.querySelector('.delivery-point__main');
const shipmentPlace = document.querySelector('._js-shipmentplace');
const sumDelivery = document.querySelector('._js-sumdelivery');
const sumCost = document.querySelector('._js-sumcost');
const sumItems = document.querySelector('._js-sumitems');
let initialSumItems = null;
const pbWrapper = document.querySelector('.porterbuddy-wrapper');

const ajax = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
});

const voiceDelivery = {
    deliveryWindow: null,
    validateWindow: function () {
        const deliveryWindows = window.porterbuddy.availabilityResponse.deliveryWindows;
        let valid = false;

        Object.keys(deliveryWindows).forEach(function (key) {
            if (deliveryWindows[key].start === voiceDelivery.deliveryWindow.start &&
                deliveryWindows[key].end === voiceDelivery.deliveryWindow.end) {
                valid = true;
            }
        });

        return valid;
    },
};

const delivery = () => {
    document.addEventListener('readystatechange', event => {
        if (event.target.readyState === 'complete') {
            const $porterRadioBtn = $('._js-select-porterbuddy .delivery-point__choose');
            if ($porterRadioBtn.is(':checked')) {
                $porterRadioBtn.trigger('click');
            }
        }
    });

    let markers;
    if (document.querySelector('#map')) {
        markers = initPostNordMap();
    }

    // Calculating summaryBox items
    if (sumItems) {
        initialSumItems = sumItems.dataset.sumitems;
    }

    // Setup
    const deliveryPoints = document.querySelectorAll('._js-delivery-point');
    if (deliveryPoints && deliveryPoints.length > 0) {
        deliveryPoints.forEach(point => {
            const input = point.querySelector('input');
            if (input && input.checked) {
                point.classList.add('is-selected');
            }
        });
    }

    // Set up porterbuddy widget
    const porterbuddyWidget = document.querySelector('#porterbuddy-widget');
    if (porterbuddyWidget) {
        const porterBuddyInput = document.querySelector('._js-select-porterbuddy .delivery-point__choose');

        if (porterbuddyWidget && porterBuddyInput) {
            if (porterBuddyInput.checked) {
                ajax.get('/publicapi/SelectedPorterbuddyDeliveryWindow')
                    .then(response => {
                        voiceDelivery.deliveryWindow = response.data;
                    });
            }

            window.porterbuddy = {
                token: porterbuddyWidget.dataset.pbToken,
                view: porterbuddyWidget.dataset.pbView,
                availabilityResponse: JSON.parse(porterbuddyWidget.dataset.pbAvailability),
                alternateAvailabilityView: true,
                resetContext: true,
                wrapInForm: true,
                updateDeliveryWindowsInterval: 300,
                onComment: function (comment) {
                    ajax.post('/publicapi/PorterBuddyComment', {
                        comment
                    });
                },
                onLeaveAtDoorstep: function (leaveAtDoorstep) {
                    ajax.post('/publicapi/PorterBuddyLeaveAtDoorstep', {
                        leaveAtDoorstep
                    });
                },
                onSelectDeliveryWindow: function (window) {
                    ajax.post('/publicapi/SelectPorterBuddy', {
                        window
                    }).then(response => {
                        const sumDelivery = document.querySelector('._js-sumdelivery');
                        if (sumDelivery) {
                            sumDelivery.innerHTML = response.data === "0,-" ? 'Fri frakt' : response.data;
                        }
                    });
                },
                onSetCallbacks: function (callbacks) {
                    voiceDelivery.updateDeliveryWindows = callbacks.updateDeliveryWindows;
                    voiceDelivery.setSelectedDeliveryWindow = callbacks.setSelectedDeliveryWindow;
                }
            };
        }
    }

    const porterbuddyInitTrigger = document.querySelector('._js-select-porterbuddy .delivery-point__choose');
    if (porterbuddyInitTrigger) {
        porterbuddyInitTrigger.addEventListener('click', () => {
            pbWrapper.classList.remove('is-hidden');

            setTimeout(() => {
                voiceDelivery.updateDeliveryWindows(porterbuddyWidget.dataset.pbAvailability);

                if (voiceDelivery.deliveryWindow && voiceDelivery.validateWindow()) {
                    voiceDelivery.setSelectedDeliveryWindow(voiceDelivery.deliveryWindow);
                } else {
                    deliveryObject.setSelectedDeliveryWindow(null, true);
                    const timeTable = document.querySelector('.pb-matrix__timetable');
                    if (timeTable) {
                        timeTable.querySelector('[data-index="0"]').click();
                    }
                }

                window.dispatchEvent(new Event('resize'));
            }, 100);
        });
    }

    // Click delivery point
    if (deliveryPoints && deliveryPoints.length > 0) {
        deliveryPoints.forEach(point => {
            point.addEventListener('click', e => {
                if (point.classList.contains('_js-select-porterbuddy')) {
                    pbWrapper.classList.remove('is-hidden');
                    setTimeout(() => {
                        voiceDelivery.updateDeliveryWindows(porterbuddyWidget.dataset.pbAvailability);

                        if (voiceDelivery.deliveryWindow && voiceDelivery.validateWindow()) {
                            voiceDelivery.setSelectedDeliveryWindow(voiceDelivery.deliveryWindow);
                        } else {
                            deliveryObject.setSelectedDeliveryWindow(null, true);
                            const timeTable = document.querySelector('.pb-matrix__timetable');
                            if (timeTable) {
                                timeTable.querySelector('[data-index="0"]').click();
                            }
                        }

                        window.dispatchEvent(new Event('resize'));
                    }, 100);
                } else {
                    if (pbWrapper)
                        pbWrapper.classList.add('is-hidden');
                }

                const name = point.dataset.name;
                markers.forEach(function (marker) {
                    if (name === marker.title) {
                        window.google.maps.event.trigger(marker, 'click');
                    }
                });

                const currentLocation = point.id;
                pushShippingOption(point.dataset.trackingname);

                currentDelivery(currentLocation);
                e.preventDefault();
            });
        });
    }

    // More items
    if (moreDeliveryPoints) {
        moreDeliveryPoints.addEventListener('click', e => {
            e.target.classList.add('is-hidden');

            if (moreChoices) {
                moreChoices.classList.remove('is-hidden');
            }

            if (deliveryPointMain) {
                deliveryPointMain.classList.remove('inactive');
            }
        });
    }

    // Toogle map
    const deliveryMap = document.querySelector('._js-delivery-map-toggler');

    if (deliveryMap) {
        deliveryMap.addEventListener('click', () => {
            if (moreDeliveryPoints) {
                moreDeliveryPoints.classList.add('is-hidden');
            }

            if (moreChoices) {
                moreChoices.classList.remove('is-hidden');
            }

            if (deliveryPointMain) {
                deliveryPointMain.classList.remove('inactive');
            }
        });
    }

    // Opening hours
    const openingHours = document.querySelectorAll('._js-toggle-openinghours');
    if (openingHours && openingHours.length > 0) {
        openingHours.forEach(button => {
            button.addEventListener('click', () => {
                button.parentNode.querySelector('._js-delivery-openinghours').classList.toggle('is-active');
            });
        });
    }
};

export const init = () => {
    delivery();
};

// Calculating summaryBox items
export const summaryPrice = (newPrice, place) => {
    if (shipmentPlace) {
        shipmentPlace.innerHTML = `(${place})`;
    }

    if (newPrice == 0) {
        const newSumCost = formatNumber(initialSumItems);
        document.querySelector('._js-sumdelivery').innerHTML = 'Fri frakt';
        document.querySelector('._js-sumcost').innerHTML = formatNumber(newSumCost);
    } else {
        const intialSumItemsNumber = parseFloat(initialSumItems.replace(',', '.'));
        const intialSumItemsNumberFormatted = parseFloat(intialSumItemsNumber.toFixed(2));
        const shippingPriceFloat = parseFloat(newPrice.replace(',', '.'));
        const shippingPriceFloatFormatted = parseFloat(shippingPriceFloat.toFixed(2));
        const newSumCost = formatPriceWithDash(intialSumItemsNumberFormatted + shippingPriceFloatFormatted);
        sumDelivery.innerHTML = formatNumber(newPrice);
        sumCost.innerHTML = formatNumber(newSumCost);
    }
};

// Set current delivery
export const currentDelivery = elementId => {
    const el = document.getElementById(elementId);
    el.querySelector('input').checked = true;

    if (deliveryPoints && deliveryPoints.length > 0) {
        deliveryPoints.forEach(point => {
            const isCurrent = point.id;
            const input = point.querySelector('input');
            const isIt = input.checked ? input.value : null;

            if (isCurrent === isIt) {
                if (el) {
                    el.classList.add('is-selected');
                }

                let currentShipmentPrice = input.dataset.shipmentprice;
                let shipmentPlace = input.dataset.name;

                if (!currentShipmentPrice) {
                    if (input.closest('[data-shipmentprice]')) {
                        currentShipmentPrice = input.closest('[data-shipmentprice]').dataset.shipmentprice;
                    }
                }

                if (!shipmentPlace) {
                    if (input.closest('[data-name]')) {
                        shipmentPlace = input.closest('[data-name]').dataset.name;
                    }
                }

                if (currentShipmentPrice && shipmentPlace) {
                    summaryPrice(currentShipmentPrice, shipmentPlace);
                }

            } else {
                if (el) {
                    el.classList.remove('is-selected');
                }
            }
        });
    }
};
