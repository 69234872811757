<template>
  <div class="product-filter product-filter__selected-facets">
    <h3 class="sr-only">
      {{ $t("currentFilter") }}
    </h3>
    <div
      v-for="pill in pills"
      :key="pill.facetValueId"
      class="tag tag--small"
      role="button"
      tabindex="0"
      @click="click(pill)"
      @keyup.enter="click(pill)"
    >
      <span class="tag__text">{{ pill.label }}</span>
      <Icon :type="IconTypes.Close" :size="IconSizes.Small" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import { Facet, FacetValue, Pill } from "../../services/search.service";
import { getSelectedValuesFromFacet } from "../../utils/search.utils";

const props = defineProps<{
  facets: Array<Facet>;
}>();

const emit = defineEmits<{
  (
    e: "upsertFacet",
    facet: { facet: Facet; selectedValues: Array<string>; useFilter: boolean },
  );
}>();

const pills = computed((): Pill[] => {
  const pills = props.facets.map((facet) => {
    return getSelectedValuesFromFacet(facet).map((facetValue: FacetValue) => {
      return {
        facetId: facet.id,
        facetValueId: facetValue.id,
        label: getLabel(facetValue),
      };
    });
  });

  return ([] as Pill[]).concat.apply([], pills);
});

/**
 * Click
 */
function click({ facetId, facetValueId }) {
  const facet = props.facets.find((facet) => facet.id === facetId);

  const selectedValues = getSelectedValuesFromFacet(facet as Facet)
    .filter((facetValue: FacetValue) => {
      return facetValue.id !== facetValueId;
    })
    .map((facetValue) => facetValue.id);

  emit("upsertFacet", {
    facet: props.facets.find((facet) => facet.id === facetId) as Facet,
    selectedValues,
    useFilter: true,
  });
}

function getLabel(facetValue: FacetValue) {
  if (facetValue.id.indexOf("custom.matrixSize") !== -1) {
    return formatMatrixSizeLabel(facetValue.label);
  }
  if (facetValue.id.indexOf("custom.suitSize") !== -1) {
    return formatSuitSizeLabel(facetValue.label);
  }

  return facetValue.label;
}

function formatMatrixSizeLabel(label: string): string {
  let w, l;

  if (label.length === 3) {
    w = label.substring(0, 1);
    l = label.substring(1);
  } else {
    w = label.substring(0, 2);
    l = label.substring(2);
  }

  return `${w}/${l}`;
}

function formatSuitSizeLabel(label: string): string {
  return `${label.substring(0, 2)}/${label.substring(2)}`;
}
</script>
