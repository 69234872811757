import {
    AfterpayMethods,
    hasAfterpayMethods,
} from "../../../../../helpers/checkoutHelpers";
import { z } from "zod";
import { FEEDBACK_TEXT, validationHelper } from "../../validationHelpers";
import { GoogleAnalytics4EventModelSchema } from "../GoogleAnalytics/GoogleAnalyticsSchema";

export type SelectListGroup = z.infer<typeof SelectListGroupSchema>;
export const SelectListGroupSchema = z.object({
    disabled: z.boolean(),
    name: z.string(),
});

export type SelectListItem = z.infer<typeof SelectListItemSchema>;
export const SelectListItemSchema = z.object({
    disabled: z.boolean(),
    group: SelectListGroupSchema.nullable(),
    selected: z.boolean(),
    text: z.string(),
    value: z.string(),
});

export type AfterPayTermsInfo = z.infer<typeof AfterPayTermsInfoSchema>;
export const AfterPayTermsInfoSchema = z.object({
    installmentAmount: z.string().nullable(),
    interestRate: z.string().nullable(),
    effectiveInterestRate: z.string().nullable(),
    effectiveAnnualPercentageRate: z.string(),
    totalInterestAmount: z.string().nullable(),
    startupFee: z.string().nullable(),
    monthlyFee: z.string().nullable(),
    totalAmount: z.string().nullable(),
});

export type AfterPayInstallmentInfo = z.infer<
    typeof AfterPayInstallmentInfoSchema
>;
export const AfterPayInstallmentInfoSchema = AfterPayTermsInfoSchema.extend({
    heading: z.string(),
});

export type AfterPayInstallmentViewModel = z.infer<
    typeof AfterPayInstallmentViewModelSchema
>;
export const AfterPayInstallmentViewModelSchema = z.object({
    installmentListItems: z.array(SelectListItemSchema).nullable(),
    installmentInfoItems: z.record(AfterPayInstallmentInfoSchema),
});

export type AfterPayAccountInfo = z.infer<typeof AfterPayAccountInfoSchema>;
export const AfterPayAccountInfoSchema = AfterPayTermsInfoSchema.extend({
    mainBody: z.string().optional(), // XhtmlString containing HTML markup
    montlyCost: z.string(),
    priceExample: z.string(),
});

export type AfterPayViewModel = z.infer<typeof AfterPayViewModelSchema>;
export const AfterPayViewModelSchema = z.object({
    accountInfo: AfterPayAccountInfoSchema,
    installmentInfo: AfterPayInstallmentViewModelSchema.nullable(),
    isAccountAvailable: z.boolean(),
    isInstallmentAvailable: z.boolean(),
});

export type LoginModel = z.infer<typeof CheckoutOptionViewModelSchema>;
export const CheckoutOptionViewModelSchema = z.object({
    description: z.string(),
    id: z.string(),
    isDefault: z.boolean(),
    name: z.string(),
});

export type PaymentMethodViewModel = z.infer<
    typeof PaymentMethodViewModelSchema
>;

export const PaymentMethodViewModelSchema =
    CheckoutOptionViewModelSchema.extend({
        disclaimer: z.string().nullable(),
        paymentMethodId: z.string().uuid(),
        systemKeyword: z.string(),
        requirePersonIdentifier: z.boolean(),
        imageId: z.string(),
        isAfterPayInstallment: z.boolean(),
        isAfterPayInvoice: z.boolean(),
        afterPayViewModel: AfterPayViewModelSchema.nullable(),
    });

export const AfterPayInstallmentTypeSchema = z.enum([
    AfterpayMethods.Account,
    AfterpayMethods.Installment,
]);

export type AfterPayInstallmentType = z.infer<
    typeof AfterPayInstallmentTypeSchema
>;

export type AddPaymentMethodRequestDto = z.infer<
    typeof AddPaymentMethodRequestDtoSchema
>;

export type SelectPaymentResultDto = z.infer<
    typeof SelectPaymentResultDtoSchema
>;

export type ValidatedCartResultDto = z.infer<
    typeof ValidatedCartResultDtoSchema
>;

export const PersonIdentifierSchema = z
    .string()
    .length(11, {
        message: FEEDBACK_TEXT().PERSON_IDENTIFIER_REQUIRED_LENGTH,
    })
    .superRefine((value, context) => {
        if (!validationHelper.containsOnlyNumbers(value)) {
            context.addIssue({
                code: z.ZodIssueCode.custom,
                message: FEEDBACK_TEXT().CAN_ONLY_CONTAIN_NUMBERS,
            });
        }
    });

export const AddPaymentMethodRequestDtoSchema = z.object({
    personIdentifier: PersonIdentifierSchema.optional(),
    selectedAfterPayInstallmentOption: z.string().optional(),
    selectedAfterPayInstallmentPlan: z.string().optional(),
});

export const UpdatedCartLineItemDtoSchema = z.object({
    name: z.string(),
    size: z.string(),
    oldQuantity: z.number(),
    newQuantity: z.number(),
});

export const ValidatedCartResultDtoSchema = z.object({
    cartLinesUpdated: z.array(UpdatedCartLineItemDtoSchema),
    cartTotal: z.number(),
    shippingTotal: z.number(),
    description: z.string().optional().nullable(),
    itemCount: z.number(),
});

export const SelectPaymentResultDtoSchema = z.object({
    url: z.string().optional().nullable(),
    errorMessage: z.string().optional().nullable(),
    cartResultDto: ValidatedCartResultDtoSchema.optional().nullable(),
});

export const createAddPaymentMethodRequestDtoSchema = (
    selectedPaymentMethod: PaymentMethodViewModel,
) => {
    return AddPaymentMethodRequestDtoSchema.refine(
        (data) => {
            if (
                selectedPaymentMethod.requirePersonIdentifier &&
                !data.personIdentifier
            ) {
                return false;
            }
            return true;
        },
        {
            message: FEEDBACK_TEXT().REQUIRED_PERSON_IDENTIFIER,
            path: ["personIdentifier"],
        },
    )
        .refine(
            (data) => {
                if (
                    hasAfterpayMethods(selectedPaymentMethod) &&
                    !data.selectedAfterPayInstallmentOption
                ) {
                    return false;
                }
                return true;
            },
            {
                message: FEEDBACK_TEXT().REQUIRED_AFTERPAY_INSTALLMENT_METHOD,
                path: ["selectedAfterPayInstallmentOption"],
            },
        )
        .refine(
            (data) => {
                if (
                    data.selectedAfterPayInstallmentOption ===
                        AfterpayMethods.Installment &&
                    !data.selectedAfterPayInstallmentPlan
                ) {
                    return false;
                }
                return true;
            },
            {
                message: FEEDBACK_TEXT().REQUIRED_AFTERPAY_INSTALLMENT_PLAN,
                path: ["selectedAfterPayInstallmentPlan"],
            },
        );
};

export type PaymentMethodsDto = z.infer<typeof PaymentMethodsDtoSchema>;
export const PaymentMethodsDtoSchema = z.object({
    paymentMethods: z.array(PaymentMethodViewModelSchema),
});

export type GetPaymentMethodsResponseModel = z.infer<typeof GetPaymentMethodsResponseModelSchema>;
export const GetPaymentMethodsResponseModelSchema = z.object({
    paymentMethodsDto: PaymentMethodsDtoSchema,
});