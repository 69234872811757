<script lang="ts" setup>
import { AfterPayInstallmentInfo } from "../../utils/API/Payment/paymentAPISchema";

const props = defineProps<{
  installment?: AfterPayInstallmentInfo;
}>();
</script>

<template>
  <div>
    <h3 v-if="props.installment">{{ props.installment.heading }}</h3>
    <ul>
      <li v-if="props.installment">
        <span>{{ $t("afterpayMontlycost") }}: </span
        ><span>{{ props.installment.installmentAmount }}</span>
      </li>
      <li v-if="props.installment">
        <span>{{ $t("afterpayInterest") }}: </span>
        <span>{{ props.installment.interestRate }}</span>
      </li>
      <li v-if="props.installment">
        <span>{{ $t("afterpayStartupfee") }}: </span>
        <span>{{ props.installment.startupFee }}</span>
      </li>
      <li v-if="props.installment">
        <span>{{ $t("afterpayMonthlyfee") }}: </span>
        <span>{{ props.installment.monthlyFee }}</span>
      </li>
      <li v-if="props.installment">
        <span>{{ $t("afterpayYearlyinterest") }}: </span>
        <span>{{ props.installment.effectiveAnnualPercentageRate }}</span>
      </li>
      <li v-if="props.installment">
        <span>{{ $t("afterpayTotalcost") }}: </span>
        <span>{{ props.installment.totalAmount }}</span>
      </li>
    </ul>
  </div>
</template>
