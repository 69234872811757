<template>
  <svg :class="classes">
    <title v-if="title">{{ title }}</title>
    <use xmlns:xlink="http://www.w3.org/1999/xlink" :xlink:href="href" />
  </svg>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  type: string;
  size?: string;
  title?: string;
}>();

const classes = computed(() => {
  const classes = {
    icon: true,
  };

  if (props.size) {
    classes[`icon--${props.size}`] = true;
  }

  return classes;
});
const href = computed(() => {
  return `#sprite-${props.type}`;
});
</script>
