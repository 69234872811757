const filter = () => {
    document.querySelectorAll('._js-filter-trigger').forEach(trigger => {
        const uniqueID = `f${Math.round(new Date().getTime() + (Math.random() * 100))}`;
        trigger.classList.remove('is-active');
        trigger.nextElementSibling.id = uniqueID;
        trigger.setAttribute('role',  'button');
        trigger.setAttribute('aria-controls', uniqueID);
        trigger.setAttribute('expanded', 'false');
        // Remove tabindex on hidden content controlled by trigger
        const content = document.querySelector(`#${uniqueID}`);
        content.querySelectorAll('button, input, a').forEach(c => {
            c.setAttribute('tabindex', '-1');
        });

        trigger.addEventListener('click', e => {
            e.preventDefault();
            const target = e.target;
            const categoryElement = target.closest('._js-filter-category');

            Array.from(categoryElement.parentElement.children).forEach(c => {
                //remove expanded from siblings
                if (categoryElement !== c) {
                    c.classList.remove('is-expanded');
                    //Remove aria expanded from collapsed filter button
                    const button = c.querySelector('._js-filter-trigger');

                    if (button) {
                        button.setAttribute('aria-expanded', 'false');
                    }
                }
            });

            //toggle expanded on itself
            categoryElement.classList.toggle('is-expanded');

            // Aria expand current
            trigger.setAttribute('aria-expanded', 'true');
        });
    });

    //mobile filters
    let toggledFilter = false;
    document.querySelectorAll('._js-filter-mobile-toggler').forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();
            toggledFilter = !toggledFilter;
            trigger.setAttribute('aria-expanded', toggledFilter);

            document.getElementById(trigger.dataset.togglewhat).classList.toggle('is-active');
        });
    });

    //Move sort on mobile
    // Move breadcumb when necessary
    const mq = window.matchMedia('(max-width: 47.9375em)');//max-width 767
    if (mq.matches) {
        const source = document.getElementById('productFilter');
        const target = document.getElementById('mobileFiltertarget');

        if (source && target) {
            target.insertAdjacentElement('beforeend', source);
        }
    }
};

export const init = () => {
    filter();
};
