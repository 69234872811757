import {getCookie, setCookie} from '../../utils';

const popup = () => {
    let $popup = $('[data-popup]'),
        popupCount,
        popupName = $popup.data('popup-name'),
        popupFrequency = Number($popup.data('popup-session-frequency')),
        popupTrigger = $popup.data('popup-trigger'),
        sessionCookieName = popupName,
        sustainedCookieName = popupName + 'Sustained',
        popupCookieVal,
        sustainedPopupCookieVal = getCookie(sustainedCookieName);

    if (sustainedPopupCookieVal) {
        popupCount = Number(sustainedPopupCookieVal);
    } else {
        popupCount = 0;
    }

    if (popupTrigger === 'onload') {
        popupCookieVal = getCookie(sessionCookieName);

        if (popupCookieVal !== 'v' && popupCount < popupFrequency) {
            setCookie(sessionCookieName, 'v', '0.08');//about two hours expiration
            setCookie(sustainedCookieName, popupCount + 1, '60');//lasts two months
            $popup.addClass('is-active');
        } else {
            setCookie(sessionCookieName, 'v', '0.08');//about two hours expiration
        }
    }

    $popup
        .on('click', function () {
            $popup.removeClass('is-active');
        })
        .on('click', '[data-close-popup]', function () {
            $popup.removeClass('is-active');
        })
        .on('click', '.popup__content', function (event) {
            event.stopPropagation();
        });
};

export const init = () => {
    popup();
};

