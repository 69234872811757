import Instafeed from 'instafeed.js';

const getUrl = (caption) => {
    const urls = caption.match(/(((http|www))[^\s]+)/g);
    if (urls != null && urls.length > 0) {
        let url = urls[0];
        if (url.indexOf('http') !== 0)
            url = 'http://' + url;
        return url;
    }
    return urls;
};

export default class VoiceInstafeed{
    constructor(element){
        if (Instafeed) {
            const feed = new Instafeed({
                accessToken: element.dataset.accessToken,
                limit: 10,
                template:
                    '<div class="instafeed__item"><a href="{{link}}" class="" target="{{target}}" rel="noopener"><img src="{{image}}" class="instafeed__photo" alt="{{caption}}" /></a></div>',
                success: (response) => {
                    for (let i = 0; i < response.data.length; ++i) {
                        const url = getUrl(response.data[i].caption.text);
                        response.data[i].target = '_blank';
                        if (url) {
                            response.data[i].link = url;
                            response.data[i].target = 'self';
                        }
                    }
                }
            });
            feed.run();
        }
    }
}
