// useUpsell.ts
import { ref, computed } from "vue";
import { SearchResultRecommendList } from "../services/search.service";

const PRUDUCT_COUNT_FALLBACK = 4;

export function useUpsell() {
  const upsellModel = ref<undefined | SearchResultRecommendList>(undefined);
  const isLoading = ref(false);

  const setUpsellModel = (model: undefined | SearchResultRecommendList) => {
    upsellModel.value = model;
  };

  const setIsLoading = (loading: boolean) => {
    isLoading.value = loading;
  };

  const productCount = computed(() => {
    return (
      upsellModel.value?.recommendationLists[0]?.productGroups?.length ||
      PRUDUCT_COUNT_FALLBACK
    );
  });

  return {
    upsellModel,
    isLoading,
    setUpsellModel,
    setIsLoading,
    productCount,
  };
}