//When using gulp this file was written using async, but that wasn't supported by gulp,
//therefore the old version used to include facebooks regeneratorRuntime, and the file was
//compiled from es5 to es6, thats why the code contains 'regeneratorRuntime', '_context' and other
//other code that looks generated.
import 'regenerator-runtime/runtime';

const bambuser = () => {
    const bambuserTrigger = document.querySelector('[data-bambuser-trigger]');
    if (bambuserTrigger) {
        let scriptNode;

        (function () {
            const bambuserShowId = bambuserTrigger.getAttribute('data-bambuser-show-id');
            const bambuserLocale = bambuserTrigger.getAttribute('data-locale');
            const bambuserCurrency = bambuserTrigger.getAttribute('data-currency');
            const bambuserCartUrl = bambuserTrigger.getAttribute('data-checkout-url');

            if (!window.initBambuserLiveShopping) {
                (function () {
                    const getProducts = function getProducts(products) {
                        let response;
                        return regeneratorRuntime.async(function getProducts$(_context) {
                            while (1) {
                                switch (_context.prev = _context.next) {
                                case 0:
                                    _context.next = 2;
                                    return regeneratorRuntime.awrap(fetch('/publicapi/bambuser/products?codes=' + products.map(function (p) {
                                        return p.ref;
                                    }).join('&codes=')));

                                case 2:
                                    response = _context.sent;
                                    _context.next = 5;
                                    return regeneratorRuntime.awrap(response.json());

                                case 5:
                                    return _context.abrupt('return', _context.sent);

                                case 6:
                                case 'end':
                                    return _context.stop();
                                }
                            }
                        }, null, null, null, Promise);
                    };

                    const addToCart = function addToCart(code, quantity) {
                        let response;
                        return regeneratorRuntime.async(function addToCart$(_context2) {
                            while (1) {
                                switch (_context2.prev = _context2.next) {
                                case 0:
                                    _context2.next = 2;
                                    return regeneratorRuntime.awrap(fetch('/publicapi/bambuser/cart', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            code: code,
                                            quantity: quantity
                                        })
                                    }));

                                case 2:
                                    response = _context2.sent;
                                    _context2.next = 5;
                                    return regeneratorRuntime.awrap(response.json());

                                case 5:
                                    return _context2.abrupt('return', _context2.sent);

                                case 6:
                                case 'end':
                                    return _context2.stop();
                                }
                            }
                        }, null, null, null, Promise);
                    };

                    const updateCartItem = function updateCartItem(code, quantity) {
                        let response;
                        return regeneratorRuntime.async(function updateCartItem$(_context3) {
                            while (1) {
                                switch (_context3.prev = _context3.next) {
                                case 0:
                                    _context3.next = 2;
                                    return regeneratorRuntime.awrap(fetch('/publicapi/bambuser/cart', {
                                        method: 'POST',
                                        headers: {
                                            'Content-Type': 'application/json'
                                        },
                                        body: JSON.stringify({
                                            code: code,
                                            quantity: quantity
                                        })
                                    }));

                                case 2:
                                    response = _context3.sent;
                                    _context3.next = 5;
                                    return regeneratorRuntime.awrap(response.json());

                                case 5:
                                    return _context3.abrupt('return', _context3.sent);

                                case 6:
                                case 'end':
                                    return _context3.stop();
                                }
                            }
                        }, null, null, null, Promise);
                    };

                    const isCartEmpty = function isCartEmpty() {
                        let response;
                        return regeneratorRuntime.async(function isCartEmpty$(_context4) {
                            while (1) {
                                switch (_context4.prev = _context4.next) {
                                case 0:
                                    _context4.next = 2;
                                    return regeneratorRuntime.awrap(fetch('/publicapi/bambuser/cart'));

                                case 2:
                                    response = _context4.sent;
                                    _context4.next = 5;
                                    return regeneratorRuntime.awrap(response.json());

                                case 5:
                                    return _context4.abrupt('return', _context4.sent);

                                case 6:
                                case 'end':
                                    return _context4.stop();
                                }
                            }
                        }, null, null, null, Promise);
                    };

                    window.onBambuserLiveShoppingReady = function (player) {
                        player.configure({
                            locale: bambuserLocale,
                            currency: bambuserCurrency
                        });
                        player.on(player.EVENT.PROVIDE_PRODUCT_DATA, function (event) {
                            //get products based on sku from bambuser
                            getProducts(event.products).then(function (epiProducts) {
                                event.products.forEach(function (_ref) {
                                    const sku = _ref.ref;
                                    const url = _ref.url;
                                    const bambuserId = _ref.id;
                                    const epiProduct = epiProducts.find(function (p) {
                                        return p.QueryCode === sku;
                                    });

                                    if (epiProduct) {
                                        player.updateProduct(bambuserId, function (productFactory) {
                                            return productFactory.inheritFromPlaceholder() // -> The .product() method takes a function
                                                //    returning a product detail object
                                                .product(function (productDetailFactory) {
                                                    return productDetailFactory // Localized name of the product
                                                        .name(epiProduct.Name) // Brand name to display for the product
                                                        .brandName(epiProduct.Brand) // Localized description for the product
                                                        // Supports text or HTML string
                                                        // Disabled by default, contact your support at Bambuser for activation.
                                                        // .description(yourProduct.description)
                                                        // sku (or any other identifier for your product)
                                                        // NOTE: Should be the same as your product reference
                                                        // defined in Bambuser Dashboard
                                                        .sku(epiProduct.QueryCode) // Describes which index in the variations list below
                                                        // contains the default variation
                                                        // (e.g. if variations contain colors, and you want to display
                                                        //  the white version of a shirt, send the index for the white variation)
                                                        .defaultVariationIndex(0) // -> The .variations() method takes a function
                                                        //    returning an array of variations
                                                        .variations(function (variationFactory) {
                                                            return epiProduct.Colors.map(function (variation) {
                                                                return (// VariationFactory contains a factory returning a new instance of a variation
                                                                    variationFactory() // -> (Optional) The .attributes() method takes a function
                                                                        //    defines color attribute for the variation
                                                                        // .attributes((attributeFactory) =>
                                                                        //     attributeFactory
                                                                        //         // Color name in the variation dropdown selector,
                                                                        //         .colorName(variation.ColorName)
                                                                        //         // (Optional) Color Hex code e.g. '#7d58ee'
                                                                        //         .colorHexCode(variation.ColorHex)
                                                                        // )
                                                                        // List of image urls for the variation
                                                                        // ordered the same as you want it displayed
                                                                        .imageUrls(variation.ImageUrls) // Name of the variation
                                                                        // Shown if colorName is not provided as attribute.
                                                                        .name(variation.Name) // sku (or any other identifier for your product)
                                                                        // specific down to this variation
                                                                        .sku(variation.Code) // -> The .sizes() method takes a function that
                                                                        //    returns an array of sizes
                                                                        .sizes(function (sizeFactory) {
                                                                            return variation.Sizes.map(function (size) {
                                                                                return (// s contains a factory returning a new instance of a variation
                                                                                    sizeFactory() // Name of the size
                                                                                        // (used in dropdowns)
                                                                                        .name(size.Size) // Set whether this combination of
                                                                                        // variation and size is in stock
                                                                                        .inStock(size.InStock) // sku (or any other identifier for your product)
                                                                                        // specific down to this size (used for add-to-cart)
                                                                                        .sku(size.Code) // -> The price method contains a new chain
                                                                                        //    defines price for the variation and size combo
                                                                                        .price(function (priceFactory) {
                                                                                            return priceFactory // currency to show price in
                                                                                                // Optional - overrides the default currency
                                                                                                .currency(size.Currency) // current price as a number
                                                                                                .current(size.Price) // (Optional) original price
                                                                                                // Used in case the current is a sale price
                                                                                                .original(size.OriginalPrice);
                                                                                        })
                                                                                );
                                                                            });
                                                                        })
                                                                );
                                                            });
                                                        });
                                                });
                                        });
                                    }
                                });
                            });
                        });
                        player.on(player.EVENT.ADD_TO_CART, function (addedItem, callback) {
                            addToCart(addedItem.sku, 1).then(function () {
                                callback(true); // item successfully added to cart
                            }).catch(function (error) {
                                if (error.type === 'out-of-stock') {
                                    // Unsuccessful due to 'out of stock'
                                    callback({
                                        success: false,
                                        reason: 'out-of-stock'
                                    });
                                } else {
                                    // Unsuccessful due to other problems
                                    callback(false);
                                }
                            });
                        });
                        player.on(player.EVENT.UPDATE_ITEM_IN_CART, function (updatedItem, callback) {
                            updateCartItem(updatedItem.sku, updatedItem.quantity).then(function () {
                                // cart update was successful
                                callback(true);
                            }).catch(function (error) {
                                if (error.type === 'out-of-stock') {
                                    callback({
                                        success: false,
                                        reason: 'out-of-stock'
                                    });
                                } else {
                                    callback(false);
                                }
                            });
                        });
                        player.on(player.EVENT.CHECKOUT, function () {
                            player.showCheckout(bambuserCartUrl);
                        });
                        player.on(player.EVENT.SYNC_CART_STATE, function () {
                            //As of 29.09.21 the documentation https://bambuser.com/docs/one-to-many/cart-integration/#sync-the-player-cart says:
                            // > Currently, the update cart does not support anything more than emptying the player cart.
                            // Use your method to check if the user has checkout
                            isCartEmpty().then(function (data) {
                                if (data.CartItems && data.CartItems.length === 0) {
                                    // Emptying the in-player cart
                                    player.updateCart({
                                        items: []
                                    });
                                }
                            }).catch(function (error) {
                                console.log(error);
                            });
                        });
                    };

                    window.initBambuserLiveShopping = function (item) {
                        window.initBambuserLiveShopping.queue.push(item);
                    };

                    window.initBambuserLiveShopping.queue = [];
                    scriptNode = document.createElement('script');
                    scriptNode['src'] = 'https://lcx-embed-eu.bambuser.com/schibsted-partnerstudio/embed.js';
                    document.body.appendChild(scriptNode);
                })();
            }

            window.initBambuserLiveShopping({
                showId: bambuserShowId,
                node: document.getElementById('bambuser-cta'),
                type: 'overlay'
            });
        })();
    }
};

export const init = () => {
    bambuser();
};
