import { z } from "zod";
import {
    FEEDBACK_TEXT,
    SupportedFeedbackTextLocales,
} from "../../apps/utils/validationHelpers";

export const NewsletterSignupSchema = (
    locale: SupportedFeedbackTextLocales = "no",
) => {
    const feedback = FEEDBACK_TEXT(locale);

    return z.object({
        email: z
            .string()
            .trim()
            .toLowerCase()
            .min(1, { message: feedback.REQUIRED })
            .email({ message: feedback.INVALID }),
        birthday: z.date({
            errorMap: (issue, { defaultError }) => ({
                message:
                    issue.code === "invalid_type"
                        ? feedback.REQUIRED
                        : defaultError,
            }),
        }),
        gender: z.string().min(1, { message: feedback.REQUIRED }),
        acceptsTerms: z.boolean().refine((value) => value === true, {
            message: feedback.CONSENT_REQUIRED,
        }),
    });
};
