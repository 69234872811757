<script setup lang="ts">
import Icon from "../../components/icon/icon.vue";

type ErrorType = "global" | "line-item" | "success";

const props = defineProps<{
  error?: string;
  errorIsHtml?: boolean;
  type?: ErrorType;
}>();
</script>

<template>
  <div
    v-if="props.error"
    role="alert"
    class="checkout-feedback"
    :class="props.type && `checkout-feedback--${props.type}`"
  >
    <Icon type="info" size="tiny" />
    <span>{{ props.error }}</span>
    <!-- <span v-if="props.errorIsHtml" v-html="props.error"></span>
    <span v-if="!props.errorIsHtml">{{ props.error }}</span> -->
  </div>
</template>
