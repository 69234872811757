import { getCookie, setCookie } from '../../utils';

interface Window {
    zESettings: any;
    zE: (command: string, ...args: any[]) => void;
}

declare var window: Window;

export default class Chat {
    private element: HTMLElement;

    constructor(element: HTMLElement) {
        this.element = element;

        const chatActive = getCookie('chatOpen') === 'opened';
        const zenid = this.element.dataset.zenid;

        const load = (id: string | undefined) => {
            if (!id) return;

            const script = document.createElement('script');
            const scriptURL = 'https://static.zdassets.com/ekr/snippet.js?key=' + id;
            script.type = 'text/javascript';
            script.id = 'ze-snippet';
            script.async = false;
            script.src = scriptURL;
            document.getElementsByTagName('head')[0].appendChild(script);
            this.element.classList.add('is-active');
            window.zESettings = {
                webWidget: {
                    launcher: {
                        chatLabel: {
                            '*': 'Chat'
                        },
                        label: {
                            '*': 'Åpne'
                        }
                    }
                }
            };
            setTimeout(() => {
                window.zE('webWidget', 'setLocale', 'no');
            }, 3000);
        };

        if (chatActive) {
            load(zenid);
            this.element.classList.add('is-active');
        }

        this.element.addEventListener('click', () => {
            setCookie('chatOpen', 'opened', '1');
            load(zenid);
        });
    }
}
