const customerservice = () => {
    const navigation = document.querySelector('.layout-customerservice__navigation-wrapper'),
        navigationElements = document.querySelectorAll('.layout-customerservice__nav-box');

    if (navigation && navigationElements && navigationElements.length > 3) {
        for (var i = 0; i < navigationElements.length; i++) {
            if (navigationElements[i].classList.contains('self')) {
                navigation.scrollLeft = navigationElements[i].offsetLeft;
            }
        }

        navigation.scrollLeft = navigation.scrollLeft;
    }
}

export const init = () => {
    customerservice()
}
