/**
 * Router push
 */
export const routerPush = (config, router) => {
    router.push(config).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
            throw error;
        }
    });
};

/**
 * Router replace
 */
export const routerReplace = (config, router) => {
    router.replace(config).catch((error) => {
        if (error.name !== "NavigationDuplicated") {
            throw error;
        }
    });
};
