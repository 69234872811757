import { pushDataToGtm } from "../../../../global/apps/mixins/tracking";
import {
    GoogleAnalytics4EventModel,
} from "./GoogleAnalytics/GoogleAnalyticsSchema";

const USE_PIWIK_TRACKING = true;

/**
 * Handles GTM tracking by pushing data to the data layer object.
 *
 * @param dataLayerObject - The data layer object containing ecommerce data in new types provided by Piwik.
 */
export const handleGtmTracking = (
    dataLayerObject: GoogleAnalytics4EventModel
) => {
    if (!USE_PIWIK_TRACKING || !dataLayerObject) return;

    pushDataToGtm({ ecommerce: null });
    pushDataToGtm(dataLayerObject);
};