<template>
  <div class="product-filter__container" @keyup.esc="esc()">
    <Facet
      v-for="facet in facets"
      :key="facet.id"
      :facet="facet"
      :is-expanded="expanded === facet.id"
      @expand="expand($event)"
      @upsertFacet="upsertFacet($event)"
      @useFilter="useFilter()"
      @keyup.enter="expand(facet.id)"
    />
    <Sort
      :sort="sort"
      :is-expanded="expanded === SORT"
      @expand="expand(SORT)"
      @updateSort="updateSort($event)"
      @useFilter="useFilter()"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Facet from "./facet.vue";
import Sort from "./sort.vue";
import {
  Facet as SearchFacet,
  Sort as SortType,
} from "../../services/search.service";

const CLOSED = "-1";
const SORT = "sort";

const props = defineProps<{
  sort: SortType;
  facets: Array<SearchFacet>;
}>();

const expanded = ref("-1");
const sort = ref(props.sort);

const emit = defineEmits<{
  (
    e: "upsertFacet",
    facets: { facet: SearchFacet; selectedValues: Array<string> },
  ): void;
  (e: "updateSort", sort: string): void;
  (e: "useFilter"): void;
}>();

/**
 * Expand
 */
function expand(facetId: string) {
  if (expanded.value === facetId) {
    return (expanded.value = CLOSED);
  }

  expanded.value = facetId;
}
/**
 * Upsert facet
 */
function upsertFacet({
  facet,
  selectedValues,
}: {
  facet: SearchFacet;
  selectedValues: Array<string>;
}) {
  emit("upsertFacet", { facet, selectedValues });
}
/**
 * Update sort
 */
function updateSort(sort: string | null) {
  if (sort) emit("updateSort", sort);
}
/**
 * Use filter
 */
function useFilter() {
  expanded.value = CLOSED;

  emit("useFilter");
}
/**
 * Esc
 */
function esc() {
  expanded.value = CLOSED;
}
</script>
