export const postFormData = (url, body) => {
    return http(url, {
        body: body,
        /*         connectionTimeout: 10000,
        readTimeout: 5000,
        mode: 'cors', */
        method: "POST",
    });
};

export const get = (url) => {
    return http(url, {
        method: "GET",
    });
};

export const post = (url, body) => {
    return http(url, {
        ...body,
        method: "POST",
    });
};

const http = (url, body = null) => {
    return body ? fetch(url, body) : fetch(url);
    /*         .then(res => res.json())
        .catch((e) => console.log(e)); */
};
