import { LineItemModel } from "../utils/API/Cart/cartAPISchema";
import {
    GoogleAnalytics4EventModel,
    GoogleAnalytics4EventModelSchema,
    Item,
} from "../utils/API/GoogleAnalytics/GoogleAnalyticsSchema";
import { handleGtmTracking } from "../utils/API/GTM";
import { LogErrorResponse } from "../utils/API/Logging/LoggingAPI";
import { Ga4Event, Ga4Events } from "./models/gtm.models";
import { Product, ProductGroup } from "./search.service";

export const pushCheckoutEvent = async (
    eventName: Ga4Event,
    lineItem?: LineItemModel,
) => {
    const object = await getCheckoutEventModel(eventName, lineItem?.code);
    handleGtmTracking(object);
};

export const pushViewItemListEvent = async (
    itemListId: string,
    itemListName: string,
    isUserAuthenticated: boolean,
    productGroups: Array<ProductGroup>,
    upsellAlgorithm?: string,
) => {
    const object = await getViewItemListEventModel(
        itemListId,
        itemListName,
        isUserAuthenticated,
        productGroups,
        upsellAlgorithm,
    );

    if (!object) return;
    handleGtmTracking(object);
};

export const pushSelectItemEvent = async (
    itemListId: string,
    itemListName: string,
    isUserAuthenticated: boolean,
    product: Product,
    productIndex?: number,
    upsellAlgorithm?: string,
) => {
    const object = await getSelectItemEventModel(
        itemListId,
        itemListName,
        isUserAuthenticated,
        product,
        productIndex,
        upsellAlgorithm,
    );

    if (!object) return;
    handleGtmTracking(object);
};

export const pushViewPromotionEvent = (
    itemId: string,
    itemName: string,
    isUserAuthenticated: boolean,
    creativeName?: string,
    creativeSlot?: string,
) => {
    const object = getViewPromotionEvent(
        itemId,
        itemName,
        isUserAuthenticated,
        creativeName,
        creativeSlot,
    );

    if (!object) return;
    handleGtmTracking(object);
};

export const pushSelectPromotionEvent = (
    itemId: string,
    itemName: string,
    isUserAuthenticated: boolean,
    linkHref?: string,
    creativeName?: string,
    creativeSlot?: string,
) => {
    const object = getSelectPromotionEvent(
        itemId,
        itemName,
        isUserAuthenticated,
        linkHref,
        creativeName,
        creativeSlot,
    );

    if (!object) return;
    handleGtmTracking(object);
};

export const getCheckoutEventModel = async (
    eventName: string,
    lineItemCode?: string,
): Promise<GoogleAnalytics4EventModel> => {
    const url = `/api/tracking/checkoutevent${lineItemCode ? `?itemCode=${lineItemCode}` : ""}`;

    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const validatedData = GoogleAnalytics4EventModelSchema.parse(data);
    validatedData.event = eventName;

    return validatedData;
};

const getSelectItemEventModel = async (
    itemListId: string,
    itemListName: string,
    isUserAuthenticated: boolean,
    product: Product,
    productIndex?: number,
    algorithm?: string,
) => {
    const item = getItem(product, itemListId, itemListName, productIndex);
    if (!item) return null;

    const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
        event: Ga4Events.SelectItem,
        logged_in_status: isUserAuthenticated,
        ecommerce: {
            item_list_id: itemListId,
            item_list_name: itemListName,
            upsell_algorithm: algorithm,
            items: [item],
        },
    };

    return googleAnalytics4EventModel;
};

const getViewItemListEventModel = async (
    itemListId: string,
    itemListName: string,
    isUserAuthenticated: boolean,
    productGroups: Array<ProductGroup>,
    algorithm?: string,
) => {
    const items = getItems(productGroups, itemListId, itemListName);

    if (!items.length) return null;

    const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
        event: Ga4Events.ViewItemList,
        logged_in_status: isUserAuthenticated,
        ecommerce: {
            item_list_id: itemListId,
            item_list_name: itemListName,
            upsell_algorithm: algorithm,
            items: items,
        },
    };

    return googleAnalytics4EventModel;
};

const getViewPromotionEvent = (
    creativeName: string,
    creativeSlot: string,
    isUserAuthenticated: boolean,
    promotionId?: string,
    promotionName?: string,
) => {
    if (!creativeName || !creativeSlot) return null;

    const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
        event: Ga4Events.ViewPromotion,
        logged_in_status: isUserAuthenticated,
        ecommerce: {
            promotion_id: promotionId,
            promotion_name: promotionName,
            creative_name: creativeName,
            creative_slot: creativeSlot,
            items: [],
        },  
    };

    return googleAnalytics4EventModel;
}

const getSelectPromotionEvent = (
    creativeName: string,
    creativeSlot: string,
    isUserAuthenticated: boolean,
    promotionDestination?: string,
    promotionId?: string,
    promotionName?: string,
) => {
    if (!creativeName || !creativeSlot) return null;

    const googleAnalytics4EventModel: GoogleAnalytics4EventModel = {
        event: Ga4Events.ViewPromotion,
        logged_in_status: isUserAuthenticated,
        ecommerce: {
            promotion_id: promotionId,
            promotion_name: promotionName,
            promotion_destionation: promotionDestination,
            creative_name: creativeName,
            creative_slot: creativeSlot,
            items: [],
        },  
    };

    return googleAnalytics4EventModel;
}

const getItems = (
    productGroups: Array<ProductGroup>,
    itemListId: string,
    itemListName: string,
): Item[] => {
    return (
        productGroups?.map((productGroup, productGroupIndex) => {
            const product = productGroup.products[0];
            return getItem(product, itemListId, itemListName, productGroupIndex);
        }) || []
    );
};

const getItem = (
    product: Product,
    itemListId: string,
    itemListName: string,
    productGroupIndex?: number
): Item => {
    let item = {
        item_id: product.key,
        item_name: product.title,
        item_brand: product.brand,
        item_variant: product.custom.ColorName[0]?.label || "",
        price: product.sellingPrice.max,
        discount: product.listPrice.max - product.sellingPrice.max,
        item_list_id: itemListId,
        item_list_name: itemListName,
        department: product.custom.Dept[0]?.label || "",
        in_stock: product.inStock,
        index: productGroupIndex,
    } as Item;

    assignCategories(item, product.custom);
    return item;
};

const assignCategories = (item: Item, custom) => {
    if (!custom?.Category) return;

    if (custom.Category.length > 0)
        item.item_category = custom.Category[0].label;
    if (custom.Category.length > 1)
        item.item_category2 = custom.Category[1].label;
    if (custom.Category.length > 2)
        item.item_category3 = custom.Category[2].label;
    if (custom.Category.length > 3)
        item.item_category4 = custom.Category[3].label;
    if (custom.Category.length > 4)
        item.item_category5 = custom.Category[4].label;

    return item;
};
