const scaleVideo = (el, width, height) => {
    const srcWidth = width;
    const srcHeight = height;
    const ratio = srcWidth / srcHeight;

    if (ratio >= 1) {
        $('#' + el).closest('.category-grid__item').addClass('category-grid__item--double');
        $('#' + el).closest('.gallery__item').css('grid-column', 'span 2');
    }
};

const controlvideo = el => {
    const videoElement = document.getElementById(el);
    const controls = videoElement.nextElementSibling;

    // Video controls
    $(controls).on('click', function () {
        if (videoElement.paused) {
            $(controls).removeClass('playbtn');
            videoElement.play();
        } else {
            videoElement.pause();
            $(controls).addClass('playbtn');
        }
    });
};


const video = () => {
    let playerLarge;
    let options;
    const videoPresent = $('#heroVideo').length;
    const fullVideoPresent = $('#fullvideo').length;

    if (window.Vimeo) {
        if (fullVideoPresent !== 0 && videoPresent == 0) {

            options = {
                width: 1080,
                loop: true
            };

            playerLarge = new Vimeo.Player('heroVideoLarge', options);

            playerLarge.setVolume(.5);

            $('._js-open-video').on('click', function () {
                playerLarge.play().then(function () {
                    // the video was paused
                    playerLarge.setVolume(.5);
                }).catch(function (reason) {
                    // Error
                    console.log(reason);
                });
            });

            $('._js-close-video').on('click', function () {
                playerLarge.pause();
            });
        }
        if (fullVideoPresent !== 0 && videoPresent !== 0) {

            options = {
                width: 1080,
                loop: true
            };

            const player = new window.Vimeo.Player('heroVideo', options);
            playerLarge = new window.Vimeo.Player('heroVideoLarge', options);

            player.setVolume(0);
            playerLarge.setVolume(.5);

            $('._js-open-video').on('click', function () {
                player.pause();
                playerLarge.play().then(function () {
                    // the video was paused
                    playerLarge.setVolume(.5);
                }).catch(function (reason) {
                    // Error
                    console.log(reason);
                });
            });

            $('._js-close-video').on('click', function () {
                playerLarge.pause();
                player.play().then(function () {
                    player.setVolume(0);
                }).catch(function (reason) {
                    // Error
                    console.log(reason);
                });
            });
        }

        // Product video
        $('._js-product-video').each(function () {
            const vidId = $(this).attr('id');
            const vid = document.getElementById(vidId);
            controlvideo(vidId);
            const videoReady = vid.readyState;
            if (videoReady >= 2) {
                scaleVideo(vidId, vid.videoWidth, vid.videoHeight);
            } else {
                vid.addEventListener('loadedmetadata', function () {
                    scaleVideo(vidId, vid.videoWidth, vid.videoHeight);
                });
            }
        });

        // Teaser video
        $('._js-teaser-video').each(function () {
            const videoElement = document.getElementById($(this).attr('id'));
            playpause(videoElement);
        });
    }
};

export const init = () => {
    video();
};
