export const formatCurrency = (currency: string) => {
    switch (currency.toLowerCase()) {
        case "nok":
        case "sek":
            return "kr";
        case "eur":
            return "€";
        case "gbp":
            return "£";
        case "usd":
            return "$";
        default:
            return currency;
    }
};
