export type Ga4Event =
    (typeof Ga4Events)[keyof typeof Ga4Events];
export const Ga4Events = {
    BeginCheckout: "begin_checkout",
    AddShippingInfoAddress: "add_shipping_info_address",
    AddShippingInfo: "add_shipping_info",
    AddPaymentInfo: "add_payment_info",
    Purchase: "purchase",
    AddToCart: "add_to_cart",
    RemoveFromCart: "remove_from_cart",
    ViewItemList: "view_item_list",
    SelectItem: "select_item",
    ViewPromotion: "view_promotion",
    SelectPromotion: "select_promotion",
    NoEvent: "",
} as const;