<template>
  <div>
    <!-- Dropdown -->
    <div class="layout-category__display-options">
      <div class="u-hide-for-small">
        <div class="product-filter__sorting">
          <label class="sr-only" for="sortselect">{{ $t("sort") }}</label>
          <select
            id="sortselect"
            class="select-list"
            @change="desktopChange($event)"
          >
            <option value="" :selected="!routerQueryParam">
              {{ $t("sortBy") }}:
            </option>
            <option
              v-for="option in sort.options"
              :key="option.id"
              :value="option.id"
              :selected="option.id === routerQueryParam"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="test u-hide-for-medium">
      <fieldset>
        <fieldset class="product-filter__heading u-hide-for-medium">
          {{ $t("sortBy") }}
        </fieldset>
        <label
          v-for="option in sort.options"
          :key="option.id"
          class="radio product-filter__checkbox"
        >
          <input
            v-model="selectedValue"
            type="radio"
            :value="option.id"
            @change="mobileChange($event)"
          />
          <span>{{ option.label }}</span>
        </label>
      </fieldset>
      <div class="product-filter__facetsubmit">
        <button
          class="btn btn--profile btn--small"
          @click="clickUseSort($event)"
        >
          {{ $t("useSort") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { Sort } from "../../services/search.service";

const props = defineProps<{
  sort: Sort;
}>();

const selectedValue = ref((props.sort as Sort).selected);
const initialSelectedValue = ref((props.sort as Sort).selected);

const route = useRoute();

const emit = defineEmits<{
  (e: "expand");
  (e: "updateSort", selectedValue: string | null);
  (e: "useFilter");
}>();

const routerQueryParam = computed(() => {
  return route.query.sort;
});

watch(
  () => props.sort,
  () => {
    selectedValue.value = (props.sort as Sort).selected;
    initialSelectedValue.value = (props.sort as Sort).selected;
  },
  { immediate: true },
);

/**
 * Change
 */
function desktopChange(e: Event) {
  selectedValue.value = (e.target as HTMLInputElement).value;
  emit("updateSort", selectedValue.value || null);
  setTimeout(() => emit("useFilter"));
}

/**
 * Change
 */
function mobileChange(e: Event) {
  selectedValue.value = (e.target as HTMLInputElement).value;
  emit("updateSort", selectedValue.value || null);
}

/**
 * Click use filter
 */
function clickUseSort(e: Event) {
  e.stopPropagation();

  emit("useFilter");
}
</script>
