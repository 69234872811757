import { closeOpenElement } from './toggler_wp';

export const sizeFunctions = {
    setSize: function(event, size) {
        const button = document.getElementById('sizeSelectorToggle');
        const buttonText = document.getElementById('sizeSelectorToggleText');
        buttonText.innerHTML = size.innerHTML;

        closeOpenElement(button);
    }
};
