import bubbleEvent from '../../helpers/event-bubble';

export default class Quantity {
    //TODO add API call here, remove from global object, remove Ajax Form
    constructor(element: HTMLElement){
        //We replace the DOM subtree everytime we increase the item count, need to use event delegation for event listeners
        bubbleEvent(element, 'click', '_js-quantity-button', this.eventHandler);
    }
    eventHandler(event: Event) {
        const target = event.target as HTMLElement;
        const inputElement = target.parentNode?.querySelector('input') as HTMLInputElement;

        if (!inputElement) return;

        const oldValue = parseFloat(inputElement.value);
        let newVal: number;

        if (target.classList.contains('_js-quantity-button-inc')) {
            newVal = oldValue + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 1) {
                newVal = oldValue - 1;
            } else {
                newVal = 1;
            }
        }
        inputElement.value = newVal.toString();
    }
}
