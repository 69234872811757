import { API_ERROR_MESSAGES, USER_FEEDBACK } from "../APIErrors";
import { LogErrorResponse } from "../Logging/LoggingAPI";

const API_URL = "/api/postal";

/**
 * Retrieves the city name associated with a given postal code.
 * If the postal code is not found, an empty string is returned.
 *
 * @param postalCode The postal code to retrieve the city for.
 * @returns A Promise that resolves to the city name.
 */
export const getCityFromPostalCode = async (
    postalCode: string,
): Promise<string> => {
    const url = `${API_URL}/${postalCode}`;

    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return API_ERROR_MESSAGES.COULD_NOT_GET_CITY;
    }

    const city = await response.json();
    return city === "" ? USER_FEEDBACK.UNKNOWN_POSTALCODE : city;
};
