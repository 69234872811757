import { FEEDBACK_TEXT } from "../global/apps/utils/validationHelpers";
import { sentenceCase } from "../../../helpers/checkoutHelpers";
import { NewsletterSignupSchema } from "./newsletter_signup_form_schema";

export default class NewsletterSignupForm {
    private SIGNUP_ENDPOINT = "/api/newsletter/register";
    private SUCCESSFUL_SIGNUP_TEXT: string;

    private form: HTMLFormElement;
    private fieldset: HTMLElement;
    private submitButton: HTMLElement;
    private submitFeedback: HTMLElement;
    private genericError: string;

    private fields: {
        [key: string]: { error: HTMLInputElement; input: HTMLInputElement };
    };

    constructor(element) {
        this.SUCCESSFUL_SIGNUP_TEXT = element.dataset.successfulSubmitMessage;

        this.form = element.querySelector("form");
        this.fieldset = element.querySelector("fieldset");
        this.submitButton = element.querySelector('button[type="submit"]');
        this.submitFeedback = element.querySelector(
            ".newsletter-block__submit-feedback",
        );
        this.genericError = element.dataset.failedSubmitMessage;

        this.fields = {
            email: {
                error: element.querySelector(".newsletter-block__email-error"),
                input: element.querySelector("input[name='email']"),
            },
            gender: {
                error: element.querySelector(".newsletter-block__gender-error"),
                input: element.querySelector("select[name='gender']"),
            },
            birthday: {
                error: element.querySelector(
                    ".newsletter-block__birthday-error",
                ),
                input: element.querySelector("input[name='birthday']"),
            },
            acceptsTerms: {
                error: element.querySelector(
                    ".newsletter-block__consent-error",
                ),
                input: element.querySelector("input[name='acceptsTerms']"),
            },
        };

        this.initSubmit();
    }

    init = () => {
        this.initSubmit();
    };

    /**
     * Initializes the submit event listener for the newsletter signup form.
     * When the form is submitted, it prevents the default form submission behavior,
     * disables the form fields, calls the submit method, and handles the success response.
     */
    private initSubmit = () => {
        this.form?.addEventListener("submit", async (e) => {
            if (!this.form) return;
            e.preventDefault();

            this.submitBegin();
            const success = await this.submit();
            this.submitComplete();

            if (!success) return;

            this.setSubmitFeedback(this.SUCCESSFUL_SIGNUP_TEXT);
        });
        return;
    };

    /**
     * Submits the newsletter signup form.
     * Builds a body object from the form fields, validates the body against the schema.
     * If the schema validation fails, it handles the input errors and returns false.
     * If the schema validation passes, it clears the input errors, sets the submit feedback to an empty string,
     * and sends a POST request to the signup endpoint with the body object.
     *
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the form submission was successful.
     */
    private submit = async () => {
        if (!this.form) return;

        const body = {
            email: this.fields.email.input?.value,
            gender: this.fields.gender?.input.value,
            birthday: this.fields.birthday?.input.value
                ? new Date(this.fields.birthday.input.value)
                : null,
            acceptsTerms: this.fields.acceptsTerms?.input.checked,
        };

        const newsletterSignupSchema = NewsletterSignupSchema("en");
        const validationResult = newsletterSignupSchema.safeParse(body);

        if (!validationResult.success) {
            this.handleInputErrors(validationResult.error);
            return false;
        }

        this.clearInputErrors();

        try {
            const res = await fetch(this.SIGNUP_ENDPOINT, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            if (!res.ok) {
                this.setSubmitFeedback(this.genericError);
            }

            return res.ok;
        } catch (error) {
            this.setSubmitFeedback(this.genericError);
        }
    };

    private setSubmitFeedback = (value) => {
        this.submitFeedback.innerHTML = value;
    };

    /**
     * Handles input errors by updating the corresponding fields with error messages and applying error styles.
     *
     * @param errors - The list of errors to handle.
     */
    private handleInputErrors = (errors) => {
        this.clearInputErrors();

        errors.errors.forEach((error) => {
            const fieldWithError = this.fields[error.path[0]];
            if (fieldWithError) {
                const errorMessageFormatted = this.formatErrorMessage(error);

                fieldWithError.error.innerHTML = errorMessageFormatted;
                fieldWithError.input.classList.add("error");
            }
        });
    };

    /**
     * Formats an error message based on the provided error object.
     * If the error path is "consents", it returns the error without a path prefix.
     *
     * @param error - The error object containing the path and message.
     * @returns The formatted error message.
     */
    private formatErrorMessage = (error: {
        path: string[];
        message: string;
    }) => {
        const includePathInErrorMessage = error.path[0] === "consents";

        const errorMessage = includePathInErrorMessage
            ? error.message
            : `${error.path} ${error.message}`;

        return sentenceCase(errorMessage);
    };

    /**
     * Clears the input errors and resets the form fields.
     */
    private clearInputErrors = () => {
        Object.values(this.fields).forEach(({ error, input }) => {
            error.innerHTML = "";
            input.classList.remove("error");
        });
    };

    /**
     * Handles the beginning of form submission.
     * Disables the form fieldset, adds an animation class to the submit button, and disables the submit button.
     */
    private submitBegin = () => {
        this.fieldset?.setAttribute("disabled", "disabled");
        this.submitButton?.classList.add("is-animating");
        this.submitButton?.setAttribute("disabled", "disabled");
    };

    /**
     * Handles the completion of form submission.
     * Enables the fieldset, removes animation class from the submit button, and enables the submit button.
     */
    private submitComplete = () => {
        this.fieldset?.removeAttribute("disabled");
        this.submitButton?.classList.remove("is-animating");
        this.submitButton?.removeAttribute("disabled");
    };
}
