import { SearchResultRecommendList } from "Scripts/global/apps/services/models/search.models";
import { API_ERROR_MESSAGES, USER_FEEDBACK } from "../APIErrors";
import { LogErrorResponse } from "../Logging/LoggingAPI";
import { UpsellRequestDto } from "./esalesAPISchema";

const API_URL = "/publicapi/esales";

export const upsell = async (
    upsellRequestDto: UpsellRequestDto,
) => {
    const url = `${API_URL}/upsell`;
    const body = JSON.stringify(upsellRequestDto);

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: body,
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        Promise.reject(USER_FEEDBACK.GENERIC);
    }

    const result = await response.json() as SearchResultRecommendList;
    return result;
};
