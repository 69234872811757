import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import CategoryPage from "./categoryPage.vue";

export enum CategoryPageRoutes {
    Root = "root",
}

const routes: Array<RouteRecordRaw> = [
    {
        name: CategoryPageRoutes.Root,
        path: "/",
        component: CategoryPage
    },
];

export default function (base?: string) {
    if (!base) return;

    return createRouter({
        history: createWebHistory(base),
        routes,
    });
}