<template>
  <fieldset
    v-if="isVisible"
    :aria-labelledby="labelId"
    :class="{
      'is-expanded': isExpanded,
    }"
    class="product-filter"
    role="group"
  >
    <!-- Trigger -->
    <button
      :id="labelId"
      :aria-controls="filterId"
      :aria-expanded="isExpanded"
      class="product-filter__heading u-hide-for-small"
      @click="clickToggle"
    >
      {{ facet.label }}
    </button>
    <legend class="u-hide-for-small sr-only">
      {{ facet.label }}
    </legend>
    <legend class="product-filter__heading u-hide-for-medium">
      {{ facet.label }}
    </legend>
    <!-- Dropdown -->
    <div
      :id="filterId"
      class="product-filter__content"
      :class="{
        'product-filter__content--grid':
          facet.type === FacetTypes.Color || facet.type === FacetTypes.Text,
      }"
    >
      <!-- Facet type: Color/text -->
      <template
        v-if="facet.type === FacetTypes.Color || facet.type === FacetTypes.Text"
      >
        <label
          v-for="value in facet.values"
          :key="value.id"
          class="checkbox product-filter__checkbox"
        >
          <input
            v-model="selectedValues"
            type="checkbox"
            :name="`facet${facet.id}`"
            :value="value.id"
            @change="change()"
            @keyup.enter="clickUseFilter($event)"
          />
          <span>{{ value.label }} ({{ value.count }})</span>
        </label>
      </template>
      <!-- Facet type: Size -->
      <template v-if="facet.type === FacetTypes.Size">
        <fieldset v-for="sizeType in facet.sizeTypes" :key="sizeType.id">
          <div v-for="format in sizeType.formats" :key="format.id">
            <!-- Suit sizes -->
            <template v-if="isSuitFormat(sizeType)">
              <SuitSizes
                :selected-values="selectedValues"
                :facet="facet"
                :format="format"
                @selectValue="selectValue($event)"
                @enter="clickUseFilter($event)"
              />
            </template>
            <!-- Matrix sizes -->
            <template v-else-if="isMatrixFormat(sizeType)">
              <MatrixSizes
                :selected-values="selectedValues"
                :facet="facet"
                :format="format"
                @selectValue="selectValue($event)"
                @enter="clickUseFilter($event)"
              />
            </template>
            <!-- Default -->
            <template v-else>
              <label
                v-for="value in format.values"
                :key="value.id"
                class="checkbox product-filter__checkbox checkbox product-filter__checkbox--sizes"
              >
                <input
                  v-model="selectedValues"
                  type="checkbox"
                  :name="`facet${facet.id}`"
                  :value="value.id"
                  @change="change()"
                  @keyup.enter="clickUseFilter($event)"
                />
                <span>{{ value.label }} ({{ value.count }})</span>
              </label>
            </template>
          </div>
        </fieldset>
      </template>
      <!-- Use button -->
      <div class="product-filter__facetsubmit">
        <button
          class="btn btn--profile btn--small"
          @click="clickUseFilter($event)"
        >
          {{ $t("useFilter") }}
        </button>
      </div>
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import {
  FacetTypes,
  FacetValue,
  SizeType,
} from "../../services/search.service";
import { getSelectedValueIdsFromFacet } from "../../utils/search.utils";
import MatrixSizes from "./facetFormats/matrixSizes.vue";
import SuitSizes from "./facetFormats/suitSizes.vue";
import { Facet } from "../../services/search.service";

const props = defineProps<{
  isExpanded: boolean;
  facet: Facet;
}>();

const selectedValues = ref([] as Array<string>);
const initialSelectedValues = ref([] as Array<string>);

const emit = defineEmits<{
  (e: "expand", id: string): void;
  (
    e: "upsertFacet",
    facet: { facet: Facet; selectedValues: Array<string> },
  ): void;
  (e: "useFilter"): void;
}>();

const labelId = computed((): string => {
  return `facetLabel-${props.facet.id}`;
});
const filterId = computed((): string => {
  return `facetFilter-${props.facet.id}`;
});
const isVisible = computed((): boolean => {
  switch (props.facet.type) {
    case FacetTypes.Color:
      return (props.facet.values && props.facet.values.length > 0) as boolean;
    case FacetTypes.Text:
      return (props.facet.values && props.facet.values.length > 0) as boolean;
    case FacetTypes.Size:
      return (props.facet.sizeTypes &&
        props.facet.sizeTypes.length > 0) as boolean;
  }

  return false;
});
watch(
  () => props.facet,
  () => {
    selectedValues.value = getSelectedValueIdsFromFacet(props.facet);
    initialSelectedValues.value = ([] as Array<string>).concat(
      selectedValues.value,
    );
  },
  { immediate: true },
);

/**
 * Click toggle
 */
function clickToggle() {
  emit("expand", props.facet.id);
}
/**
 * Change
 */
function change() {
  emit("upsertFacet", {
    facet: props.facet,
    selectedValues: selectedValues.value,
  });
}
/**
 * Click use filter
 */
function clickUseFilter(e: Event) {
  e.stopPropagation();

  emit("useFilter");
}
/**
 * Select value
 */
function selectValue({
  facetValue,
  state,
}: {
  facetValue: FacetValue;
  state: boolean;
}) {
  const isCurrentlySelected =
    selectedValues.value.indexOf(facetValue.id) !== -1;

  if (state === true && !isCurrentlySelected) {
    selectedValues.value.push(facetValue.id);

    change();
  }
  if (state === false && isCurrentlySelected) {
    selectedValues.value = selectedValues.value.filter(
      (selectedValue) => selectedValue !== facetValue.id,
    );

    change();
  }
}
/**
 * Is suit format?
 */
function isSuitFormat(sizeType: SizeType) {
  return sizeType.label === "suitSize";
}
/**
 * Is matrix format?
 */
function isMatrixFormat(sizeType: SizeType) {
  return sizeType.label === "matrixSize";
}
</script>
