import { defineStore } from "pinia";
import {
    CheckoutStage,
    CheckoutStages,
    CheckoutStep,
    CheckoutSteps,
} from "../../../services/models/checkout.models";
import { LineItemModel } from "Scripts/global/apps/utils/API/Cart/cartAPISchema";

export const useCheckoutStateStore = defineStore("checkoutState", {
    state: () => ({
        currentCheckoutStep: CheckoutSteps.CustomerInformation as CheckoutStep,
        currentCheckoutStage: CheckoutStages.ShoppingCart as CheckoutStage,
        isLoading: false,
        baseUrlPath: "",
        newItemsAfterVerification: null as null | Array<LineItemModel>
    }),
    actions: {
        setIsLoading(isLoading: boolean) {
            this.isLoading = isLoading; 
        },
        setCheckoutStage(stage: CheckoutStage) {
            this.currentCheckoutStage = stage;
        },
        setToCheckoutStep(step: CheckoutStep) {
            this.currentCheckoutStep = step;
        },
        setBaseUrlPath(baseUrlPath: string) {
            this.baseUrlPath = baseUrlPath;
        },
        setNewItemsAfterVerification(items) {
            this.newItemsAfterVerification = items;
        }
    },
});
