import { defineStore } from "pinia";
import { SearchResultRecommendList } from "../../../../../global/apps/services/models/search.models";

export const useUpsellStore = defineStore("upsell", {
    state: () => ({
        PRUDUCT_COUNT_FALLBACK: 4,
        upsellModel: undefined as undefined | SearchResultRecommendList,
        isLoading: false,
    }),
    actions: {
        setUpsellModel(upsellModel: undefined | SearchResultRecommendList) {
            this.upsellModel = upsellModel; 
        },
        setIsLoading(loading: boolean) {
            this.isLoading = loading;
        }
    },
    getters: {
        upsellProductCount: (state) => {
            return state.upsellModel?.recommendationLists[0]?.productGroups?.length || state.PRUDUCT_COUNT_FALLBACK;
        }
    }
});
