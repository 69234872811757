//Helper functions to replace jquery faster
//Primarily covers "$" functionality

//Returns an iterable array
const selectAll = (selector) => {
    return Array.from(document.querySelectorAll(selector));
};
export default selectAll;

export const selectOne = (selector) => {
    return selectAll(selector)[0];
};

export const ready = (fn) => {
    if (document.readyState != "loading") {
        fn();
    } else {
        document.addEventListener("DOMContentLoaded", fn);
    }
};
