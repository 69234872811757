﻿export default class ResetPassword {
    private RESET_PASSWORD_ENDPOINT = '/Login/ResetPassword';

    private form: HTMLElement;
    private submitTrigger: HTMLElement | null;
    private feedbackSuccess: HTMLElement | null;
    private feedbackError: HTMLElement | null;
    private profileData: HTMLFormElement | null;
    private email: string | undefined;

    constructor(element) {
        this.form = element;
        this.email = this.form.dataset.email;
        this.submitTrigger = this.form.querySelector('input[type="submit"]');
        this.feedbackSuccess = this.form.querySelector('.feedback--success');
        this.feedbackError = this.form.querySelector('.feedback--error');
        this.profileData = document.querySelector(
            '._js-mypage-profile'
        ) as HTMLFormElement;

        this.init();
    }

    init = () => {
        this.initSubmit();
    };

    private initSubmit = () => {
        this.form?.addEventListener('submit', async (e) => {
            e.preventDefault();
            this.resetPasswordBegin();
            const success = await this.resetPassword();
            this.resetPasswordComplete(success);
        });
        return;
    };

    private resetPassword = async () => {
        if (!this.profileData || !this.email) return false;

        const body = new FormData();
        body.append('email', this.email);

        try {
            const res = await fetch(this.RESET_PASSWORD_ENDPOINT, {
                method: 'POST',
                body: body,
            });
            return res.ok;
        } catch (error) {
            return false;
        }
    };

    private resetPasswordBegin = () => {
        this.submitTrigger?.setAttribute('disabled', 'true');
        this.feedbackError?.classList.remove(
            'reset-password__feedback--visible'
        );
        this.feedbackSuccess?.classList.remove(
            'reset-password__feedback--visible'
        );
    };

    private resetPasswordComplete = (success) => {
        this.submitTrigger?.removeAttribute('disabled');

        if (success && !this.feedbackSuccess?.classList.contains('reset-password__feedback--visible')) {
            this.feedbackSuccess?.classList.add('reset-password__feedback--visible');
            return;
        }

        if (!this.feedbackError?.classList.contains('reset-password__feedback--visible')) {
            this.feedbackError?.classList.add('reset-password__feedback--visible');
        }
    };
}
