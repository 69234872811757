import axios from 'axios';
import {scrollToTarget} from '../../utils';

export const checkoutFunctions = {
    togglePhoneLookup: () => {
        const status = document.querySelector('._js-PhoneLookupStatus').value || 'false';
        const formTarget = document.querySelector('._js-adressModelForm');
        const inputs = document.querySelectorAll('#_js-FormData input[type=text], #_js-FormData input[type=tel]');

        if (status === 'True' || status === 'true') {
            document.querySelector('._js-PhoneLookupHitText').style.display = 'none';
            document.querySelector('._js-PhoneLookupNoHitText').style.display = 'block';
        } else {
            // Scroll to form
            scrollToTarget(formTarget);
            voice.scrolltotarget.scrollPage(formTarget);
            // Animate Inputs
            inputs.addClass('autofilled');
        }
    },

    basketUpdateBegin: () => {
        const form = document.querySelector('._js-ajax-form');
        form.querySelectorAll('button,input').forEach(element => {
            element.disabled = true;
        });
    },

    basketUpdateSuccess: function () {
        document.querySelectorAll('._jsBasketUpdateCallbackTarget').forEach((index, target) => {
            const refreshUrl = target.dataset.refreshUrl;
            if (refreshUrl) {
                const ajax = axios.create({
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                        'type': 'html',
                    },
                });
                ajax.post(refreshUrl, {})
                    .then(response => {
                        target.replaceWith(response.data);
                    });
            }
        });
    },

    basketUpdateComplete: function () {
        const form = document.querySelector('._js-ajax-form');
        form.querySelectorAll('button,input').forEach(element => {
            element.disabled = false;
        });
    },
};

export const initCheckout = () => {
    // Hide Phone lookup error
    const phoneLookupNoHit = document.querySelector('._js-PhoneLookupNoHitText');
    const phoneLookupHit = document.querySelector('._js-PhoneLookupHitText');

    if (phoneLookupNoHit && phoneLookupHit) {
        phoneLookupNoHit.style.display = 'none';
        phoneLookupHit.style.display = 'block';
    }

    // Remember me
    // Password
    const addPassword = document.querySelector('._js-add-password');
    const selectPassword = document.querySelector('._js-select-password');
    const repeatPassword = document.querySelector('._js-password-repeat');
    const originalpassword = document.querySelector('._js-password-original');

    let toggled = false;

    if (addPassword && selectPassword && repeatPassword && originalpassword) {
        if (addPassword && addPassword.checked) {
            toggled = true;
            if (selectPassword && selectPassword.querySelector('input')) {
                selectPassword.querySelector('input').setAttribute('required', true);
            }
        } else {
            toggled = false;
            selectPassword.classList.add('is-hidden');
        }

        addPassword.addEventListener('change', () => {
            toggled = !toggled;
            selectPassword.classList.toggle('is-hidden');
            selectPassword.querySelector('input').setAttribute('required', toggled);
            repeatPassword.querySelector('input').setAttribute('required', toggled);
        });

        originalpassword.addEventListener('change', () => {
            originalpassword.setAttribute('pattern', originalpassword.value);
        });

        repeatPassword.addEventListener('input', e => {
            e.target.setCustomValidity('');
        });

        repeatPassword.addEventListener('invalid', e => {
            const message = e.target.dataset.validationMessage;
            if (message && message.length > 0) {
                e.target.setCustomValidity(message);
            }
        });
    }


    const afterPayNumber = document.querySelector('._js-afterpay-number');
    const afterPayOption = document.querySelector('._js-payment-option[value=afterpay]');

    if (afterPayOption) {
        const afterPayStatusInitial = document.querySelector('._js-payment-option[value=afterpay]').checked;

        if (afterPayStatusInitial) {
            afterPayNumber.setAttribute('required', true);
            afterPayNumber.setAttribute('disabled', false);
        } else {
            afterPayNumber.setAttribute('required', false);
            afterPayNumber.setAttribute('disabled', true);
        }
    }

    const paymentOption = document.querySelector('._js-payment-option');
    if (paymentOption) {
        paymentOption.addEventListener('change', () => {
            const afterPayOption = document.querySelector('._js-payment-option[value=afterpay]');

            if (afterPayOption) {
                if (afterPayOption.checked) {
                    afterPayNumber.setAttribute('required', true);
                    afterPayNumber.setAttribute('disabled', false);
                } else {
                    afterPayNumber.setAttribute('required', false);
                    afterPayNumber.setAttribute('disabled', true);
                }
            }
        });
    }

    const orderForm = document.querySelector('#orderForm');
    const payButton = document.querySelector('._js-btn-pay');
    if (orderForm && payButton) {
        orderForm.addEventListener('submit', () => {
            payButton.setAttribute('disabled', 'disabled');
        });
    }
};
