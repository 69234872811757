<template>
  <a
    :href="product.link"
    tabindex="0"
    @click="notifyClick($event, product)"
    @click.middle="notifyClick($event, product)"
    @click.right="notifyClick($event, product)"
  >
    <!-- Item -->
    <article class="quicksearch-product-item">
      <!-- Image -->
      <div class="quicksearch-product-item__image">
        <img :src="product.imageInfo.thumbnail" :alt="product.title" />
      </div>
      <!-- Info -->
      <div class="quicksearch-product-item__text">
        <!-- Heading -->
        <h4 class="quicksearch-product-item__heading">
          <span>{{ product.title }}</span>
        </h4>
        <!-- Badge -->
        <template v-if="promotion">
          <span :class="`label ${promotion.className} label--inline`">{{
            promotion.text
          }}</span>
        </template>
        <!-- Price -->
        <div class="quicksearch-product-item__price">
          <div v-if="price" class="price price">{{ price }}</div>
          <div v-if="newPrice" class="price price--new">{{ newPrice }}</div>
          <div v-if="oldPrice" class="price price--old">
            {{ oldPrice }}
          </div>
        </div>
      </div>
    </article>
  </a>
</template>

<script setup lang="ts">
import { notifyClick } from "../../mixins/notifyClick";
import { computePromotion } from "../../mixins/productBadge.mixin";
import {
  computePrice,
  computeNewPrice,
  computeOldPrice,
} from "../../mixins/productPrice.mixin";
import { Product } from "../../services/search.service";
import { computed } from "vue";

/*  mixins: [
        NotifyClick,
        ProductBadge,
        ProductPrice,
    ], */
const props = defineProps<{
  product: Product;
}>();

const promotion = computed(() => computePromotion(props.product));

const newPrice = computed(() => computeNewPrice(props.product));

const oldPrice = computed(() => computeOldPrice(props.product));

const price = computed(() => computePrice(props.product));
</script>
