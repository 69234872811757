import { lazy } from "../apps/mixins/lazy";
import { pushSelectPromotionEvent, pushViewPromotionEvent } from "../apps/services/gtm.service";

export default class PromotionTracking {
    private root: HTMLElement;
    private name: string;
    private id: string;
    private links: HTMLCollectionOf<HTMLAnchorElement>;

    constructor(element: HTMLElement) {
        this.root = element;
        this.name = element.dataset.name || '';
        this.id = element.dataset.id || '';
        this.init();
    }
    
    init = () => {
        this.links = this.root.getElementsByTagName('a');
        this.setupViewPromotionTracking();
        this.setupSelectPromotionTracking();
    };

    private setupViewPromotionTracking = () => {
        lazy(() => this.ViewPromotion(), this.root, true);
    }

    private setupSelectPromotionTracking = () => {
        Array.from(this.links)?.forEach((link) => {
            link.addEventListener('click', () => this.SelectPromotion(link));
        });
    }

    private ViewPromotion = () => {
        pushViewPromotionEvent(
            this.id,
            this.name,
            false,
            this.name,
            this.name
        );
    }
    
    private SelectPromotion = (link: HTMLAnchorElement) => {
        pushSelectPromotionEvent(
            this.id,
            this.name,
            false,
            link.href,
            this.name,
            this.name
        );
    }
}