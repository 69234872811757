<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { lazy } from "../../mixins/lazy";
import SearchService, {
  SearchResultRecommendList,
} from "../../services/search.service";
import ProductsModuleContent from "./productsModuleContent.vue";
import { useFavoritesStore } from "../../stores/favoritesStore";
import { pushViewItemListEvent } from "../../services/gtm.service";

const props = defineProps<{
  heading: string;
  baseUrlPath: string;
  apiUrl: string;
  userAuthenticated: string;
  favoriteFormHidden: string;
  lazy: string;
  limit: number;
  showAsColumnOnMobile: string;
  smallLabel?: string;
  useSkeletonLoaders?: string;
  hideSizes?: string;
  hideColors?: string;
  hidePromotion?: string;
  hideFavorites?: string;
  containerClass?: string;
  itemListName?: string;
  itemListId?: string;
  upsellAlgorithm?: string;
  trackViewItemList?: string;
  trackSelectItem?: string;
}>();

const hasFetched = ref(false);
const hasTrackedViewItemList = ref(false);
const searchResultRecommendList = ref<SearchResultRecommendList | null>(null);
const isLoading = ref(false);
const favoritesStore = useFavoritesStore();
const rootElement = ref();

// Computed properties
const hideFavorites = computed(() => props.favoriteFormHidden === "True");
const isUserAuthenticated = computed(() => props.userAuthenticated === "True");
const isLazy = computed(() => props.lazy === "True");
const isShowAsColumnOnMobile = computed(
  () => props.showAsColumnOnMobile === "True",
);
const isLimit = computed(() => Number(props.limit));
const shouldHideSizes = computed(() => props.hideSizes === "True");
const shouldHideColors = computed(() => props.hideColors === "True");
const shouldHidePromotion = computed(() => props.hidePromotion === "True");
const shouldHideFavorites = computed(() => props.hideFavorites === "True");
const shouldUseSkeletonLoaders = computed(
  () => props.useSkeletonLoaders === "True",
);
const shouldTrackViewItemList = computed(
  () => !!(!hasTrackedViewItemList.value && props.trackViewItemList === "True"),
);
const shouldTrackSelectItem = computed(() => props.trackSelectItem === "True");

const fetch = async () => {
  if (hasFetched.value) return;
  isLoading.value = true;

  await SearchService.products(props.apiUrl, props.baseUrlPath, props.limit)
    .then((response) => {
      hasFetched.value = true;
      searchResultRecommendList.value = response.data;
    })
    .catch((error) => {
      console.error(error);
    });

  isLoading.value = false;
};

onBeforeMount(() => {
  if (isLazy.value && !hasFetched.value) {
    lazy(fetch, rootElement.value, false);
  }
});

onMounted(() => {
  if (!isLazy.value) {
    fetch();
  }

  if (isUserAuthenticated.value) {
    favoritesStore.getFavorites();
  }
});
</script>
<!-- v-if="searchResultRecommendList?.primaryList?.productGroups" -->
<template>
  <div class="product-list" ref="rootElement">
    <ProductsModuleContent
      :heading="props.heading"
      :productGroups="searchResultRecommendList?.primaryList?.productGroups"
      :show-as-column-on-mobile="isShowAsColumnOnMobile"
      :user-authenticated="isUserAuthenticated"
      :favorite-form-hidden="hideFavorites"
      :is-loading="isLoading"
      :product-count="isLimit"
      :use-skeleton-loaders="shouldUseSkeletonLoaders"
      :hide-promotion="shouldHidePromotion"
      :hide-sizes="shouldHideSizes"
      :hide-colors="shouldHideColors"
      :hide-favorites="shouldHideFavorites"
      :containerClass="props.containerClass"
      :item-list-id="props.itemListId"
      :item-list-name="props.itemListName"
      :upsell-algorithm="props.upsellAlgorithm"
      :track-view-item-list="shouldTrackViewItemList"
      :track-select-item="shouldTrackSelectItem"
    />
  </div>
</template>
