import axios, { AxiosResponse } from "axios";

export default {
    /**
     * Get favorites
     */
    getFavorites(): Promise<AxiosResponse<Array<number | string>>> {
        return axios.get("/cart/GetFavorites?field=productCode");
    },
    /**
     * Toggle favorite
     */
    toggleFavorite(productCode: string): Promise<AxiosResponse<any>> {
        return axios.post(
            "/cart/ToggleFavorite",
            {
                productCode,
            },
            {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                },
            },
        );
    },
};
