<script setup lang="ts">
//Imports here
import { ref } from "vue";
import Icon from "../../components/icon/icon.vue";

//Dataset means all props are strings for now
const props = defineProps<{
  inlineStyle?: string;
  modalHeading?: string;
  heading?: string;
  discountCode: string;
  expirationDate: string;
  description?: string;
  image?: string;
}>();

const dialog = ref<HTMLDialogElement>();
const isExpanded = ref<boolean>(false);

function closeDialog() {
  setIsExpanded(false);
  dialog.value?.close();
}

function showDialog() {
  setIsExpanded(true);
  dialog.value?.showModal();
}

function setIsExpanded(expandedState: boolean) {
  isExpanded.value = expandedState;
}
</script>
<template>
  <div>
    <button
      class="loyalty-discounts__offer loyalty-discount reset-button"
      :style="inlineStyle"
      :aria-expanded="isExpanded"
      aria-haspopup="dialog"
      @click="showDialog()"
    >
      <div class="loyalty-discount__image-container">
        <img class="loyalty-discount__image" :src="image" />
      </div>
      <div class="loyalty-discount__textcontent">
        <span class="loyalty-discount__category">{{ $t("category") }}</span>
        <h2 v-html="heading" />
        <span v-if="discountCode" class="loyalty-discount__discountcode"
          >{{ $t("discountCode") }}: <strong>{{ discountCode }}</strong></span
        >
        <span v-if="expirationDate" class="loyalty-discount__expiration-date"
          >{{ $t("expires") }} {{ expirationDate }}</span
        >
      </div>
    </button>
    <dialog ref="dialog" class="dialog">
      <div class="dialog__layout">
        <div class="dialog__modal-heading">
          <h2 v-if="modalHeading">
            {{ modalHeading }}
          </h2>
          <button
            class="dialog__close btn btn--icon"
            :aria-label="$t('close').toString()"
            @click="closeDialog()"
          >
            <Icon type="close" />
          </button>
        </div>
        <div class="dialog__contents">
          <div class="loyalty-discount loyalty-discount--in-dialog">
            <div class="loyalty-discounts__offer">
              <div class="loyalty-discount__image-container">
                <img class="loyalty-discount__image" :src="image" />
              </div>
              <div class="loyalty-discount__textcontent">
                <span class="loyalty-discount__category">
                  {{ $t("category") }}</span
                >
                <h2 v-if="heading" v-html="heading" />
                <span v-if="discountCode" class="loyalty-discount__discountcode"
                  >{{ $t("discountCode") }}:
                  <strong>{{ discountCode }}</strong></span
                >
                <span
                  v-if="expirationDate"
                  class="loyalty-discount__expiration-date"
                  >{{ $t("expires") }} {{ expirationDate }}</span
                >
                <span
                  v-if="description"
                  class="loyalty-discount__description"
                  v-html="description"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </dialog>
  </div>
</template>
