export default class HeaderBanner {
    private SESSIONSTORAGE_PREFIX = 'headerbanner-hidden-';

    private banner: HTMLElement;
    private dismissButton: HTMLButtonElement | null;
    private id: string | undefined;
    private hidden: boolean;

    constructor(element) {
        this.banner = element;
        this.init();
    }
    
    init = () => {
        this.dismissButton = this.banner.querySelector('button');
        this.id = this.banner.dataset.id;
        this.hidden = this.getHiddenFromSessionStorage();
        this.initDismissButton();
        this.updateDisplayValue();
    };
    
    private initDismissButton = () => {
        this.dismissButton?.addEventListener('click', async (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.dismissBanner();
        });
    };

    private updateDisplayValue = () => {
        if (this.hidden && !this.banner.classList.contains('is-hidden')) {
            this.banner.classList.add('is-hidden');
        }    
    };

    private dismissBanner = () => {
        this.hidden = true;
        this.updateDisplayValue();
        this.setHiddenInSessionStorage();
    };
    
    private getHiddenFromSessionStorage = () => {
        return sessionStorage.getItem(this.sessionStorageKey()) === 'true';
    };
    
    private setHiddenInSessionStorage = () =>  {
        sessionStorage.setItem(this.sessionStorageKey(), 'true');
    };

    private sessionStorageKey = () => {
        return this.SESSIONSTORAGE_PREFIX + this.id;
    };
}
