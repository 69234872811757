import 'slick-carousel';

const SELECTORS = {
    main: '._js-gallery-main-image',
    mainGallery: '.main-gallery',
    thumbs: '._js-gallery-nav',
    thumbsSide: '._js-gallery-side-nav',
    mainThumbs: '._js-gallery-next-btn',
    sideGallery: '.side-gallery',
    largeImage: '.large-image',
    showcasedItem: '.main-gallery__showcased',
    indexable: '.thumbnail'
};

const slickslide = () => {
    if ($('._js-gallery').length > 0) {
        let currentSideItemIndex = 0;
        const windowSize = $(window).width();
        const mainGallery = $(SELECTORS.mainGallery);
        const showcasedItem = $(SELECTORS.showcasedItem);
        const sideGalleryItems = $(SELECTORS.thumbsSide).parent().find(SELECTORS.largeImage);
        const thumbnailItems = $(SELECTORS.thumbsSide).parent().find(SELECTORS.indexable);
        let showcasedItemLargeImage;

        mainGallery.hide();

        $(SELECTORS.thumbs).removeClass('is-hidden');

        //Set first image in main image div
        if (thumbnailItems.length > 0) {
            thumbnailItems[0].click;
            $(SELECTORS.showcasedItem).append(sideGalleryItems[0].cloneNode(true));

            // Now that an first image has been created, we can populate the variable for that image
            showcasedItemLargeImage = $(SELECTORS.showcasedItem).find(SELECTORS.largeImage)[0];

            if (showcasedItemLargeImage[0] && showcasedItemLargeImage[0].nodeName === 'VIDEO'){
                showcasedItemLargeImage[0].play();
            }
            $(SELECTORS.main).append($('._jsloveContainer'));
        }


        $(SELECTORS.indexable).on('click', function (e) {
            const current = $(this);

            removePreviousActiveSidePanel();

            for (var i = 0; i < thumbnailItems.length; i++) {
                if (current[0] === thumbnailItems[i]) {
                    currentSideItemIndex = i;
                }
            }

            replaceLargeImage();
        });

        // ARROW NAVIGATION
        $('body').on('click', '.slick-arrow', function () {
            $('.slick-slide.slick-active').each(function () {
                if (window.lazySizes) {
                    window.lazySizes.init();
                }
            });
        });

        // MAIN IMAGE NAVIGATION
        $(SELECTORS.mainThumbs).on('click', function(e) {
            const button = $(this);
            const amountOfItems = sideGalleryItems.length;

            //Remove previous selected galleryItem from sidepanel
            removePreviousActiveSidePanel();

            //Navigate forward in gallery
            if(button.hasClass('right-arrow')) {

                //At last image of imagelist
                if(currentSideItemIndex === amountOfItems -1) {
                    currentSideItemIndex = 0;
                }else{
                    currentSideItemIndex = currentSideItemIndex +1;
                }
            }

            //Navigate backwards in gallery
            else if(button.hasClass('left-arrow')){

                if(currentSideItemIndex === 0) {
                    currentSideItemIndex = sideGalleryItems.length -1;
                }else{
                    currentSideItemIndex = currentSideItemIndex -1;
                }
            }

            replaceLargeImage();
        });

        //Show active image on sidegallery
        function replaceLargeImage() {
            thumbnailItems[currentSideItemIndex].classList.add('active-slide');

            const currentItemToDisplay = sideGalleryItems[currentSideItemIndex];
            $(SELECTORS.showcasedItem).html(currentItemToDisplay.cloneNode(true));

            if (currentItemToDisplay.nodeName === 'VIDEO') {
                $(SELECTORS.showcasedItem).find(SELECTORS.largeImage)[0].play();
            }

            runMainImageAnimation();
        }

        function removePreviousActiveSidePanel(){
            if (thumbnailItems.length > 0){
                for (var i = 0; i < thumbnailItems.length; i++) {
                    if (thumbnailItems[i].classList.contains('active-slide')) {
                        thumbnailItems[i].classList.remove('active-slide');
                    }
                }
            }
        }

        function runMainImageAnimation(){
            showcasedItem.hide();
            showcasedItem.fadeIn();
        }

        $(window).on('load',function () {
            if (windowSize < 600) {
                const mobileGallery = $('#mobile-gallery');
                mobileGallery.append(mainGallery);
            }

            $(SELECTORS.mainThumbs).css({opacity:1});
            $('.loader').hide();
            mainGallery.show();

        });

        // block / generic
        $('._js-gallery-generic').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: true
        });
    }
};

export const init = () => {
    slickslide();
};
