<template>
  <div class="layout-searchpage__store-list">
    <h2 class="layout-searchpage__subheading u-text-center">
      {{ $t("hitsIn") }} {{ $t("stores") }}
      <span class="layout-searchpage__hit-count"
        >({{ nStores }} {{ $t("hits") }})</span
      >
    </h2>
    <ul v-if="stores">
      <li v-for="store in stores" :key="store.key">
        <Store :content-item="store" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Store from "./store.vue";
import {
  CONTENT_LIST_STORES,
  ContentList,
  ContentItem,
} from "../../services/search.service";

export default defineComponent({
  components: {
    Store,
  },
  props: ["contentLists"],
  computed: {
    contentList(): ContentList | undefined {
      return (this.contentLists || []).find(
        (contentList) => contentList.id === CONTENT_LIST_STORES,
      );
    },
    nStores(): number | undefined {
      if (!this.contentList) {
        return;
      }

      return this.contentList.totalHits;
    },
    stores(): Array<ContentItem> | undefined {
      if (!this.contentList) {
        return;
      }

      return this.contentList.items;
    },
  },
});
</script>
