import selectAll, { selectOne } from '../../helpers/jquery-replace';

const updateIndicator = (el) => {
    selectOne('.nav-main__link-indicator').style.left = el.offsetLeft;
};

const makeParentIndicator = (el) => {
    const template = document.createElement('template');
    template.innerHTML = '<span class=\'nav-main__link-indicator u-hide-for-small\' style=\'left:' + el.offsetLeft + 'px; width:' + el.offsetWidth + 'px;\'></span>';
    selectOne('.global-header').appendChild(template.content);
};

export default class Menu {
    constructor(element) {
        // Height of header
        const headerHeight = document.getElementById('_js-global-header__content').offsetHeight;

        const onLoad = () => {
            if (headerHeight != null) {
                selectOne('body').style['--headerHeight'] = headerHeight;
            }
        };

        if (document.readyState != 'loading'){
            onLoad();
        } else {
            document.addEventListener('DOMContentLoaded', onLoad);
        }
    
        // Toogle mega menu desktop hover
        const mainItems = selectAll('._js-nav-main-item');
        mainItems.forEach(el =>  {
            const mq = window.matchMedia('(min-width: 43em)');
            const [menuTrigger] = el.querySelectorAll('._js-nav-main-expander');
            const menuToExpand = el;
            const [menuToExpandContainer] = el.querySelectorAll('._js-mega-menu-container');
            if (mq.matches) {

                // Mouseovers
                menuTrigger.addEventListener('mouseenter', e => {
                    e.preventDefault();
                    selectAll('._js-nav-main-item')
                        .forEach(e => e.classList.remove('is-expanded'));
                    menuToExpand.classList.add('is-expanded');
                });
                menuToExpandContainer.addEventListener('mouseleave', function () {
                    menuToExpand.classList.remove('is-expanded');
                });
            }
        });
        
        // Toggle Mobile menu
        selectAll('._js-mobile-menu-trigger').forEach(e => {
            e.addEventListener('click', () => {
                element.classList.toggle('is-active');
                selectOne('body').classList.toggle('u-overflow-hidden');
            });
        });
        
        // Toggle mobile submenus
        const mobileExpanders = selectAll('._js-nav-main-expander');
        mobileExpanders.forEach( expander => {
            expander.addEventListener('click', e => {
        
                //MObil
                const mq = window.matchMedia('(max-width: 43em)');
                if (mq.matches) {
                    e.preventDefault();
                    expander.closest('._js-nav-main-item').classList.toggle('is-expanded');
                }
                
            });
        });
        
        if (selectAll('.nav-main__link.self').length > 0) {
            const menuElement = selectOne('.nav-main__link.self');
            makeParentIndicator(menuElement);
        
            window.onresize = () => {
                updateIndicator(menuElement);
            };
        } else if (selectAll('.nav-main__link.on-path').length > 0) {
            const menuElement = selectOne('.nav-main__link.on-path');
            makeParentIndicator(menuElement);
            window.onresize = () => {
                updateIndicator(menuElement);
            };
        }
    }
}
