import { defineComponent } from "vue";
import SearchService, {
    Product,
    ContentItem,
} from "../services/search.service";

export default defineComponent({
    methods: {
        notifyClick(e: Event, item: Product | ContentItem) {
            SearchService.notifyClick(item.ticket);
        },
    },
});

/**
 * Notify click
 */
export function notifyClick(e: Event, item: Product | ContentItem) {
    SearchService.notifyClick(item.ticket);
}
