const forms = () => {
    document.querySelectorAll('input select textarea').forEach(i => {
        i.addEventListener('blur', e => {
            e.target.classList.add('_js-input-touched');
        });
    });

    document.querySelectorAll(':required').forEach(requiredElement => {
        const label = document.querySelector(`label[for="${requiredElement.id}"]`) || requiredElement.closest('label');
        if (label) {
            label.classList.add('is-required');
        }
    });

    // Submit different form
    document.querySelectorAll('._js-submit-other').forEach(trigger => {
        trigger.addEventListener('click', e => {
            e.preventDefault();

            const targetFormId = trigger.dataset.targetForm;
            const targetForm = document.getElementById(targetFormId);

            if (targetForm) {
                targetForm.submit();
            }
        });
    });

    //Submit forms on radio change (ie search page)
    document.querySelectorAll('._js-submit-form').forEach(trigger => {
        trigger.addEventListener('change', e => {
            e.target.closest('form').submit();
        });
    });

    setupDeliveryMoreChoices();

    // Show password - refactor when needed
    //$('._js-show-password-toggle').on("click", function () {
    //    var input = $(this).parent().siblings("._js-show-password-input")[0];
    //    if ($(input).is(":password")) {
    //        $(input).attr("type", "text");
    //    } else if ($(input).is(":text")) {
    //        $(input).attr("type", "password");
    //    }
    //});
};

const setupDeliveryMoreChoices = () => {
    const deliveryMoreChoicesContainer = document.querySelector('._js-delivery-more-choices');
    if (!deliveryMoreChoicesContainer) return;

    const inputPostalCode = deliveryMoreChoicesContainer.querySelector('#pickupPointPostalCode');
    const inputCity = deliveryMoreChoicesContainer.querySelector('#pickupPointCity');
    const submitButton = deliveryMoreChoicesContainer.querySelector('button');
    const feedback = deliveryMoreChoicesContainer.querySelector('#delivery-point-feedback');

    if (!inputPostalCode || !inputCity || !feedback) return;

    submitButton.addEventListener('click', (e) => {
        if (!inputPostalCode.value || !inputCity.value) {
            e.preventDefault();
            feedback.classList.add('delivery-point__feedback--visible');
        }
    });

    submitButton.addEventListener('focus', e => {
        if (inputPostalCode.value && inputCity.value) {
            e.preventDefault();
            submitButton.setAttribute('type', 'submit');
        }
    });

    submitButton.addEventListener('blur', e => {
        submitButton.setAttribute('type', 'button');
    });
};

export const initForms = () => {
    forms();
};
