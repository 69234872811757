﻿export default class ChangeLoyaltyEmail {
    private UPDATE_LOYALTY_CLUB_EMAIL_ENDPOINT = '/min-side/UpdateLoyaltyClubEmail';

    private form: HTMLElement;
    private submitTrigger: HTMLElement | null;
    private feedbackSuccess: HTMLElement | null;
    private feedbackErrorGeneric: HTMLElement | null;
    private feedbackInUse: HTMLElement | null;
    private emailInput: HTMLInputElement | null;

    constructor(element) {
        this.form = element;
        this.emailInput = this.form.querySelector('[type="email"]');
        this.submitTrigger = this.form.querySelector('input[type="submit"]');
        this.feedbackSuccess = this.form.querySelector('.change-loyalty-email__feedback--success');
        this.feedbackInUse = this.form.querySelector('.change-loyalty-email__feedback--error-in-use');
        this.feedbackErrorGeneric = this.form.querySelector('.change-loyalty-email__feedback--error-generic');

        this.init();
    }

    init = () => {
        this.initSubmit();
    };

    private initSubmit = () => {
        this.form?.addEventListener('submit', async (e) => {
            e.preventDefault();
            this.ChangeLoyaltyEmailBegin();
            const res = await this.ChangeLoyaltyEmail();
            this.ChangeLoyaltyEmailComplete(res);
        });
        return;
    };

    private ChangeLoyaltyEmail = async () => {
        if (!this.emailInput?.value) return false;

        const body = new FormData();
        body.append('email', this.emailInput.value);

        try {
            const res = await fetch(this.UPDATE_LOYALTY_CLUB_EMAIL_ENDPOINT, {
                method: 'POST',
                body: body,
            });
            return res;
        } catch (error) {
            return false;
        }
    };

    private ChangeLoyaltyEmailBegin = () => {
        this.submitTrigger?.setAttribute('disabled', 'true');

        this.feedbackErrorGeneric?.classList.remove(
            'change-loyalty-email__feedback--visible'
        );

        this.feedbackSuccess?.classList.remove(
            'change-loyalty-email__feedback--visible'
        );

        this.feedbackInUse?.classList.remove(
            'change-loyalty-email__feedback--visible'
        );
    };

    private ChangeLoyaltyEmailComplete = (response) => {
        this.submitTrigger?.removeAttribute('disabled');

        if (response.status === 200 && !this.feedbackSuccess?.classList.contains('change-loyalty-email__feedback--visible')) {
            this.feedbackSuccess?.classList.add('change-loyalty-email__feedback--visible');
            return;
        }

        if (response.status === 409 && !this.feedbackInUse?.classList.contains('change-loyalty-email__feedback--visible')) {
            this.feedbackInUse?.classList.add('change-loyalty-email__feedback--visible');
            return;
        }

        if (!this.feedbackErrorGeneric?.classList.contains('reset-password__feedback--visible')) {
            this.feedbackErrorGeneric?.classList.add('change-loyalty-email__feedback--visible');
        }
    };
}
