import { defineStore } from "pinia";
import {
    Cart,
    KeySellingPoint,
    LineItemModel,
} from "../../../utils/API/Cart/cartAPISchema";
import { formatCurrency } from "../../../utils/currencyHelpers";
import { formatWithoutRounding } from "../../../mixins/productPrice.mixin";
import { useCheckoutStateStore } from "../../checkout/stores/checkoutStateStore";
import { CheckoutSteps } from "../../../services/models/checkout.models";
import { useUpsell } from "../../../composables/useUpsell";

export const useShoppingCartStore = defineStore("shoppingCart", {
    state: () => ({
        cart: null as Cart | null | undefined,
        ksps: null as KeySellingPoint[] | null | undefined,
        discounts: [] as string[] | null | undefined,
        lineItemsOutOfStock: [] as LineItemModel[],
        lineItemErrors: {} as Record<string, string> | null | undefined,
        summaryError: "" as string | null | undefined,
        discountError: "" as string | null | undefined,
        upsell: useUpsell(),
    }),
    actions: {
        setCart(cart: Cart | null | undefined) {
            this.cart = cart;
        },
        setKsps(ksps: KeySellingPoint[] | null | undefined) {
            this.ksps = ksps;
        },
        addDiscountCode(discount: string) {
            if (!this.discounts) return;
            this.discounts.push(discount);
            this.discounts = [...this.discounts];
        },
        removeDiscountCode(discount: string) {
            if (!this.discounts) return;
            this.discounts = this.discounts.filter((d) => d !== discount);
        },
        setLineItemsOutOfStock(lineItems: LineItemModel[]) {
            this.lineItemsOutOfStock = lineItems;
        },
        setLineItemError(lineItemEan: string, error: string) {
            this.lineItemErrors = {
                ...this.lineItemErrors,
                [lineItemEan]: error,
            };
        },
        setLineItemErrors(errors: Record<string, string> | null | undefined) {
            this.lineItemErrors = errors;
        },
        setSummaryError(error: string | null | undefined) {
            this.summaryError = error;
        },
        setDiscountError(error: string | null | undefined) {
            this.discountError = error;
        },
    },
    getters: {
        shippingCostFormatted: (state): string => {
            const checkoutState = useCheckoutStateStore();

            if (
                (state.cart?.freeShippingActive &&
                    state.cart?.freeShippingLimit.amount <=
                        state.cart?.total.amount) ||
                !state.cart?.selectedShippingMethodPrice.amount
            )
                return "Fri frakt";
            if (
                state.cart?.selectedShippingMethodPrice.amount &&
                (checkoutState.currentCheckoutStep === CheckoutSteps.Delivery ||
                    checkoutState.currentCheckoutStep === CheckoutSteps.Payment)
            ) {
                return `${formatCurrency(state.cart?.selectedShippingMethodPrice.currency)} ${formatWithoutRounding(state.cart?.selectedShippingMethodPrice.amount)}`;
            }
            return "Beregnes senere";
        },
        totalAmount: (state): number => {
            const checkoutState = useCheckoutStateStore();
            if (
                checkoutState.currentCheckoutStep === CheckoutSteps.Delivery ||
                checkoutState.currentCheckoutStep === CheckoutSteps.Payment
            ) {
                return state.cart?.total.amount || 0;
            }
            return state.cart?.subTotal.amount || 0;
        },
        esalesKeys: (state) => {
            return state.cart?.lineItems.map((li) => li.esalesKey) ?? [];
        },
    },
});
