const afterpayFunctions = {
    toggle: (container, active) => {
        const afterpayElements = document.querySelectorAll('._js-afterpay');
        const afterpayElementsInputs = document.querySelectorAll('._js-afterpay-input');
        const currentAfterpayElement = container.querySelector('._js-afterpay');
        const currentAfterpayElementInputs = container.querySelectorAll('._js-afterpay-input');

        afterpayElements.forEach(afterpayElement => {
            afterpayElement.classList.remove('is-active');
        });

        afterpayElementsInputs.forEach(afterpayElementInput => {
            afterpayElementInput.setAttribute('required', false);
            afterpayElementInput.setAttribute('disabled', true);
        });

        if (active === true) {
            if (currentAfterpayElement)
                currentAfterpayElement.classList.add('is-active');
            if (currentAfterpayElementInputs) {
                currentAfterpayElementInputs.forEach(currentAfterpayElementInput => {
                    currentAfterpayElementInput.setAttribute('required', true);
                    currentAfterpayElementInput.removeAttribute('disabled');
                });
            }
        }
    },

    init: () => {
        //Arvato
        const optionInvoice = document.querySelector('._js-payment-option[value=afterpay_invoice]');
        const optionInstallment = document.querySelector('._js-payment-option[value=afterpay_installment]');

        //Check Arvato on pageload
        if (optionInvoice && optionInvoice.checked) {
            afterpayFunctions.toggle(optionInvoice.closest('.payment-option__item'), true);
        } else if (optionInstallment && optionInstallment.checked) {
            afterpayFunctions.toggle(optionInstallment.closest('.payment-option__item'), true);
        }

        //Check Arrvato on click
        document.querySelectorAll('._js-payment-option')
            .forEach(option => {
                option.addEventListener('change', (e) => {
                    const afterpayStatus = e.target.checked;
                    afterpayFunctions.toggle(e.target.closest('.payment-options'), false);
                    afterpayFunctions.toggle(e.target.closest('.payment-option__item'), afterpayStatus);
                });
            });
    }
};

export const init = () => {
    afterpayFunctions.init();
};
