const loadingClass = 'is-loading';
let url = '';
export const categoryFunctions = {
    loadMoreProducts: formId => {
        const queryParameters = {};
        const queryString = location.search.substring(1);
        const re = /([^&=]+)=([^&]*)/g;
        let m = null;

        while (m = re.exec(queryString)) {
            queryParameters[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
        }

        document.querySelectorAll('input').forEach(input => {
            if (input.type === 'hidden') {
                if (input.value.length > 0)
                    queryParameters[input.name] = input.value;
            }
        });

        const params = Object.keys(queryParameters).map(q => (
            `${encodeURIComponent(q)}=${encodeURIComponent(queryParameters[q])}`
        ));
        url = `${document.location.href.split('?')[0]}?${params}`;

        const form = document.getElementById(formId);
        const button = form.querySelector('._js-load-more-products');

        if (button)
            button.parentNode.remove();
    },

    begin: formId => {
        document.body.classList.add(loadingClass);
        categoryFunctions.loadMoreProducts(formId);
    },


    success: function() {
        document.body.classList.remove(loadingClass);
        window.history.pushState(null, null, url);
    },

    failure: function() {
        document.body.classList.remove(loadingClass);
    },

    complete: function() {
        const impressions = document.querySelector('.impression-tracking');
        if (impressions) {
            document.body.insertAdjacentHTML('afterend',impressions.innerHTML);
            impressions.remove();
        }
    },

};

export const initCategory = () => {
    return {
        init: () => {
            const mq = window.matchMedia('(min-width: 47.9375em)');
            if (mq.matches) {
                document.querySelectorAll('._js-auto-submit').forEach(trigger => {
                    trigger.addEventListener('change', () => {
                        document.querySelector('#productFilter').submit();
                        trigger.classList.add(loadingClass);
                    });
                });
            }
        }
    };
};
