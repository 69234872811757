export const oauthFunctions = {
    doLogin: (loginUrl, idToken) => {
        return $.ajax({
            url: loginUrl,
            data: { idtoken: idToken },
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            cache: false
        });
    },

    doRegistration: (regUrl, idToken, email) => {
        return $.ajax({
            url: regUrl,
            data: { token: idToken, email: email },
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            cache: false
        });
    },

    doCreation: (createUrl, idToken, firstName, lastName, email, locale) => {
        return $.ajax({
            url: createUrl,
            data: { token: idToken, firstName: firstName, lastName: lastName, email: email, locale: locale },
            type: 'POST',
            contentType: 'application/x-www-form-urlencoded',
            cache: false
        });
    }
};
