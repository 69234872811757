<template>
  <div>
    <h2 class="layout-searchpage__subheading">
      {{ $t("hitsIn") }} {{ $t("articles") }}
      <span class="layout-searchpage__hit-count"
        >({{ nArticles }} {{ $t("hits") }})</span
      >
    </h2>
    <ul class="category-grid category-grid--articles" data-layout="4">
      <li v-for="article in articles" :key="article.key">
        <Article :content-item="article" />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Article from "./article.vue";
import {
  CONTENT_LIST_ARTICLES,
  ContentList,
  ContentItem,
} from "../../services/search.service";

export default defineComponent({
  components: {
    Article,
  },
  props: ["contentLists"],
  computed: {
    contentList(): ContentList | undefined {
      return (this.contentLists || []).find(
        (contentList) => contentList.id === CONTENT_LIST_ARTICLES,
      );
    },
    nArticles(): number | undefined {
      if (!this.contentList) {
        return;
      }

      return this.contentList.totalHits;
    },
    articles(): Array<ContentItem> | undefined {
      if (!this.contentList) {
        return;
      }

      return this.contentList.items;
    },
  },
});
</script>
