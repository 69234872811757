let resizedFinished = null;
const teaserblock = () => {
    const mq = window.matchMedia('(min-width: 64em)');
    checkMq();

    function checkMq() {
        if ($('._js-gallery-teaserblock').length) {
            $('._js-gallery-teaserblock').each(function() {
                if (mq.matches) {
                    if (!$(this).hasClass('slick-initialized')) {
                        $(this).slick({
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            arrows: true,
                            infinite: false
                        });
                    }
                } else {
                    if ($(this).hasClass('slick-initialized')) {
                        $(this).slick('unslick');
                    }
                }
            });
        }
    }

    $(window).on('resize', function () {
        clearTimeout(resizedFinished);
        resizedFinished = setTimeout(function () {
            checkMq();
        }, 250);
    });
};

export const init = () => {
    teaserblock();
};
