<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import {
  formatWithoutRounding,
  formatPrice,
} from "../../mixins/productPrice.mixin";
import { LineItemModel, Cart } from "../../utils/API/Cart/cartAPISchema";
import { Money } from "../../services/models/cart.models";
import { formatCurrency } from "../../utils/currencyHelpers";
import autoAnimate from "@formkit/auto-animate";
import ShoppingCartError from "./shoppingCartError.vue";
import PaymentProviders from "./paymentProviders.vue";
import { CheckoutStages } from "../../services/models/checkout.models";
import {
  amountNeededToQualifyForFreeShipping,
  shippingCostFormatted,
  qualifiesForFreeShipping,
} from "../../.././../helpers/checkoutHelpers";
import { useShoppingCartStore } from "./stores/shoppingCartStore";
import * as CheckoutService from "../../services/checkout.service";

const props = defineProps<{
  showCheckoutLink?: boolean;
  error?: string;
}>();

const shoppingCartStore = useShoppingCartStore();
const root = ref();
const totalList = ref();

const error = computed(() => {
  return props.error;
});

/**
 * Computes whether the shopping cart is eligible for free shipping.
 *
 * @returns {boolean} True if the shopping cart is eligible for free shipping, false otherwise.
 */
const freeShipping = computed(() => {
  if (!shoppingCartStore.cart) return false;

  return qualifiesForFreeShipping(
    shoppingCartStore.cart.freeShippingActive,
    shoppingCartStore.cart.freeShippingLimit,
    shoppingCartStore.cart.subTotal,
  );
});

/**
 * Computed property that returns the shipping call to action text.
 *
 * @returns {string} The shipping call to action text.
 */
const shippingCallToActionText = computed(() => {
  const remainingAmount = amountNeededToQualifyForFreeShipping(
    shoppingCartStore.cart?.freeShippingActive,
    shoppingCartStore.cart?.freeShippingLimit,
    shoppingCartStore.cart?.subTotal,
  )?.amount;

  if (!remainingAmount) return "";

  return `Du er ${formatWithoutRounding(remainingAmount)} unna fri frakt`;
});

/**
 * Computed property that returns the shipping cost text.
 * If the cart is not available, a placeholder text is returned.
 *
 * @returns {string} The shipping cost text.
 */
const shippingCostText = computed(() => {
  if (!shoppingCartStore.cart) return "Beregnes senere";

  return shoppingCartStore.shippingCostFormatted;
});

/**
 * Handles the action of navigating to the checkout stage.
 *
 * @function handleGoToCheckout
 * @returns {void}
 */
const handleGoToCheckout = () => {
  CheckoutService.goToCheckoutStage(CheckoutStages.Verification);
};

onMounted(() => {
  autoAnimate(root.value);
  autoAnimate(totalList.value);
});
</script>

<template>
  <div ref="root" class="checkout-summary">
    <h2 class="checkout-summary__heading">{{ $t("summary") }}</h2>
    <ShoppingCartError :error="error" type="global" />
    <div>
      <ul
        v-if="shoppingCartStore.cart?.lineItems"
        class="checkout-summary__group"
      >
        <li
          v-for="lineItem in shoppingCartStore.cart.lineItems"
          :key="lineItem.code"
          class="checkout-summary__entry"
        >
          <div class="checkout-summary__product">
            <span class="checkout-summary__product-name">
              {{ lineItem.name }} ({{ lineItem.quantity }} stk)
            </span>
          </div>
          <div class="checkout-summary__price">
            <span class="checkout-summary__price"
              >{{ formatCurrency(lineItem.listPrice.currency) }}
              {{
                formatWithoutRounding(
                  lineItem.quantity * lineItem.discountedPrice.amount,
                )
              }}
            </span>
          </div>
        </li>
      </ul>
      <div ref="totalList" class="checkout-summary__group">
        <div
          v-if="shoppingCartStore.cart?.discountTotal?.amount"
          class="checkout-summary__entry checkout-summary__entry"
        >
          <div>{{ $t("discountTotal") }}</div>
          <div>
            <span class="checkout-summary__discount"
              >-
              {{
                shoppingCartStore.cart.discountTotal &&
                formatCurrency(shoppingCartStore.cart.discountTotal.currency)
              }}
              {{
                formatWithoutRounding(
                  shoppingCartStore.cart.discountTotal.amount,
                )
              }}</span
            >
          </div>
        </div>
        <div
          v-if="shoppingCartStore.cart?.total?.amount"
          class="checkout-summary__entry checkout-summary__entry"
        >
          <div>{{ $t("shipping") }}</div>
          <div>
            <span>{{ shippingCostText }}</span>
          </div>
        </div>
        <div
          v-if="shoppingCartStore.cart && !freeShipping"
          class="checkout-summary__entry checkout-summary__entry--gray"
        >
          <div class="checkout-summary__shipping-tip">
            {{ shippingCallToActionText }}
          </div>
        </div>
      </div>
      <h4 class="checkout-summary__total">
        <span>{{ $t("total") }}: </span>
        <span
          >{{
            shoppingCartStore.cart?.total &&
            formatCurrency(shoppingCartStore.cart.total.currency)
          }}
          {{
            shoppingCartStore.cart?.total &&
            formatWithoutRounding(shoppingCartStore.totalAmount)
          }}</span
        >
      </h4>
      <div
        v-if="props.showCheckoutLink || shoppingCartStore.cart?.paymentMethods"
        class="checkout-summary__extra"
      >
        <button
          v-if="props.showCheckoutLink"
          class="btn btn--profile btn--smaller"
          @click="handleGoToCheckout"
        >
          <span>{{ $t("toCheckout") }}</span>
        </button>
        <div
          v-if="shoppingCartStore.cart?.paymentMethods"
          class="checkout-summary__extra-info"
        >
          <span>{{ $t("acceptedPaymentMethods") }}</span>
          <PaymentProviders
            :payment-methods="shoppingCartStore.cart?.paymentMethods"
          ></PaymentProviders>
        </div>
      </div>
    </div>
  </div>
</template>
