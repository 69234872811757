import { Product } from "../services/search.service";

/**
 * Format price range
 */
function formatPriceRange(minPrice, maxPrice): string {
    if (minPrice === maxPrice) {
        return formatPrice(maxPrice);
    }

    return `${formatPrice(minPrice)} - ${formatPrice(maxPrice)}`;
}

/**
 * Format price
 */
export function formatPrice(price: number): string {
    const formattedPrice = new Intl.NumberFormat("nb-NB").format(price);

    if (formattedPrice.indexOf(",") !== -1) {
        const splitFormattedPrice = `${formattedPrice}`.split(",")[0];
        return `${splitFormattedPrice},-`;
    }

    return `${formattedPrice},-`;
}

export function formatWithoutRounding(price: number): string {
    price = Math.round(price * 100) / 100;

    const formattedPrice = new Intl.NumberFormat("nb-NB", {
        minimumFractionDigits: 2,
    }).format(price);

    return formattedPrice;
}

export function formatPriceWithDash(price: number): string {
    price = Math.round(price * 100) / 100;

    const formattedPrice = new Intl.NumberFormat("nb-NB", {
        minimumFractionDigits: 2,
    }).format(price);

    const [integerPart, decimalPart] = formattedPrice.split(",");

    if (decimalPart === "00") {
        return `${integerPart},-`;
    }

    return formattedPrice;
}

function hasModifiedPrice(product: Product) {
    return !(
        product.listPrice.min === product.sellingPrice.max &&
        product.listPrice.min === product.sellingPrice.max
    );
}
export function computePrice(product: Product): string {
    return hasModifiedPrice(product)
        ? ""
        : formatPriceRange(product.listPrice.min, product.listPrice.max);
}

export function computeNewPrice(product: Product): string {
    return hasModifiedPrice(product)
        ? formatPriceRange(product.sellingPrice.min, product.sellingPrice.max)
        : "";
}

export function computeOldPrice(product: Product): string {
    return hasModifiedPrice(product)
        ? formatPriceRange(product.listPrice.min, product.listPrice.max)
        : "";
}

export function priceAsNumber(product: Product): number {
    if (hasModifiedPrice(product)) {
        return product.sellingPrice.max;
    }

    return product.listPrice.max;
}

function oldPriceAsNumber(product: Product): number {
    return product.listPrice.max;
}

export function computeRebate(product: Product): string {
    if (!hasModifiedPrice(product)) {
        return "0";
    }

    return (
        Math.floor(
            ((oldPriceAsNumber(product) - priceAsNumber(product)) /
                oldPriceAsNumber(product)) *
                100,
        ) + ""
    );
}
