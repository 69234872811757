import axios from 'axios';

const ajax = axios.create({
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
    },
});

const getProducts = (): number[] => {
    const ids: number[] = [];
    document.querySelectorAll('._js-product-variation').forEach((p) => {
        const variationId = (p as HTMLElement).dataset.variationId;
        if (variationId) {
            ids.push(parseInt(variationId));
        }
    });
    return ids;
};

const markFavorites = (ids: number[]): void => {
    ids.forEach(id => {
        const product = document.querySelector(`[data-variation-id="${id}"]`) as HTMLElement | null;
        product?.classList.add('is-loved');
        const teaser = document.querySelector(`[data-variation-id="${id}"] .teaser-product__lovebutton`) as HTMLElement | null;
        if (teaser) {
            const loveButton = teaser.querySelector('._js-love')  as HTMLElement | null;
            loveButton?.classList.add('is-loved');
        }
    });
};

const filterArrays = (favorites: number[]): void => {
    const productsOnPage = getProducts();
    const hits = productsOnPage.filter((p) => favorites.includes(p));
    markFavorites(hits);
};

const getFavorites = (): void => {
    ajax.get<number[]>('/cart/GetFavorites').then(response => {
        filterArrays(response.data);
    });
};

const toggleFavorite = (element: HTMLElement): void => {
    const productCode = element.dataset.productcode;
    if (productCode) {
        ajax.post('/cart/ToggleFavorite',
            {
                productCode: productCode
            }).then(() => {
            element.classList.contains('is-loved') ? element.classList.remove('is-loved') : element.classList.add('is-loved');
            updateFavoritesCount();
        });
    }
};

const addToFavoritesBegin = (): void => {
    const favoriteCart = document.querySelector('._js-favoritecart') as HTMLElement | null;
    favoriteCart?.classList.add('is-animating');
};

const updateFavoritesCount = (): void => {
    const favoriteCart = document.querySelector('._js-favoritecart') as HTMLElement | null;
    if (!favoriteCart) {
        return;
    }
    ajax.get('/cart/GetFavoritesCount').then(response => {
        addToFavoritesBegin();
        const miniCartAmount = favoriteCart?.querySelector('._js-minicart-amount') as HTMLElement | null;
        if (miniCartAmount) {
            miniCartAmount.innerHTML = response.data
            ? response.data.toString()
            : "";
        }
    });
};

const favorite = (): void => {
    if (getProducts().length > 0) {
        getFavorites();
    }

    const favoriteToggles = document.querySelectorAll('._jsloveContainer, ._js-love');

    if (favoriteToggles && favoriteToggles.length > 0) {
        favoriteToggles.forEach(toggle => {
            toggle.addEventListener('click', () => {
                toggleFavorite(toggle as HTMLElement);
            });
        });
    }
};

export const initFavorites = (): void => {
    favorite();
};

export const updateFavorites = (): void => {
    updateFavoritesCount();
};
