import { defineStore } from "pinia";
import {
    PaymentMethodViewModel,
    ValidatedCartResultDto,
    AfterPayTermsInfo,
    SelectListItem,
    AfterPayInstallmentInfo,
} from "../../../utils/API/Payment/paymentAPISchema";
import { AfterpayMethods } from "../../../../../helpers/checkoutHelpers";

export const usePaymentInformationStore = defineStore("paymentInformation", {
    state: () => ({
        paymentMethods: null as PaymentMethodViewModel[] | null | undefined,
        selectedPaymentMethod: null as
            | PaymentMethodViewModel
            | null
            | undefined,
        selectedAfterPayInstallmentOption: AfterpayMethods.Account as string,
        selectedAfterPayInstallmentPlan: undefined as
            | AfterPayInstallmentInfo
            | undefined,
        personIdentifier: "" as string | undefined,
        thirdpartyPaymentUrl: "" as string | null | undefined,
        errors: {
            personIdentifier: [] as string[],
            selectedAfterPayInstallmentOption: [] as string[],
            selectedAfterPayInstallmentPlan: [] as string[],
            paymentMethod: [] as string[],
            finalizeOrder: [] as string[],
        },
        cartContentChangedPaymentContent: undefined as
            | ValidatedCartResultDto
            | undefined,
        showCartContentChangedPaymentError: false,
    }),
    actions: {
        setPaymentMethods(
            paymentMethods: PaymentMethodViewModel[] | null | undefined,
        ) {
            this.paymentMethods = paymentMethods;
        },
        setSelectedPaymentMethod(
            selectedPaymentMethod: PaymentMethodViewModel | null | undefined,
        ) {
            this.selectedPaymentMethod = selectedPaymentMethod;

        },
        setSelectedAfterPayInstallmentOption(
            selectedAfterPayInstallmentOption: string,
        ) {
            this.selectedAfterPayInstallmentOption =
                selectedAfterPayInstallmentOption;
        },
        setSelectedAfterPayInstallmentPlan(
            afterPayInstallmentPlan: AfterPayInstallmentInfo | undefined,
        ) {
            this.selectedAfterPayInstallmentPlan = afterPayInstallmentPlan;
        },
        setError(field: string | number, error: string[]) {
            this.errors[field] = error;
        },
        setErrors(errors: {
            personIdentifier: string[];
            selectedAfterPayInstallmentOption: string[];
            selectedAfterPayInstallmentPlan: string[];
            paymentMethod: string[];
            finalizeOrder: string[];
        }) {
            this.errors = errors;
        },
        SetShowCartContentChangedPaymentError(
            showCartContentChangedPaymentError: boolean,
        ) {
            this.showCartContentChangedPaymentError =
                showCartContentChangedPaymentError;
        },
        clearAllErrors() {
            this.errors = {
                personIdentifier: [],
                selectedAfterPayInstallmentOption: [],
                selectedAfterPayInstallmentPlan: [],
                paymentMethod: [],
                finalizeOrder: [],
            };
        },
        setCartContentChangedPaymentContent(
            value: ValidatedCartResultDto | undefined,
        ) {
            this.cartContentChangedPaymentContent = value;
        },
        setThirdpartyPaymentUrl(url: string | null | undefined) {
            this.thirdpartyPaymentUrl = url;
        },
        setPersonIdentifier(personIdentifier: string | undefined) {
            this.personIdentifier = personIdentifier;
        },
        setSelectedAfterpayInstallmentPlanByValue(value: string) {
            this.selectedAfterPayInstallmentPlan =
                this.selectedPaymentMethod?.afterPayViewModel?.installmentInfo?.installmentInfoItems?.[
                    value
                ];
        },
    },
    getters: {
        selectedAfterPayInstallmentPlanId: (state) => {
            if (
                !state.selectedPaymentMethod?.afterPayViewModel?.installmentInfo
                    ?.installmentInfoItems
            )
                return;

            const selectedKey = getKeyByValue(
                state.selectedPaymentMethod?.afterPayViewModel?.installmentInfo
                    ?.installmentInfoItems,
                state.selectedAfterPayInstallmentPlan,
            );
            if (!selectedKey) return;

            return state.selectedPaymentMethod?.afterPayViewModel?.installmentInfo?.installmentListItems?.find(
                (x) => x.value === selectedKey,
            )?.value;
        },
    },
});

function getKeyByValue(
    record: Record<any, any>,
    value: any,
): string | undefined {
    if (!record) return;

    for (const [key, val] of Object.entries(record)) {
        if (val === value) {
            return key;
        }
    }
    return undefined;
}
