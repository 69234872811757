import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Products from "./products.vue";
import Articles from "./articles.vue";
import Stores from "./stores.vue";

export enum SearchPageRoutes {
    Products = "products",
    Articles = "articles",
    Stores = "stores",
}

const routes: Array<RouteRecordRaw> = [
    {
        name: SearchPageRoutes.Products,
        path: "/produkter",
        component: Products,
    },
    {
        name: SearchPageRoutes.Articles,
        path: "/artikler",
        component: Articles,
    },
    {
        name: SearchPageRoutes.Stores,
        path: "/butikker",
        component: Stores,
    },
];

export default function (base?: string) {
    if (!base) return;

    return createRouter({
        history: createWebHistory(base),
        routes,
    });
}