<template>
  <div class="quicksearch-wrapper" @keydown.esc="$emit('keydownEsc', $event)">
    <!-- Search form -->
    <form role="search" @submit="clickSearch($event)">
      <div class="l__content">
        <div :class="`quicksearch ${className}`">
          <label
            id="sok"
            for="quickSearchInput"
            class="sr-only quicksearch__label"
          >
            {{ $t("searchLabel") }}
          </label>
          <!-- Input: Search -->
          <input
            id="quickSearchInput"
            ref="input"
            type="search"
            class="quicksearch__input"
            autocomplete="off"
            :value="inputQuery"
            :placeholder="placeholder"
            :disabled="disabled"
            @input="change($event)"
            @focus="$emit('inputFocus', $event)"
          />
          <!-- Button: Submit -->
          <button
            type="submit"
            :aria-label="$tc('searchButton')"
            :title="$tc('searchButton')"
            class="btn btn--icon quicksearch__label"
            :disabled="disabled"
            @click="clickSearch($event)"
          >
            <Icon :type="IconTypes.Search" :size="IconSizes.Small" />
          </button>
        </div>
      </div>
    </form>
    <!-- Result panel -->
    <div
      v-if="autocompleteResult && hasAnyHits"
      class="quicksearch-panel-wrapper"
      :class="{
        'quicksearch-panel-wrapper--absolute': absolutePanel,
        'quicksearch-panel-wrapper--hidden': isResultsHidden,
      }"
    >
      <div class="quicksearch-panel">
        <!-- Suggestions -->
        <div
          v-if="autocompleteResult.phraseSuggestions.length > 0"
          class="quicksearch-panel__section"
        >
          <h3 class="sr-only">
            {{ $t("suggestions") }}
          </h3>
          <ul>
            <li
              v-for="(
                suggestion, index
              ) in autocompleteResult.phraseSuggestions"
              :key="`${index}-${suggestion.q}`"
            >
              <Suggestion
                :search-page-url="searchPageUrl"
                :suggestion="suggestion"
                @click="clickSuggestion"
              />
            </li>
          </ul>
        </div>
        <!-- Products -->
        <div
          v-if="autocompleteResult.productSuggestions.length > 0"
          class="quicksearch-panel__section quicksearch-panel__section--products"
        >
          <h3 class="quicksearch-panel__heading u-trailer-half">
            {{ $t("recommendedProducts") }}
          </h3>
          <ul>
            <template
              v-for="productSuggestion in autocompleteResult.productSuggestions"
            >
              <template
                v-if="
                  productSuggestion.products && productSuggestion.products[0]
                "
              >
                <li
                  :key="`${productSuggestion.key}-${productSuggestion.products[0].key}`"
                >
                  <Product :product="productSuggestion.products[0]" />
                </li>
              </template>
            </template>
          </ul>
        </div>
        <!-- Articles -->
        <div
          v-if="articles && articles.length > 0"
          class="quicksearch-panel__section quicksearch-panel__section--links"
        >
          <h3 class="quicksearch-panel__heading">
            {{ $t("articles") }}
          </h3>
          <ul>
            <li v-for="article in articles" :key="article.key">
              <a
                :href="article.link"
                @click="notifyClick($event, article)"
                @click.middle="notifyClick($event, article)"
                @click.right="notifyClick($event, article)"
              >
                {{ article.title }}
              </a>
            </li>
          </ul>
        </div>
        <!-- Stores -->
        <div
          v-if="stores && stores.length > 0"
          class="quicksearch-panel__section quicksearch-panel__section--links"
        >
          <h3 class="quicksearch-panel__heading">
            {{ $t("stores") }}
          </h3>
          <ul>
            <li v-for="store in stores" :key="store.key">
              <a
                :href="store.link"
                @click="notifyClick($event, store)"
                @click.middle="notifyClick($event, store)"
                @click.right="notifyClick($event, store)"
              >
                {{ store.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
      <!-- Total hits -->
      <div
        v-if="autocompleteResult && autocompleteResult.totalHits > 0"
        class="quicksearch-panel-button"
      >
        <router-link
          v-if="hasRouter"
          :to="totalHitsUrl"
          type="button"
          class="btn btn--profile btn--small"
          @click.native="clickTotalHits()"
        >
          {{ $t("showAll") }} ({{ autocompleteResult.totalHits }})
        </router-link>
        <a
          v-else
          :href="totalHitsUrl"
          type="button"
          class="btn btn--profile btn--small"
          @click="clickTotalHits()"
        >
          {{ $t("showAll") }} ({{ autocompleteResult.totalHits }})
        </a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import axios, { CancelTokenSource } from "axios";
import { notifyClick } from "../../mixins/notifyClick";
import { getTotalHitsUrl } from "./quickSearch.utils";
import Icon from "../../components/icon/icon.vue";
import Suggestion from "./suggestion.vue";
import Product from "./product.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import SearchService, {
  AutocompleteResult,
  CONTENT_LIST_ARTICLES,
  CONTENT_LIST_STORES,
  ContentList,
  ContentItem,
} from "../../services/search.service";

const CANCEL_AUTOCOMPLETE_REQUEST = "CancelAutoCompleteRequest";

const props = defineProps<{
  className?: string;
  absolutePanel?: boolean;
  searchPageUrl: string;
  placeholder?: string;
  resetOnSearch?: boolean;
  disabled?: boolean;
  isResultsHidden?: boolean;
}>();

const inputQuery = ref("");
const input = ref(null as HTMLInputElement | null);
const autocompleteResult = ref(null as AutocompleteResult | null);
const cancelTokenSource = ref(null as CancelTokenSource | null);

const emit = defineEmits<{
  (e: "input", inputQuery: string): void;
  (e: "clickSearch", inputQuery: string): void;
  (e: "clickSuggestion"): void;
}>();

defineExpose({
  input,
});

watch(inputQuery, (input) => {
  emit("input", input);
});
let route;
let router;
try {
  route = useRoute();
  router = useRouter();
} catch (error) {
  route = null;
  router = null;
}

const hasRouter = computed((): boolean => {
  return !!router;
});
const totalHitsUrl = computed((): string => {
  return getTotalHitsUrl(
    route ? route.query.PREVIEW : undefined,
    route ? "/" : props.searchPageUrl,
    inputQuery.value,
  );
});
const articles = computed((): Array<ContentItem> | undefined => {
  return getContentItems(autocompleteResult.value, CONTENT_LIST_ARTICLES);
});
const stores = computed((): Array<ContentItem> | undefined => {
  return getContentItems(autocompleteResult.value, CONTENT_LIST_STORES);
});
const hasAnyHits = computed((): boolean => {
  return (
    !!autocompleteResult.value &&
    (autocompleteResult.value.phraseSuggestions.length > 0 ||
      autocompleteResult.value.productSuggestions.length > 0 ||
      (!!articles.value && articles.value.length > 0) ||
      (!!stores.value && stores.value.length > 0))
  );
});

onMounted(() => {
  autocomplete();
});

/**
 * Click search
 */
function clickSearch(e: Event) {
  e.preventDefault();

  if (router) {
    router.push(addLeadingSlashIfNeeded(totalHitsUrl.value));
  } else {
    window.location.href = addLeadingSlashIfNeeded(totalHitsUrl.value);
  }

  emit("clickSearch", inputQuery.value);

  if (props.resetOnSearch === true) {
    inputQuery.value = "";
    autocompleteResult.value = null;

    cancelRequest();
  }
  (input.value as HTMLInputElement).blur();
}
/**
 * Click total hits
 */
function clickTotalHits() {
  emit("clickSearch", inputQuery.value);

  if (props.resetOnSearch === true) {
    inputQuery.value = "";
    autocompleteResult.value = null;

    cancelRequest();
  }

  (input.value as HTMLInputElement).blur();
}
/**
 * Change
 */
function change(event: Event) {
  inputQuery.value = (event.target as HTMLInputElement).value;

  autocomplete();
}
/**
 * Cancel request
 */
function cancelRequest() {
  if (cancelTokenSource.value) {
    cancelTokenSource.value.cancel(CANCEL_AUTOCOMPLETE_REQUEST); // Cancel previous request

    cancelTokenSource.value = null;
  }
}
/**
 * Autocomplete
 */
function autocomplete() {
  cancelRequest();

  if (!inputQuery.value) {
    return (autocompleteResult.value = null);
  }

  const localCancelTokenSource = (cancelTokenSource.value =
    axios.CancelToken.source());

  const searchPromise = SearchService.autocomplete(
    localCancelTokenSource,
    inputQuery.value,
  );

  searchPromise
    .then((result) => {
      autocompleteResult.value = result.data;
    })
    .catch((error) => {
      autocompleteResult.value = null;

      if (error.message !== CANCEL_AUTOCOMPLETE_REQUEST) {
        console.error(error);
      }
    });
}
/**
 * Click suggestion
 */
function clickSuggestion() {
  if (props.resetOnSearch === true) {
    inputQuery.value = "";
    autocompleteResult.value = null;

    cancelRequest();
  }

  emit("clickSuggestion");
}

function getContentItems(
  searchResult: AutocompleteResult | null,
  contentListId: string,
): Array<ContentItem> | undefined {
  if (!searchResult) {
    return;
  }

  const contentList = searchResult.contentLists.find(
    (contentList: ContentList) => {
      return contentList.id === contentListId;
    },
  );

  if (!contentList || !contentList.items) {
    return;
  }

  return contentList.items;
}

function addLeadingSlashIfNeeded(url: string): string {
  if (url[0] && url[0] !== "/") {
    return "/" + url;
  }

  return url;
}
</script>
