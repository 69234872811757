const trackPageView = (pathRoot, virtualPath) => {
    const trackData = {
        'event': 'pageVirtual', // GTM Event Trigger
        'pageVirtual': pathRoot + virtualPath // Virtuell URL
    };
    if (window.dataLayer) {
        window.dataLayer.push(trackData);
    }
};

const trackEvent = (pathRoot, virtualPath, action, label) => {
    const trackData = {
        'event': 'jeansguide', // GTM Event Trigger
        'eventCategory': 'Jeans Guide',
        'eventAction': action,
        'eventLabel': label,
        'pageVirtual': pathRoot + virtualPath
    };
    dataLayer.push(trackData);
};

const labelSelector = el => {
    const label = document.querySelector('input[name=' + el + ']:checked');
    if (label) {
        return label.dataset.label;
    }
};

const trackSlideChange = (el, resultObject) => {
    let pathRoot = window.location.pathname;
    if (!pathRoot.endsWith('/')) {
        pathRoot += '/';
    }

    if (el === 2) {
        trackPageView(pathRoot, 'storrelse');
    } else if (el === 3) {
        trackEvent(
            pathRoot,
            'storrelse',
            'Størrelse',
            `Livvidde; ${labelSelector('step2a')}|Lengde: ${labelSelector('step2b')}`
        );
        trackPageView(pathRoot, 'passform-lar');
    } else if (el === 4) {
        trackEvent(
            pathRoot,
            'passform-lar',
            'Passform lår',
            `Mine lår er: ${labelSelector('step3a')}|Jeg liker jeansen ved låret: ${labelSelector('step3b')}`
        );
        trackPageView(pathRoot, 'passform-legger');
    } else if (el === 5) {
        trackEvent(
            pathRoot,
            'passform-legger',
            'Passform legger',
            `Mine legger er: ${labelSelector('step4a')}|Jeg liker jeansen ved leggen: ${labelSelector('step4b')}`
        );
        trackPageView(pathRoot, 'fullfort');
        trackEvent(
            pathRoot,
            'fullfort',
            'Fullført',
            `${resultObject.name}:${resultObject.waist}|${resultObject.top.fit}|${resultObject.bottom.fit}`
        );
    } else if (el === 1337) {
        trackEvent(
            pathRoot,
            'fullfort',
            'Fullført',
            `${resultObject.name}:${resultObject.waist}|${resultObject.top.fit}|${resultObject.bottom.fit}`
        );
    }
};

const changeSlide = step => {
    const results = document.querySelector('._js-jeansguide-results');
    if (step == 1 || step == 2 && results) {
        results.classList.remove('is-filtered');
    }
    document.querySelectorAll('._js-jeansguide-step').forEach(s => {
        if (s.dataset.step != step) {
            s.classList.remove('is-active');
            s.setAttribute('aria-expanded', 'false');
        } else {
            s.classList.add('is-active');
            s.setAttribute('aria-expanded', 'true');
        }
    });

    trackSlideChange(step);
};

const selector = el => document.querySelector(`input[name="${el}"]:checked`).value;

// Filter list v 2.0
// Used to find correct row or col number in size table
const findColumnOrRow = me => {
    if (me === 'thin') {
        return {
            'columns': [0, 1, 2]
        };
    }
    if (me === 'medium') {
        return {
            'columns': [3, 4, 5]
        };
    }
    if (me === 'wide') {
        return {
            'columns': [6, 7, 8]
        };
    }
};

// Log result in top
const logResult = currentArray => {
    document.querySelectorAll('.jeansguide__final-result__item').forEach(el => {
        el.remove();
    });

    for (var i = 0; i < currentArray.length; i++) {
        const obj = currentArray[i];
        const result = '<div class=\'jeansguide__final-result__item\'><img src=\'' + obj.img + '\' /><h3 class=\'h3\'>' + obj.name + '</h3><p class=\'u-text-center\'>' + obj.waist + '<br>' + obj.top.fit + '<br>' + obj.bottom.fit + '</p></div>';
        document.querySelector('._js-jeansguide-final-result').insertAdjacentHTML('beforeend', result);
        trackSlideChange(1337, obj);
    }
};

const displayElement  = currentArray => {
    for (var i = 0; i < currentArray.length; i++) {
        const obj = currentArray[i];
        for (var e = 0; e < obj.sku.length; e++) {
            document.querySelectorAll(`[data-productid="${obj.sku[e]}"]`).forEach(el => {
                el.classList.add('foundyou');
            });
        }
    }
};

const filterJeansList2D = step => {
    if (window.jeansjson) {
        // Clear results
        document.querySelectorAll('[data-productid]').forEach(p => {
            p.classList.remove('foundyou');
        });

        // Filter only on step 2
        if (step > 2) {
            document.querySelectorAll('._js-jeansguide-results').forEach(el => {
                el.classList.add('is-filtered');
            });
        }

        // Input from user
        const tMe = selector('step3a');
        const tJeans = selector('step3b');
        const bMe = selector('step4a');
        const bJeans = selector('step4b');

        // Find my row-/colspan
        const rowArray = findColumnOrRow(bMe);
        const colArray = findColumnOrRow(tMe);

        // Find correct row/col
        const rowNumber = rowArray.columns[bJeans];
        const colNumber = colArray.columns[tJeans];

        // Look up in correct "tablecell"
        const hit = window.jeansjson[0].table[rowNumber][colNumber];

        // All pants in array
        const pantsArray = window.jeansjson[0].pants;

        // Filter ids from pant array
        const filteredPants = pantsArray.filter(item => {
            if (item.id == hit[0] || item.id == hit[1]) {
                return item;
            }
        });

        // Helper
        console.log('Rad:', rowNumber, 'Kolonne:', colNumber, 'Resultat:', hit[0], hit[1]);

        // Do stuff with divs
        logResult(filteredPants);
        displayElement(filteredPants);
    }
};

const jeansguide = () => {
    document.querySelectorAll('.result .teaser-product').forEach(t => {
        const prodId = t.dataset.productid;
        const result = t.closest('.result');
        if (result && prodId) {
            result.dataset.productid = prodId;
        }
    });

    document.querySelectorAll('._js-jeansguide-button-start').forEach(b => {
        b.addEventListener('click', e => {
            changeSlide(e.target.dataset.stepAction);
        });
    });

    document.querySelectorAll('._js-jeansguide-button-prev').forEach(el => {
        el.addEventListener('click', e => {
            document.querySelectorAll('.jeansguide__final-result__item').forEach(e => {
                e.remove();
            });
            changeSlide(e.target.dataset.stepAction);
        });
    });

    document.querySelectorAll('._js-jeansguide-button').forEach(b => {
        b.addEventListener('click', e => {
            const target = e.target;
            const targetSlide = target.dataset.stepAction;
            const stepContent = target.closest('.jeansguide__step__content');

            if (stepContent) {
                const fieldsetInvalid = stepContent.querySelectorAll('input:invalid');
                const fieldsetValid = stepContent.querySelectorAll('input');

                if (fieldsetInvalid.length == 0) {
                    fieldsetValid.forEach(el => {
                        el.classList.remove('invalid');
                    });

                    changeSlide(targetSlide);

                    if (targetSlide == 5) {
                        filterJeansList2D(targetSlide);
                    }
                } else {
                    fieldsetInvalid.forEach(el => {
                        el.classList.add('invalid');
                    });
                }
            }
        });
    });
};

export const init = () => {
    jeansguide();
};
