/**
 * User feedback messages for checkout API errors.
 */
export const USER_FEEDBACK = {
    CART_FULL: "Handlekurven er full.",
    GENERIC: "Noe gikk galt, kunne ikke utføre handlingen.",
    COULD_NOT_ADD_DISCOUNT_CODE:
        "Handlekurven din møter ikke kriteriene for å få rabatt, eller rabattkoden er ikke skrevet på korrekt måte.",
    INSUFFICIENT_STOCK: "Du har nådd det maksimale antallet vi har på lager",
    LOGIN_FAILED: "Feil brukernavn eller passord",
    RESET_PASSWORD_FAILED:
        "Kunne ikke tilbakestille passord, prøv igjen senere",
    RESET_PASSWORD_SUCCESS:
        "Vi har sendt deg en e-post med lenke for å tilbakestille ditt passord",
    UNKNOWN_POSTALCODE: "Ukjent postnummer",
} as const;

/**
 * Error messages for the checkout API.
 */
export const API_ERROR_MESSAGES = {
    GENERIC: "Noe gikk galt, kunne ikke utføre handlingen.",
    CART_FULL: "Handlekurven er full",
    OUT_OF_STOCK: "Beklager! Varen er ikke lenger tilgjengelig",
    COULD_NOT_GET_CITY: "Kunne ikke hente poststed",
} as const;

/**
 * Error names for the checkout API.
 */
export const API_ERROR_NAMES = {
    ABORT_ERROR: "AbortError",
} as const;
