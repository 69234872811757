/**
 * Get suggestion url
 */
export function getSuggestionUrl(
    preview: string | (string | null)[] | undefined,
    searchPageUrl: string,
    q: string,
): string {
    return `${searchPageUrl}produkter?q=${encodeURIComponent(q)}${preview ? `&PREVIEW=${preview}` : ""}`;
}

/**
 * Get total hits URL
 */
export function getTotalHitsUrl(
    preview: string | (string | null)[] | undefined,
    searchPageUrl: string,
    q: string,
): string {
    return `${searchPageUrl}produkter?q=${encodeURIComponent(q)}${preview ? `&PREVIEW=${preview}` : ""}`;
}
