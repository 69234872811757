import { USER_FEEDBACK } from "../APIErrors";
import {
    CartSchema,
    Product,
    Cart,
    AddressModelWithoutAnalytics,
    GetCartResponseModel,
    GetCartResponseModelSchema,
    UpdateProductQuantityResponseModel,
    RemoveProductFromCartResponseModelSchema,
    RemoveProductFromCartResponseModel,
} from "./cartAPISchema";
import { LogErrorResponse } from "../Logging/LoggingAPI";

const API_URL = "/api/cart";

export async function addToCart(
    productId: number,
    count: number,
): Promise<Product> {
    throw new Error("Not implemented");
}

/**
 * Retrieves the shopping cart from the API.
 *
 * @returns A Promise that resolves to the shopping cart data.
 * @throws An error if the HTTP request fails or the response is not valid.
 */
export async function getCart(): Promise<GetCartResponseModel> {
    const url = `${API_URL}`;
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        Promise.reject(await response.json());
    }

    
    const data = await response.json();
    const validatedData = GetCartResponseModelSchema.parse(data);
    return validatedData;
}

/**
 * Updates the quantity of a product in the shopping cart.
 *
 * @param code - The code of the product.
 * @param quantity - The new quantity of the product.
 * @param signal - An AbortSignal object that allows cancelling the request.
 * @returns A Promise that resolves to the updated shopping cart.
 * @throws If the request fails or is aborted.
 */
export async function updateProductQuantity(
    code: string,
    quantity: number,
    isAdditive: boolean,
    signal: AbortSignal,
): Promise<UpdateProductQuantityResponseModel> {
    const url = `${API_URL}/${code}`;

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            quantity: quantity,
            isAdditive: isAdditive,
        }),
        signal: signal,
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return Promise.reject(await response.json());
    }

    const data = await response.json();
    const validatedData = RemoveProductFromCartResponseModelSchema.parse(data);

    return validatedData;
}

/**
 * Removes a product from the shopping cart.
 *
 * @param code - The code/productId of the product to be deleted.
 * @returns A Promise that resolves to the updated shopping cart.
 * @throws An error if the HTTP request fails or the response is not valid.
 */
export async function removeProductFromCart(code: string): Promise<RemoveProductFromCartResponseModel> {
    const url = `${API_URL}/${code}`;
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return Promise.reject(USER_FEEDBACK.GENERIC);
    }

    const data = await response.json();
    const validatedData = RemoveProductFromCartResponseModelSchema.parse(data);

    return validatedData;
}

/**
 * Adds a discount code to the shopping cart.
 *
 * @param discountCode The discount code to be added.
 * @returns A Promise that resolves to the updated shopping cart.
 */
export async function addDiscountCode(discountCode: string): Promise<Cart> {
    const url = `${API_URL}/coupon`;
    const body = JSON.stringify({
        couponCode: discountCode,
    });

    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: body,
    });

    if (!response.ok) {
        await LogErrorResponse(url, response, body);
        return Promise.reject(USER_FEEDBACK.COULD_NOT_ADD_DISCOUNT_CODE);
    }

    const data = await response.json();
    const validatedData = CartSchema.parse(data);
    return validatedData;
}

/**
 * Removes a discount code from the shopping cart.
 *
 * @param discountCode - The discount code to be removed.
 * @returns A Promise that resolves to the updated shopping cart.
 * @throws An error if there is an HTTP error during the API call.
 */
export async function removeDiscountCode(discountCode: string): Promise<Cart> {
    const url = `${API_URL}/coupon`;
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            couponCode: discountCode,
        }),
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return Promise.reject(USER_FEEDBACK.GENERIC);
    }

    const data = await response.json();
    const validatedData = CartSchema.parse(data);
    return validatedData;
}

/**
 * Updates the customer's address information.
 *
 * @param addressModel - The address model to be updated. It can be a single AddressModel object or an array of AddressModel objects.
 * @returns A Promise that resolves to a boolean indicating whether the update was successful.
 */
export async function updateCustomer(
    addressModel: AddressModelWithoutAnalytics | Array<AddressModelWithoutAnalytics>,
): Promise<boolean> {
    const url = `${API_URL}/address`;

    if (!Array.isArray(addressModel)) {
        addressModel = [addressModel];
    }

    const response = await fetch(url, {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(addressModel),
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        return Promise.reject(false);
    }
    return Promise.resolve(true);
}