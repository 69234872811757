<template>
  <div class="teaser teaser--simple teaser--fixed-ratio teaser--small">
    <article>
      <div class="teaser__photo">
        <a
          :href="article.link"
          class="teaser__link"
          tabindex="-1"
          aria-hidden="true"
          @click="notifyClick($event, article)"
          @click.middle="notifyClick($event, article)"
          @click.right="notifyClick($event, article)"
        >
          <ResponsiveImage
            v-if="contentImage"
            width="856"
            height="642"
            :alt-text="contentImage.alt"
            :src="fallbackImage"
            :sources="imageSources"
          />
        </a>
      </div>
      <div class="teaser__content">
        <h3 class="teaser__heading">
          <a
            :href="article.link"
            @click="notifyClick($event, article)"
            @click.middle="notifyClick($event, article)"
            @click.right="notifyClick($event, article)"
          >
            {{ article.title }}
          </a>
        </h3>
      </div>
    </article>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import NotifyClick from "../../mixins/notifyClick";
import ResponsiveImage from "../../components/responsiveImage/responsiveImage.vue";
import {
  ContentItem,
  ContentImage,
  ContentImageSource,
} from "../../services/search.service";

export default defineComponent({
  components: {
    ResponsiveImage,
  },
  mixins: [NotifyClick],
  props: ["contentItem"],
  computed: {
    article(): ContentItem {
      return this.contentItem;
    },
    text(): string {
      return (
        this.article.custom &&
        this.article.custom.MainIntro &&
        this.article.custom.MainIntro[0] &&
        this.article.custom.MainIntro[0].label
      );
    },
    contentImage(): ContentImage | undefined {
      if (this.article.image) {
        return this.article.image;
      }
    },
    fallbackImage(): string {
      if (!this.contentImage) {
        return "";
      }

      return getFallbackImage(this.contentImage);
    },
    imageSources():
      | Array<{
          srcset: string;
          media: string;
          type: string;
        }>
      | undefined {
      if (!this.contentImage) {
        return;
      }

      return getImageSources(this.contentImage);
    },
  },
});

function getFallbackImage(contentImage: ContentImage): string {
  return contentImage.sources[contentImage.sources.length - 1].url;
}

function getImageSources(contentImage: ContentImage): Array<{
  srcset: string;
  media: string;
  type: string;
}> {
  const sources = contentImage.sources.slice(0, -1);

  return sources.map((source: ContentImageSource, i: number) => {
    const isLast = i === sources.length - 1;

    return {
      srcset: source.url,
      media: isLast ? "" : `(max-width: ${source.width * 2}px)`,
      type: "image/webp",
    };
  });
}
</script>
