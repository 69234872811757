<template>
  <div class="responsiveImage">
    <picture v-if="sources">
      <source
        v-for="(source, index) in sources"
        :key="index"
        :media="source.media"
        :srcset="source.srcset"
        :type="source.type"
      />
      <img
        :width="width"
        :height="height"
        :loading="loading"
        :src="lazyLoadFallback(width, height)"
        :data-src="src"
        :class="className"
        :alt="altText"
      />
    </picture>
    <img
      v-else
      :width="width"
      :height="height"
      :loading="loading"
      :src="lazyLoadFallback(width, height)"
      :data-src="src"
      :class="className"
      :alt="altText"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";


export default defineComponent({
  props: {
    src: {
      type: String,
      default: "",
    },
    sources: {
      type: Array,
      default: null,
    },
    width: {
      type: String,
      default: "",
    },
    height: {
      type: String,
      default: "",
    },
    altText: {
      type: String,
      default: "",
    },
    imgClass: {
      type: String,
      default: "",
    },
    lazy: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    loading(): string | undefined {
      if (this.lazy) {
        return "lazy";
      }
      return "eager";
    },
    className(): string {
      return `${this.imgClass} ${this.lazy ? "lazyload" : ""}`;
    },
  },
  methods: {
    lazyLoadFallback(width, height) {
      if (!this.lazy) {
        return this.src;
      }

      return `data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%20${width}%20${height}%20'%2F%3E`;
    },
  },
});
</script>
