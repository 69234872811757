<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import autoAnimate from "@formkit/auto-animate";
import Icon from "../icon/icon.vue";
import { HeadingLevel } from "./accordionSchema";

const accordion = ref();
const icon = ref();
const expanded = ref(false);

const props = defineProps<{
  heading: string;
  headingSecondPart?: string;
  headingLevel?: HeadingLevel;
  headingClass?: string;
  hideIcon?: boolean;
  extraClass?: string;
}>();

const headingLevelWithDefault = computed(() => props.headingLevel ?? "h2");

onMounted(() => {
  autoAnimate(accordion.value);
});

const expandedAttribute = computed(() => ({
  expanded: expanded.value,
  collapsed: !expanded.value,
}));
</script>

<template>
  <div
    ref="accordion"
    class="accordion-new"
    :class="[expandedAttribute, props.extraClass]"
  >
    <component :is="headingLevelWithDefault" :class="props.headingClass">
      <button
        :aria-expanded="expanded ? 'true' : 'false'"
        class="accordion-new__activator"
        @click="expanded = !expanded"
      >
        <div class="accordion-new__heading-container">
          <span>{{ props.heading }}</span>
          <span v-if="props.headingSecondPart">{{
            props.headingSecondPart
          }}</span>
        </div>
        <Icon
          v-if="!props.hideIcon"
          ref="icon"
          class="accordion-new__icon"
          size="accordion"
          type="chevron"
        ></Icon>
      </button>
    </component>
    <div v-if="expanded" class="accordion-new__content">
      <slot></slot>
    </div>
  </div>
</template>
