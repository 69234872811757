import { defineStore } from "pinia";
import { AxiosResponse } from "axios";
import FavoriteService from "../services/favorites.service";
import { updateFavorites } from "../../modules/favorite_wp";

export const useFavoritesStore = defineStore("favorites", {
    state: () => ({
        favorites: null as Array<string> | null,
        isLoadingFavorites: false,
    }),
    actions: {
        /**
         * Get favorites
         */
        async getFavorites(): Promise<void> {
            this.isLoadingFavorites = true;

            try {
                const response: AxiosResponse<Array<number | string>> =
                    await FavoriteService.getFavorites();
                this.favorites = response.data.map((i) => String(i));
                updateFavorites();
            } catch (error) {
                // Fail silently...
                this.favorites = null;
            }

            this.isLoadingFavorites = false;
        },
        /**
         * Toggle favorite
         */
        async toggleFavorite(productKey: string): Promise<void> {
            this.isLoadingFavorites = true;

            try {
                await FavoriteService.toggleFavorite(productKey);
                await this.getFavorites();
            } catch (error) {
                await this.getFavorites();
            }

            this.isLoadingFavorites = false;
        },
    },
    getters: {
        isFavorite: (state) => (epiProductCode: string) => {
            return state.favorites && state.favorites?.indexOf(epiProductCode) !== -1;
        },
    },
});

/**
 * Get favorites for non-Vue usage
 */
export async function getFavorites() {
    try {
        const response: AxiosResponse<Array<number | string>> =
            await FavoriteService.getFavorites();
        const favoriteCartAmount = document.querySelector(
            "._js-minicart-amount",
        );
        if (favoriteCartAmount) {
            favoriteCartAmount.innerHTML = response.data.length
                ? response.data.length.toString()
                : "";
        }
        return response;
    } catch (error) {
        // Fail silently...
        return null;
    }
}
