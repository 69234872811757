import { createApp } from "vue";
import QuickSearch from "./apps/views/quickSearch/quickSearch.vue";
import SearchPage from "./apps/views/searchPage/searchPage.vue";
import searchPageRouter from "./apps/views/searchPage/searchPage.router";
import CategoryPage from "./apps/views/categoryPage/categoryPage.vue";
import categoryPageRouter from "./apps/views/categoryPage/categoryPage.router";
import ProductsModule from "./apps/views/productsModule/productsModule.vue";
import CartStatus from "./apps/views/cartStatus/cartStatus.vue";
import MiniCart from "./apps/views/miniCart/miniCart.vue";
import CheckoutPage from "./apps/views/checkoutPage/checkoutPage.vue";
import Root from "./apps/root.vue";
import LoyaltyDiscount from "./apps/components/loyaltyDiscount/loyaltyDiscount.vue";
import ImageZoom from "./apps/views/imageZoom/imageZoom.vue";
import { createI18n } from "vue-i18n"; // Assuming init18n is a wrapper around vue-i18n
import { createPinia } from "pinia";
import addToCartForm from "./apps/components/addToCartForm/addToCartForm.vue";

const app = createApp(Root);

export default {
    init() {
        const quickSearchEl = document.querySelector(
            "#app-quick-search",
        ) as HTMLElement;
        const searchPageEl = document.querySelector(
            "#app-search-page",
        ) as HTMLElement;
        const categoryPageEl = document.querySelector(
            "#app-category-page",
        ) as HTMLElement;
        const productsModuleEls = document.querySelectorAll(
            ".app-products-module",
        ) as NodeListOf<HTMLElement>;
        const cartStatusEl = document.querySelector(
            "#app-cart-status",
        ) as HTMLElement;
        const miniCartEl = document.querySelector(
            "#app-minicart",
        ) as HTMLElement;
        const loyaltyDiscountBlockEls = document.querySelectorAll(
            ".discount-block",
        ) as NodeListOf<HTMLElement>;
        const CheckoutPageEl = document.querySelector(
            "#checkout-page",
        ) as HTMLElement;
        const imageZoomEl = document.querySelector(
            "#image-zoom",
        ) as HTMLElement;
        const addToCartFormEl = document.querySelector(
            "#add-to-cart-form",
        ) as HTMLElement;

        return [
            // Init quickSearch
            quickSearchEl && initApp(quickSearchEl, QuickSearch),
            // Init searchPage
            searchPageEl &&
                initApp(
                    searchPageEl,
                    SearchPage,
                    searchPageRouter(searchPageEl.dataset.baseUrlPath),
                ),
            // Init categoryPage
            categoryPageEl &&
                initApp(
                    categoryPageEl,
                    CategoryPage,
                    categoryPageRouter(categoryPageEl.dataset.baseUrlPath),
                ),
            // Init productsModules
            [].forEach.call(productsModuleEls, (el) => {
                initApp(el, ProductsModule, null);
            }),
            // Minicart el
            miniCartEl && initApp(miniCartEl, MiniCart, null),
            // Cart status el
            cartStatusEl && initApp(cartStatusEl, CartStatus, null),
            // image zoom el
            imageZoomEl && initApp(imageZoomEl, ImageZoom, null),
            // Init discount block els
            discountBlockInit(loyaltyDiscountBlockEls),
            // Init shopping cart
            CheckoutPageEl && initApp(CheckoutPageEl, CheckoutPage),
            // Init add to cart form
            addToCartFormEl && initApp(addToCartFormEl, addToCartForm),
        ];
    },
};

/**
 * Init app
 */
function initApp(el, app, router?) {
    const i18n = init18n(app, el);
    const pinia = createPinia();

    const config: any = {
        ...Root,
        i18n,
        components: {
            App: app,
        },
        data() {
            return {
                attributes: {
                    ...el.dataset,
                },
            };
        },
    };

    const vueApp = createApp({
        ...config,
        template: "<App />",
    });

    if (router) {
        vueApp.use(router);
    }

    // vueApp.config.performance = true;
    vueApp.use(i18n);
    vueApp.use(pinia);
    vueApp.mount(el);
}

/**
 * Gets translation strings from dataset attributes prefixed with 'translation_'
 *
 * Example:
 *
 * translation_close-button yields $t('closeButton')
 */
function init18n(app, el) {
    const translations = {};

    Object.keys(el.dataset).forEach((key) => {
        if (key.indexOf("translation_") !== -1) {
            const newKey = key.split("translation_")[1];
            translations[newKey] = el.dataset[key];
        }
    });

    return createI18n({
        locale: "no",
        messages: {
            no: translations,
        },
    });
}

/**
 * Custom init for discountBlocks. Listener required because the viewModel is occasionally rerendered without a page reload through ajax actionlinks.
 */
function discountBlockInit(loyaltyDiscountBlockEls: NodeListOf<HTMLElement>) {
    [].forEach.call(loyaltyDiscountBlockEls, (el) => {
        initApp(el, LoyaltyDiscount, null);
    });

    const ajaxContent = document.getElementById("ajax-content");
    if (!ajaxContent) return;

    const observer = new MutationObserver((mutationList, observer) => {
        mutationList.forEach((mutation) => {
            if (mutation.type == "childList") {
                const loyaltyDiscountBlockEls = document.querySelectorAll(
                    ".discount-block",
                ) as NodeListOf<HTMLElement>;
                [].forEach.call(loyaltyDiscountBlockEls, (el) => {
                    initApp(el, LoyaltyDiscount, null);
                });
            }
        });
    });

    const config = { childList: true, subtree: false };
    observer.observe(ajaxContent, config);
}
