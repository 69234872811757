<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { ProductGroup } from "../../services/search.service";
import ProductTeaser from "../../components/productTeaser/productTeaser.vue";
import ProductsModuleContentSkeleton from "./productsModuleContentSkeleton.vue";
import { useFavoritesStore } from "../../stores/favoritesStore";
import { pushViewItemListEvent } from "../../services/gtm.service";
import { useLazy } from "../../composables/useLazy";

const props = defineProps<{
  heading: string;
  userAuthenticated: boolean;
  productGroups?: Array<ProductGroup>;
  isLoading?: boolean;
  isLoadingFavorites?: boolean;
  showAsColumnOnMobile?: boolean;
  containerClass?: string;
  smallLabel?: boolean;
  hideSizes?: boolean;
  hideColors?: boolean;
  hidePromotion?: boolean;
  productCount?: number;
  useSkeletonLoaders?: boolean;
  hideFavorites?: boolean;
  itemListName?: string;
  itemListId?: string;
  upsellAlgorithm?: string;
  trackViewItemList?: boolean;
  trackSelectItem?: boolean;
}>();

const favoritesStore = useFavoritesStore();
const id = `${props.itemListId}-content`;

const handleIntersection = () => {
  if (props.trackViewItemList && props.productGroups) {
    pushViewItemListEvent(
      props.itemListId ?? "",
      props.itemListName ?? "",
      props.userAuthenticated,
      props.productGroups,
      props.upsellAlgorithm,
    );
  }
};

onBeforeMount(() => {
  useLazy(handleIntersection, `#${id}`, true);
});

onMounted(() => {
  if (props.userAuthenticated) {
    favoritesStore.getFavorites();
  }
});
</script>

<template>
  <div :id="id" v-show="!props.isLoading && props.productGroups?.length" :class="props.containerClass">
    <div
      class="product-list product-list__upsell"
    >
      <!-- Heading -->
      <h2 v-if="props.heading" class="product-list__heading">
        {{ props.heading }}
      </h2>
      <!-- Result -->
      <ul
        id="category-list"
        class="product-list__items product-list__items--auto-width"
        :class="{
          'product-list__items--column': props.showAsColumnOnMobile,
        }"
        data-layout="4"
      >
        <template v-for="productGroup in props.productGroups">
          <template v-if="productGroup.products && productGroup.products[0]">
            <li :key="`${productGroup.key}-${productGroup.products[0].key}`">
              <ProductTeaser
                :product-group="productGroup"
                :product="productGroup.products[0]"
                :is-user-authenticated="props.userAuthenticated"
                :favorites="favoritesStore.favorites"
                :is-loading-products="props.isLoading"
                :is-loading-favorites="favoritesStore.isLoadingFavorites"
                :use-gtm-tracking="true"
                :user-id="''"
                :hide-sizes="props.hideSizes"
                :hide-colors="props.hideColors"
                :hide-promotion="props.hidePromotion"
                :hide-favorites="props.hideFavorites"
                :track-select-item="props.trackSelectItem"
                :item-list-id="props.itemListId"
                :item-list-name="props.itemListName"
                :item-list-index="props.productGroups?.indexOf(productGroup)"
                @toggleFavorite="favoritesStore.toggleFavorite($event)"
              />
            </li>
          </template>
        </template>
      </ul>
    </div>
  </div>
  <div
    v-if="props.isLoading && props.useSkeletonLoaders"
    :class="props.containerClass"
    class="product-list product-list__upsell"
  >
    <ProductsModuleContentSkeleton
      :product-count="props.productCount"
      :show-as-column-on-mobile="props.showAsColumnOnMobile"
    />
  </div>
</template>
