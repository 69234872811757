/**
 * Function to handle event bubbling. Event bubbles up to containing element and triggers event if it hits an element matching the class selector.
 * @param {HTMLElement} element - Upper element. Registers events below in DOM, and events will not bubble past this.
 * @param {string} eventType - String describing DOM Event type, e.g. 'click'.
 * @param {string} className - Class you're looking for
 * @param {(e: Event)} handler - Function to be executed on first matching element
 */
const bubbleEvent = (element, eventType, className, handler) => {
    element.addEventListener(
        eventType,
        (e) => {
            //Event bubbling to handle slowloading/hidden link
            for (
                let target = e.target;
                target && target !== element;
                target = target.parentNode
            ) {
                if (target.matches("." + className)) {
                    handler.call(target, e);
                    break;
                }
            }
        },
        false,
    );
};

export default bubbleEvent;
