<template>
  <span>
    <router-link
      v-if="hasRouter"
      :to="suggestionUrl"
      @click.native="click"
      v-html="formattedSuggestion"
    />
    <a
      v-else
      :href="suggestionUrl"
      @click="click"
      v-html="formattedSuggestion"
    />
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { getSuggestionUrl } from "./quickSearch.utils";

const props = defineProps<{
  searchPageUrl: string;
  suggestion?: {
    highlighted: string;
    q: string;
  };
}>();

const emit = defineEmits(["click"]);

let route;
let router;
try {
  route = useRoute();
  router = useRouter();
} catch (error) {
  route = null;
  router = null;
}

const hasRouter = computed((): boolean => {
  return !!router;
});
const suggestionUrl = computed((): string => {
  return getSuggestionUrl(
    route ? route.query.PREVIEW : undefined,
    route ? "/" : props.searchPageUrl,
    props.suggestion?.q as string,
  );
});
const formattedSuggestion = computed((): string => {
  const hq = props.suggestion?.highlighted
    .replaceAll("{", "</mark>")
    .replaceAll("}", "<mark>");

  return `<mark>${hq}</mark>`;
});

/**
 * Click
 */
function click() {
  emit("click");
}
</script>
