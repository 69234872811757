import {
    AddressModel,
    Cart,
    CartSchema,
    CheckoutInputModel,
} from "../Cart/cartAPISchema";
import {
    DeliveryLocation,
    DeliveryLocationSchema,
    DeliveryMethod,
    DeliveryModel,
    DeliveryModelSchema,
    GetDeliveryInformationResponseModel,
    GetDeliveryInformationResponseModelSchema,
    SetDeliveryOptionResponseModel,
    SetDeliveryOptionResponseModelSchema,
    UpdateDeliveryRequestDto,
} from "./deliveryAPISchema";
import { LogErrorResponse } from "../Logging/LoggingAPI";
import { json } from "stream/consumers";
import { ShippingMethod } from "Scripts/global/apps/services/models/checkout.models";

const API_URL = "/api/delivery";

/**
 * Retrieves delivery information from the API.
 *
 * @returns A promise that resolves to a `DeliveryModel` object.
 * @throws An error if the HTTP request fails or the response is not valid.
 */
export async function getDeliveryInformation(): Promise<GetDeliveryInformationResponseModel> {
    const url = `${API_URL}`;
    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    const validatedData = GetDeliveryInformationResponseModelSchema.parse(data);
    return validatedData;
}

/**
 * Retrieves delivery information from the API.
 *
 * @returns A promise that resolves to a `DeliveryModel` object.
 * @throws An error if the HTTP request fails or the response is not valid.
 */
export async function getDeliveryLocations(
    shippingMethodName: string,
    postalCode: string,
): Promise<Array<DeliveryLocation>> {
    const url = `${API_URL}/deliveryLocations?shippingMethodName=${shippingMethodName}&postalCode=${postalCode}`;

    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    });

    if (!response.ok) {
        await LogErrorResponse(url, response);
        throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    //Parse all array entries in the response data to the DeliveryLocationSchema
    const validatedData = data.map((deliveryLocation: DeliveryLocation) =>
        DeliveryLocationSchema.parse(deliveryLocation),
    );
    return validatedData;
}

/**
 * Sets the selected delivery option for a given delivery method.
 *
 * @param request - The selected delivery option schema.
 * @returns A promise that resolves to a boolean indicating whether the delivery option was set successfully.
 */
export async function setDeliveryOption(
    deliveryMethodId: string,
    request: UpdateDeliveryRequestDto,
): Promise<SetDeliveryOptionResponseModel> {
    const url = `${API_URL}/${deliveryMethodId}`;
    const body = JSON.stringify(request);
    const response = await fetch(url.toString(), {
        method: "PUT",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
    });

    if (!response.ok) {
        await LogErrorResponse(url, response, body);
        return Promise.reject(false);
    }

    const data = await response.json();
    const validatedData = SetDeliveryOptionResponseModelSchema.parse(data);

    return validatedData;
}
