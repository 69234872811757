import { LogMessage } from "./LoggingAPISchema";

const API_URL = "/publicapi/commerce";

/**
 * Sends a log message to application insights
 *
 * @param logmessage The log message to be sent.
 * @throws Error if the HTTP request fails.
 */
export const Log = async (logmessage: LogMessage) => {
    const url = `${API_URL}/log`;
    const response = await fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            logmessage: logmessage,
        }),
    });

    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
};

/**
 * Logs the response from an API endpoint.
 *
 * @param endpointUrl - The URL of the API endpoint.
 * @param response - The response object from the API call.
 * @param body - The request body (optional).
 */
export const LogErrorResponse = async (
    endpointUrl: string,
    response: Response,
    body?: string,
) => {
    const responseText = await response.text();
    await Log({
        LogLevel: "Error",
        Message: `Endpoint: ${endpointUrl} - Status: ${response.status} - Response text: ${responseText} - Body: ${body ?? ""}`,
    });
};
