import { AddressModel, AddressModelSchema } from "./API/Cart/cartAPISchema";
import { isAlpha, AlphaLocale } from "validator";

export const MAX_FULLNAME_LENGTH = 36; // Limitation in M3 for full name.
export const MIN_FULLNAME_LENGTH = 3;

export const FieldType = {
    firstName: "",
    lastName: "",
    address: "",
    city: "",
} as const;

export type FieldType = keyof typeof FieldType;

/**
 * Defines the allowed special characters for different field types.
 */
const ALLOWED_SPECIAL_CHARACTERS: Record<FieldType, string> = {
    firstName: "-'. ",
    lastName: "-'. ",
    address: "-'. 1234567890",
    city: "-'. ",
} as const;

const FEEDBACK_TEXTS = {
    no: {
        REQUIRED: "påkrevd",
        PHONE_MIN_LENGTH: "må være minst 8 siffer",
        PHONE_MAX_LENGTH: "må være maks 10 siffer",
        PHONE_NUMBER_REQUIRED_LENGTH: "må være 8 siffer",
        POSTAL_CODE_REQUIRED_LENGTH: "må være 4 siffer",
        INVALID: "ugyldig ",
        PERSON_IDENTIFIER_REQUIRED_LENGTH: "må være 11 siffer",
        CANNOT_CONTAIN_NUMBERS: "kan ikke inneholde tall",
        CAN_ONLY_CONTAIN_NUMBERS: "kan kun inneholde tall",
        CANNOT_CONTAIN_SPECIAL_CHARACTERS: "kan ikke inneholde ugyldige tegn",
        REQUIRED_DELIVERY_METHOD: "Du må velge en leveringsmetode",
        REQUIRED_DELIVERY_WINDOW: "Du må velge et leveringsvindu",
        REQUIRED_DELIVERY_LOCATION: "Du må velge et leveringssted",
        REQUIRED_PAYMENT_METHOD: "Du må velge en betalingsmetode",
        REQUIRED_PERSON_IDENTIFIER: "Du må fylle inn fødselsnummer",
        REQUIRED_AFTERPAY_INSTALLMENT_METHOD:
            "Du må velge en delbetalingsmetode",
        REQUIRED_AFTERPAY_INSTALLMENT_PLAN: "Du må velge en nedbetalingsplan",
        GENERIC_ERROR: "Noe gikk galt, vennligst prøv igjen senenere",
        UNKNOWN_POSTALCODE: "Ukjent postnummer",
        POSTALCODE_AND_CITY_DONT_MATCH:
            "Postnummer og poststed stemmer ikke overens",
        PASSWORD_RESET_EMAIL_REQUIRED:
            "Vennligst fyll inn epostadresse for å kunne tilbakestille passord",
        PASSWORD_RESET_EMAIL_INVALID:
            "Vennligst fyll inn en gyldig epostadresse for å kunne tilbakestille passord",
        CONSENT_REQUIRED: "Du må godta vilkårene",
        NAME_MAXLENGTH_EXCEEDED: `Samlet lengde av fornavn og etternavn kan ikke overstige ${MAX_FULLNAME_LENGTH} tegn`,
    },
    en: {
        REQUIRED: "required",
        PHONE_MIN_LENGTH: "must be at least 8 digits",
        PHONE_MAX_LENGTH: "must be a maximum of 10 digits",
        PHONE_NUMBER_REQUIRED_LENGTH: "must be 8 digits",
        POSTAL_CODE_REQUIRED_LENGTH: "must be 4 digits",
        INVALID: "invalid",
        PERSON_IDENTIFIER_REQUIRED_LENGTH: "must be 11 digits",
        CANNOT_CONTAIN_NUMBERS: "cannot contain numbers",
        CAN_ONLY_CONTAIN_NUMBERS: "can only contain numbers",
        CANNOT_CONTAIN_SPECIAL_CHARACTERS: "cannot contain special characters",
        REQUIRED_DELIVERY_METHOD: "You must choose a delivery method",
        REQUIRED_DELIVERY_WINDOW: "You must choose a delivery window",
        REQUIRED_DELIVERY_LOCATION: "You must choose a delivery location",
        REQUIRED_PAYMENT_METHOD: "You must choose a payment method",
        REQUIRED_PERSON_IDENTIFIER: "You must enter a personal identifier",
        REQUIRED_AFTERPAY_INSTALLMENT_METHOD:
            "You must choose an installment method",
        REQUIRED_AFTERPAY_INSTALLMENT_PLAN:
            "You must choose an installment plan",
        GENERIC_ERROR: "Something went wrong, please try again later",
        UNKNOWN_POSTALCODE: "Unknown postal code",
        POSTALCODE_AND_CITY_DONT_MATCH: "Postal code and city do not match",
        PASSWORD_RESET_EMAIL_REQUIRED:
            "Please enter an email address to reset your password",
        PASSWORD_RESET_EMAIL_INVALID:
            "Please enter a valid email address to reset your password",
        CONSENT_REQUIRED: "You must accept the terms and conditions",
        NAME_MAXLENGTH_EXCEEDED: `Samlet lengde av fornavn og etternavn kan ikke overstige ${MAX_FULLNAME_LENGTH} tegn`,
    },
} as const;

export type SupportedFeedbackTextLocales = keyof typeof FEEDBACK_TEXTS;

/**
 * Returns the feedback text based on the specified locale.
 * If the locale is not found, it defaults to Norwegian.
 *
 * @param locale - The locale to get the feedback text for.
 * @returns The feedback text for the specified locale.
 */
export const FEEDBACK_TEXT = (locale: SupportedFeedbackTextLocales = "no") => {
    return FEEDBACK_TEXTS[locale] || FEEDBACK_TEXTS.no; // Default to Norwegian if the locale
};

/**
 * Collection of validation helper functions.
 */
export const validationHelper = {
    isPhoneNumber: (phoneNumber: string): boolean => {
        const phoneNumberRegex = /^\d{8,10}$/;
        return phoneNumberRegex.test(phoneNumber);
    },
    isPostalCode: (postalCode: string): boolean => {
        const postalCodeRegex = /^\d{4}$/;
        return postalCodeRegex.test(postalCode);
    },
    validCustomerInformation: (customerInformation: AddressModel): boolean => {
        return AddressModelSchema.safeParse(customerInformation).success;
    },
    containsNumbers: (value: string): boolean => {
        return /\d/.test(value);
    },
    containsOnlyNumbers: (value: string): boolean => {
        return /^\d+$/.test(value);
    },
    containsSpecialCharacters: (value: string): boolean => {
        const regex = /[^\p{L}\p{N}\p{M}-]/u;
        return regex.test(value);
    },

    /**
     * Checks if the given value contains any disallowed special characters based on the field type.
     *
     * @param fieldType - The type of the field.
     * @param value - The value to be checked.
     * @param locale - The country code for locale-specific checks. Defaults to "nb-NO".
     * @returns A boolean indicating whether the value contains disallowed special characters.
     */
    containsDisallowedSpecialCharacters(
        fieldType: FieldType,
        value: string,
        locale: AlphaLocale | undefined = "nb-NO",
    ): boolean {
        return !isAlpha(value, locale, {
            ignore: ALLOWED_SPECIAL_CHARACTERS[fieldType],
        });
    },
};
