import { currentDelivery } from './delivery_wp';
export const initPostNordMap = () => {
    const myLatLng = {lat: 60, lng: 10};
    const adrsLength = document.querySelectorAll('[data-latitude]');
    const bounds = new google.maps.LatLngBounds();
    const markers = [];

    // Create a map object and specify the DOM element for display.
    const map = new google.maps.Map(document.getElementById('map'), {
        center: myLatLng,
        fullscreenControl: false,
        mapTypeControl: false,
        zoom: 2,
        maxZoom: 18,
        styles: [
            {
                'featureType': 'poi.business',
                'stylers': [
                    {
                        'visibility': 'off'
                    }
                ]
            }
        ]
    });

    // Loop through elements
    let i = 0, num = adrsLength.length;
    for (; i < num; i++) {
        setInfoWindow(i);
    }

    function setInfoWindow(i) {
        const currentName = adrsLength[i].dataset.name;
        let currentLat = adrsLength[i].dataset.latitude;
        let currentLng = adrsLength[i].dataset.longitude;
        const currentAddress = adrsLength[i].dataset.address;
        const currentStore = adrsLength[i].dataset.pickuptype;


        // Marker style
        const postNordBox = {
            path: 'M7,18V43H43V18H7ZM20,28H12V22h8v6Zm4-12H7l5-9H24v9Zm2-9H38l5,9H26V7Z',
            fillColor: 'black',
            fillOpacity: 1,
            scale: .5,
            strokeColor: 'white',
            strokeWeight: 1,
            anchor: new google.maps.Point(30, 30)
        };

        const matchLogo = {
            path: 'M16,0C7.1,0,0,7.2,0,16s10.7,13,16,26c5-13,16-17.2,16-26S24.8,0,16,0z M25.3,7.4v17.1c0,0.1-0.1,0.1-0.1,0.1h-3.7c-0.1,0-0.1-0.1-0.1-0.1V13.8c0-0.1-0.1,0-0.1-0.1s-0.1,0-0.1,0l-5.2,8h-0.1l-0.1-0.1l-5.2-8h-0.1c-0.1,0-0.1,0.1-0.1,0.1v9.9v0.8c0,0.1-0.1,0.1-0.1,0.1H6.7c-0.1,0-0.1-0.1-0.1-0.1v-0.8V8.1V7.4c0-0.1,0.1-0.2,0.1-0.2h4.1c0.1,0,0.2,0.1,0.3,0.1l4.9,7.8l0.1,0.1h0.1l0.1-0.1L21,7.3c0.1-0.1,0.2-0.1,0.3-0.1h4C25.4,7.2,25.4,7.3,25.3,7.4z',
            fillColor: 'red',
            strokeWeight: 0,
            fillOpacity: 1,
            scale: .75

        };

        const boysLogo = {
            path: 'M12.1,9.5c0-1.2-0.7-1.8-1.4-2c0.6-0.3,1-0.8,1-1.6c0-0.8-0.5-2-2.6-2H4.7v8.1h4.6C10.7,11.9,12.1,11.1,12.1,9.5z M7.2,5.7h1.3c0.5,0,0.9,0.1,0.9,0.6C9.3,6.8,9,7,8.5,7H7.2V5.7z M7.2,10V8.5h1.6c0.6,0,0.9,0.3,0.9,0.7c0,0.6-0.5,0.8-1,0.8H7.2, M8,16c4.4,0,8-3.6,8-8c0-4.4-3.6-8-8-8C3.6,0,0,3.6,0,8C0,12.4,3.6,16,8,16z M8,1.4c3.6,0,6.6,3,6.6,6.6c0,3.6-3,6.6-6.6,6.6c-3.6,0-6.6-3-6.6-6.6C1.4,4.4,4.4,1.4,8,1.4z',
            fillColor: '#eb690b',
            fillOpacity: 1,
            strokeColor: '#eb690b',
            strokeWeight: 0,
            scale: 1.2
        };
        const vicLogo = {
            path: 'M8,0C3.6,0,0,3.6,0,8s3.6,8,8,8c4.4,0,8-3.6,8-8S12.4,0,8,0z M3.8,10.4L1,5.6h1.1l1.7,2.9l1.6-2.9h1.1L3.8,10.4z M8.5,10.3H7.5V5.6h0.9V10.3z M12.4,10.4C11,10.4,9.8,9.3,9.8,8c0-1.3,1.1-2.4,2.6-2.4c0.9,0,1.7,0.5,2.2,1.1l-0.8,0.5c-0.3-0.4-0.8-0.7-1.4-0.7c-0.9,0-1.6,0.7-1.6,1.5c0,0.8,0.7,1.5,1.6,1.5c0.6,0,1.1-0.3,1.4-0.7l0.8,0.5C14.1,9.9,13.3,10.4,12.4,10.4z',
            fillColor: 'black',
            fillOpacity: 1,
            strokeColor: 'black',
            strokeWeight: 0,
            scale: 1
        };

        const currentSymbol = currentStore === 'Match' ? matchLogo : currentStore === 'Boys' ? boysLogo : currentStore === 'Vic' ? vicLogo : postNordBox;

        if (window.google.maps) {
            let geocoder = new google.maps.Geocoder;
            geocoder.geocode({'address': currentAddress}, function (results, status) {
                if (status == window.google.maps.GeocoderStatus.OK) {
                    currentLat = results[0].geometry.location.lat();
                    currentLng = results[0].geometry.location.lng();
                }

                // Infowindow
                const infowindow = new google.maps.InfoWindow({
                    content: currentName
                });

                const marker = new google.maps.Marker({
                    map: map,
                    position: new google.maps.LatLng(currentLat, currentLng),
                    title: currentName,
                    icon: currentSymbol,
                    infowindow: infowindow
                });

                markers.push(marker);

                // Click events
                marker.addListener('click',
                    function () {
                        let el = adrsLength[i].id;
                        hideAllInfoWindows();
                        infowindow.open(map, marker);
                        currentDelivery(el);
                        map.setZoom(14);
                        map.panTo(marker.position);
                    });

            });
            bounds.extend(new google.maps.LatLng(currentLat, currentLng));
        }
    }

    function hideAllInfoWindows() {
        markers.forEach(function (marker) {
            marker.infowindow.close(map, marker);
        });
    }

    map.fitBounds(bounds);

    return markers;
};
